import React from "react";
import {
    Document,
    Image,
    Page,
    StyleSheet,
    Text,
    View,
    Font,
} from "@react-pdf/renderer";
import logo from "../../assets/media/images/logo.png";
import sig from "../../assets/media/images/sig.png";
import { colors } from "../../assets/utils/colors";
import moment from "moment";
import akaya from "../../assets/fonts/akaya.woff";
// import jaro from "../../assets/fonts/jaro.ttf";

// Load font
Font.register({ family: "akaya", src: akaya });
// Font.register({ family: "jaro", src: jaro });

// Create styles
const styles = StyleSheet.create({
    page: {
        paddingLeft: "25px",
        paddingRight: "25px",
        paddingTop: "25px",
        paddingBottom: "25px",
    },
    section: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
});

// QR Code

// Create Document Component
const CertificatePDF = ({ license }) => {
    const qrCodeCanvas = document.getElementById("certQr");
    const qrCodeDataUri = qrCodeCanvas.toDataURL("image/jpg", 0.3);
    return (
        <Document>
            <Page
                size="A4"
                style={styles.page}
                dpi={70}
                orientation={"landscape"}
            >
                <View
                    style={{
                        height: "100%",
                        display: "flex",
                        // justifyContent: "space-around",
                    }}
                >
                    {/* ################# Bg Logo ############### */}
                    <View
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "absolute",
                            left: 0,
                            right: 0,
                            bottom: 0,
                            top: 0,
                        }}
                    >
                        <Image
                            style={{
                                opacity: 0.1,
                                width: "65%",
                                height: "95%",
                            }}
                            src={logo}
                        />
                    </View>
                    <View
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            position: "relative",
                            left: 0,
                            right: 0,
                            bottom: 0,
                            top: 0,
                            height: "100%",
                        }}
                    >
                        <View
                            style={{
                                position: "absolute",
                                border: `2px double ${colors.yellow}`,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                top: 0,
                            }}
                        ></View>
                        <View
                            style={{
                                position: "absolute",
                                border: `2px double ${colors.yellow}`,
                                left: 5,
                                right: 5,
                                bottom: 5,
                                top: 5,
                            }}
                        ></View>
                        <View
                            style={{
                                position: "absolute",
                                border: `2px double ${colors.yellow}`,
                                left: 10,
                                right: 10,
                                bottom: 10,
                                top: 10,
                            }}
                        ></View>
                        <View
                            style={[
                                styles.section,
                                {
                                    marginTop: 20,
                                    width: "100%",
                                    position: "relative",
                                },
                            ]}
                        >
                            <Image
                                style={{
                                    width: 120,
                                    height: 120,
                                }}
                                src={logo}
                            />
                            <Text
                                style={{
                                    textTransform: "uppercase",
                                    fontWeight: "bold",
                                    fontSize: 18,
                                    marginTop: 10,
                                    color: colors.yellow,
                                }}
                            >
                                Tanzania chamber of commerce, industry and
                                agriculture
                            </Text>
                            <Text style={{ fontSize: 13, marginTop: 10 }}>
                                Reg. Under cap 212
                            </Text>
                            <Text
                                style={{
                                    position: "absolute",
                                    right: 30,
                                    top: 20,
                                    fontSize: 15,
                                    lineHeight: 2,
                                    fontWeight: "bold",
                                }}
                            >
                                No.{license?.licenseNumber}
                            </Text>
                        </View>
                        <View
                            style={[
                                styles.section,
                                {
                                    height: 100,
                                    width: "100%",
                                    position: "relative",
                                },
                            ]}
                        >
                            <Text
                                style={{
                                    fontSize: 30,
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                    fontFamily: "akaya",
                                }}
                            >
                                Membership certificate
                            </Text>
                            <Text
                                style={{
                                    fontSize: 14,
                                    marginTop: 10,
                                    fontFamily: "akaya",
                                }}
                            >
                                This is to certify that
                            </Text>
                        </View>
                        <View
                            style={[
                                styles.section,
                                {
                                    width: "100%",
                                    position: "relative",
                                },
                            ]}
                        >
                            <Text
                                style={{
                                    fontSize: 22,
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                    color: colors.yellow,
                                    textDecoration: "underline",
                                }}
                            >
                                {license?.member?.companyName}
                            </Text>
                            <Text
                                style={{
                                    fontSize: 14,
                                    marginTop: 10,
                                    fontFamily: "akaya",
                                }}
                            >
                                Is our{" "}
                                <Text
                                    style={{
                                        fontSize: 18,
                                        textTransform: "uppercase",
                                        color: colors.primary,
                                    }}
                                >
                                    {license?.membershipLevel.levelName}{" "}
                                </Text>
                                corporate member for the
                            </Text>
                            <Text
                                style={{
                                    fontSize: 14,
                                    marginTop: 10,
                                    fontFamily: "akaya",
                                }}
                            >
                                year {moment(license.startDate).format("LL")} -
                                {moment(license.endDate).format("LL")}
                            </Text>
                            <View
                                style={{
                                    height: 60,
                                    width: 60,
                                    position: "absolute",
                                    right: 50,
                                }}
                            >
                                <View
                                    style={{
                                        height: 60,
                                        width: 60,
                                        position: "relative",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Image
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                        }}
                                        source={{ uri: qrCodeDataUri }}
                                    />
                                </View>
                            </View>
                        </View>
                        <View
                            style={[
                                styles.section,
                                {
                                    width: "100%",
                                    position: "relative",
                                    flexDirection: "row",
                                    paddingLeft: 50,
                                    paddingRight: 50,
                                    justifyContent: "space-between",
                                    bottom: 15,
                                },
                            ]}
                        >
                            <View
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <View
                                    style={{
                                        borderBottom: `1px solid #000`,
                                        width: "100%",
                                        padding: 2,
                                        marginBottom: 5,
                                    }}
                                >
                                    <Image
                                        style={{
                                            width: "100%",
                                            objectFit: "contain",
                                        }}
                                        src={sig}
                                    />
                                </View>
                                <Text style={{ fontSize: 10 }}>
                                    TCCIA President
                                </Text>
                            </View>
                            <View
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <View
                                    style={{
                                        borderBottom: `1px solid #000`,
                                        width: "100%",
                                        padding: 2,
                                        marginBottom: 2,
                                    }}
                                >
                                    <Text
                                        style={{
                                            fontSize: 10,
                                        }}
                                    >
                                        {moment(license.updated_at).format(
                                            "LL"
                                        )}
                                    </Text>
                                </View>
                                <Text
                                    style={{
                                        fontSize: 10,
                                    }}
                                >
                                    Date issued
                                </Text>
                            </View>
                            <View
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <View
                                    style={{
                                        borderBottom: `1px solid #000`,
                                        width: "100%",
                                        padding: 2,
                                        marginBottom: 5,
                                    }}
                                >
                                    <Image
                                        style={{
                                            width: "100%",
                                            objectFit: "contain",
                                        }}
                                        src={sig}
                                    />
                                </View>
                                <Text style={{ fontSize: 10 }}>
                                    Executive director
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};
export default CertificatePDF;
