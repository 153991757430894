import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    memberICN: "",
    companyName: "",
    pinId: "",
    phoneNumber: "",
    isPhoneVerified: false,
    password: "",
};

// !########## SLICE #############
const registerSlice = createSlice({
    name: "registerSlice",
    initialState,
    reducers: {
        setMemberICN: (state, action) => {
            state.memberICN = action.payload.tinNumber;
        },
        setCompanyName: (state, action) => {
            state.companyName = action.payload.companyName;
        },
        setPassword: (state, action) => {
            state.password = action.payload.password;
        },
        setPinId: (state, action) => {
            let pinId = action.payload.pinId;
            let phoneNumber = action.payload.phoneNumber;
            state.pinId = pinId;
            state.phoneNumber = phoneNumber;
        },
        resetPinId: (state) => {
            state.pinId = "";
        },
        phoneVerified: (state, action) => {
            state.isPhoneVerified = action.payload;
        },
    },
});

export const {
    setMemberICN,
    setPinId,
    resetPinId,
    phoneVerified,
    setCompanyName,
    setPassword,
} = registerSlice.actions;
export default registerSlice.reducer;
