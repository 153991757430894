// => GET invoice
const GET_INVOICES = "/invoices/members";

// => GET single invoice
const GET_SINGLE_INVOICE = "/invoices";

// => PAY invoice
const PAY_INVOICE = "/payments/invoices";

export const invoiceEndpoints = {
    GET_INVOICES,
    GET_SINGLE_INVOICE,
    PAY_INVOICE,
};
