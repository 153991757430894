import { Box, Typography } from "@mui/material";
import React from "react";
import { WarningAmberOutlined } from "@mui/icons-material";
import { colors } from "../assets/utils/colors";

const NoContent = ({ height, message, children }) => {
    return (
        <React.Fragment>
            <Box
                sx={{
                    height: height ? height : "50vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: height ? "relative" : "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                }}
            >
                {message ? (
                    <>
                        <Box sx={{ textAlign: "center" }}>
                            <WarningAmberOutlined
                                color="warning"
                                sx={{ fontSize: 40, opacity: 0.3 }}
                            />
                            <Typography
                                component={"div"}
                                sx={{
                                    fontSize: 12,
                                    fontWeight: "bold",
                                    color: colors.primary,
                                    opacity: 0.8,
                                }}
                            >
                                {message}
                            </Typography>
                        </Box>
                    </>
                ) : (
                    { children }
                )}
            </Box>
        </React.Fragment>
    );
};

export default NoContent;
