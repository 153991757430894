import * as React from "react";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {
    Avatar,
    Box,
    Button,
    List,
    ListItem,
    ListItemButton,
    Stack,
    styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../assets/media/images/logo.png";
import SpacingContent from "./SpacingContent";
import { ArrowRight, Person2, Segment, Window } from "@mui/icons-material";
import { Link, NavLink } from "react-router-dom";
import { colors } from "../assets/utils/colors";
import Login from "../pages/auth/Login";

// ################# CONSTANT ###############
const drawerWidth = 250;

// ################# STYLED APPBAR ###############
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    // backgroundColor: "transparent",
    backgroundColor: colors.primary,
    boxShadow: "none !important",
    background: `rgba(23, 148, 202, .5)`,
    backdropFilter: `blur(5px)`,
}));

// ################# TOPBAR RIGHT ###############
const TopbarRight = styled(Stack)({});

// !###################### MAIN FUNC ############################
const HomeTopBar = ({ mobileOpen, handleDrawerToggle }) => {
    const links = [
        {
            id: 1,
            title: "Home",
            icon: <Window className="icon" />,
            url: "/",
        },
        {
            id: 2,
            title: "Link 1",
            url: "/",
        },
    ];

    return (
        <>
            <AppBar position="fixed">
                <SpacingContent>
                    <Toolbar
                        sx={{
                            px: { sm: `0 !important` },
                            justifyContent: "space-between",
                        }}
                    >
                        {/* ################### LEFT ################# */}
                        <Box
                            sx={{
                                position: "absolute",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Typography
                                noWrap
                                component="div"
                                sx={{
                                    mt: 7,
                                    display: "flex",
                                    alignItems: "center",
                                    borderRadius: 50,
                                    background: `rgba(23, 148, 202, .5)`,
                                    backdropFilter: `blur(5px)`,
                                    p: 0.5,
                                    // border: `5px solid ${colors.primary}`,
                                }}
                            >
                                <Link to={"/"}>
                                    <Avatar
                                        src={logo}
                                        sx={{
                                            height: 90,
                                            width: 90,
                                            img: {
                                                objectFit: "contain",
                                            },
                                        }}
                                    />
                                </Link>
                            </Typography>
                        </Box>

                        {/* ################### CENTER ################# */}
                        <Box sx={{ textAlign: "center" }}>
                            {/* <List
                                sx={{
                                    position: "relative",
                                    height: 55,
                                    bottom: 0,
                                    right: 0,
                                    left: 0,
                                    p: 0,
                                    display: { md: "flex", xs: "none" },
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    "& .item": {
                                        display: "flex",
                                        flexDirection: "column",
                                        // 'width': '25%',
                                        "& .btn": {
                                            display: "flex",
                                            px: { md: 1, xs: "100%" },
                                            textAlign: "center",
                                            alignItems: "center",
                                        },
                                        "& .icon": {
                                            opacity: 0.8,
                                            fontSize: 20,
                                            mr: 1,
                                        },
                                        "& .title": {
                                            opacity: 0.8,
                                            fontWeight: "bold",
                                        },
                                    },
                                }}
                            >
                                {links?.map((link) => (
                                    <NavLink
                                        to={link.url}
                                        style={{ width: "100%" }}
                                        key={link.id}
                                    >
                                        {({ isActive }) => (
                                            <ListItem className="item">
                                                <ListItemButton
                                                    className="btn"
                                                    sx={{
                                                        color: isActive
                                                            ? colors.secondary
                                                            : colors.textColor,
                                                    }}
                                                >
                                                    {link.icon}
                                                    <Typography
                                                        noWrap
                                                        className="title"
                                                        sx={{
                                                            color: isActive
                                                                ? colors.secondary
                                                                : "auto",
                                                        }}
                                                    >
                                                        {link.title}
                                                    </Typography>
                                                </ListItemButton>
                                            </ListItem>
                                        )}
                                    </NavLink>
                                ))}
                            </List> */}
                        </Box>

                        {/* ################### RIGHT ################# */}
                        <TopbarRight
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={3}
                        >
                            <Box>
                                <Login />
                            </Box>
                            {mobileOpen ? (
                                <IconButton
                                    onClick={handleDrawerToggle}
                                    color="inherit"
                                    sx={{
                                        display: {
                                            xs: "block",
                                            sm: "none",
                                        },
                                        marginTop: 1.3,
                                    }}
                                >
                                    <CloseIcon sx={{ fontSize: 35 }} />
                                </IconButton>
                            ) : (
                                // <IconButton
                                //     onClick={handleDrawerToggle}
                                //     color="inherit"
                                //     sx={{
                                //         display: {
                                //             xs: "block",
                                //             sm: "none",
                                //         },
                                //         marginTop: 1.3,
                                //     }}
                                // >
                                //     <Segment sx={{ fontSize: 35 }} />
                                // </IconButton>
                                <></>
                            )}
                        </TopbarRight>
                    </Toolbar>
                </SpacingContent>
            </AppBar>
        </>
    );
};

export default HomeTopBar;
