import { useEffect, useState } from "react";
import { errorNotify, successNotify } from "../helpers/notify";
import {
    getRTKErrorMessage,
    getRTKResponseData,
    getRTKSuccessMessage,
} from "../helpers/RTKHelpers";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useGetProfileQuery } from "../api/profileAPI/profileAPI";
import { setUserProfile } from "../state/features/profile/profileSlice";
import { setMemberProfile } from "../state/features/profile/memberProfileSlice";
import {
    setActiveRegions,
    setActiveSectors,
    setAnnouncements,
    setBankAccounts,
} from "../state/features/admin/adminSlice";
import {
    useLazyGetActiveRegionsQuery,
    useLazyGetActiveSectorsQuery,
    useLazyGetAllAnnouncementsQuery,
    useLazyGetBantAccountsQuery,
} from "../api/adminAPI/adminAPI";
import { setMembershipLevels } from "../state/features/membershipLevel/membershipLevelSlice";
import { setInvoices } from "../state/features/invoice/invoiceSlice";
import { useLazyGetInvoicesQuery } from "../api/invoiceAPI/invoiceAPI";
import {
    authSelector,
    systemConfigSelector,
} from "../state/features/selectors";
import { useLazyGetMemberShipLevelsQuery } from "../api/membershipLevelAPI/membershipLevelAPI";
import { disableMainFetch } from "../state/features/systemConfig/systemConfigSlice";
import { useLazyGetLicensesQuery } from "../api/licenseAPI/licenseAPI";
import { setLicenses } from "../state/features/license/licenseSlice";
import { useLazyGetAllNotificationsQuery } from "../api/notificationsAPI/notificationsAPI";
import { setNotifications } from "../state/features/notifications/notificationsSlice";
import useAuth from "./useAuth";

const useRTK = () => {
    const [isMainLoading, setIsMainLoading] = useState(false);
    // => Use RTK Response
    const useRTKResponse = (isSuccess, isError, error, data, actions) => {
        useEffect(() => {
            let isSubscribed = true;
            if (isSubscribed) {
                if (isSuccess) {
                    actions && actions(getRTKResponseData(data));
                    successNotify(getRTKSuccessMessage(data));
                }
                if (isError) {
                    errorNotify(
                        !isEmpty(error)
                            ? getRTKErrorMessage(error)
                            : "Some error occur. Please try again!"
                    );
                }
            }
            return () => {
                isSubscribed = false;
            };
        }, [isSuccess, isError, error, data]);
    };

    // => Use RTK Fetch
    const useRTKFetch = (isSuccess, data, actions) => {
        useEffect(() => {
            let isSubscribed = true;
            if (isSubscribed) {
                if (isSuccess) {
                    actions(getRTKResponseData(data));
                }
            }
            return () => {
                isSubscribed = false;
            };
        }, [isSuccess, data]);
    };

    // => RTK main data
    const useRTKMainData = () => {
        // => CONST
        const dispatch = useDispatch();
        const { user } = useSelector(authSelector);
        const auth = useAuth();

        // ######### Redux states #############
        const { isMainFetch } = useSelector(systemConfigSelector);

        // ################# Profile #################
        const {
            isLoading: profileLoading,
            isSuccess: profileSuccess,
            isError: isProfileError,
            data: profileData,
        } = useGetProfileQuery();
        const rtkPofileActions = (data) => {
            dispatch(setUserProfile(data));
            dispatch(setMemberProfile(data));
        };
        useRTKFetch(profileSuccess, profileData, rtkPofileActions);

        // ################# Admin Settings #################
        // => Active sectors
        const [getSector, { isSuccess: sectorSuccess, data: sectorData }] =
            useLazyGetActiveSectorsQuery();
        const rtkSectorActions = (data) => {
            dispatch(setActiveSectors(data));
        };
        useRTKFetch(sectorSuccess, sectorData, rtkSectorActions);

        // => Active bank acc
        const [getBankAccounts, { isSuccess: bankSuccess, data: bankData }] =
            useLazyGetBantAccountsQuery();
        const rtkBankActions = (data) => {
            dispatch(setBankAccounts(data));
        };
        useRTKFetch(bankSuccess, bankData, rtkBankActions);

        // => Active regions
        const [
            getActiveRegions,
            { isSuccess: regionsSuccess, data: regionsData },
        ] = useLazyGetActiveRegionsQuery();
        const rtkRegionActions = (data) => {
            dispatch(setActiveRegions(data));
        };
        useRTKFetch(regionsSuccess, regionsData, rtkRegionActions);

        // => Get announcements
        const [
            getAnnouncements,
            {
                isLoading: announcementsIsLoading,
                isSuccess: announcementsSuccess,
                data: announcementsData,
            },
            ,
        ] = useLazyGetAllAnnouncementsQuery();
        const rtkAnnouncementsActions = (data) => {
            dispatch(setAnnouncements(data));
        };
        useRTKFetch(
            announcementsSuccess,
            announcementsData,
            rtkAnnouncementsActions
        );

        // => Get notification
        const [
            getAllNotifications,
            { isSuccess: notificationsSuccess, data: notificationsData },
            ,
        ] = useLazyGetAllNotificationsQuery();
        const rtkNotificationsActions = (data) => {
            dispatch(setNotifications(data));
        };
        useRTKFetch(
            notificationsSuccess,
            notificationsData,
            rtkNotificationsActions
        );

        // ################# GET Membership levels #################
        const [
            getMemberShipLevels,
            {
                isLoading: membershipLevelsLoading,
                isSuccess: membershipLevelsSuccess,
                data: membershipLevelsData,
            },
        ] = useLazyGetMemberShipLevelsQuery();
        const rtkMembershipLevelsActions = (data) => {
            dispatch(setMembershipLevels(data));
        };
        useRTKFetch(
            membershipLevelsSuccess,
            membershipLevelsData,
            rtkMembershipLevelsActions
        );

        // ################# GET invoices #################
        const [
            getAllInvoices,
            {
                isLoading: invoicesLoading,
                isSuccess: invoicesSuccess,
                data: invoicesData,
            },
        ] = useLazyGetInvoicesQuery();
        const rtkInvoicesActions = (data) => {
            dispatch(setInvoices(data));
        };
        useRTKFetch(invoicesSuccess, invoicesData, rtkInvoicesActions);

        // ################# GET licenses #################
        const [
            getAllLicenses,
            {
                isLoading: licenseLoading,
                isSuccess: licenseSuccess,
                data: licenseData,
            },
        ] = useLazyGetLicensesQuery();
        const rtkLicenseActions = (data) => {
            dispatch(setLicenses(data));
        };
        useRTKFetch(licenseSuccess, licenseData, rtkLicenseActions);

        // ############## useEffect #################
        useEffect(() => {
            let isSubscribed = true;
            if (isSubscribed) {
                if (isMainFetch) {
                    setIsMainLoading(true);
                    if (profileSuccess) {
                        getMemberShipLevels();
                        getSector();
                        getAllInvoices(profileData.data.member.id);
                        getAllLicenses();
                        getBankAccounts();
                        getAnnouncements();
                        getAllNotifications();
                        getActiveRegions();
                        setTimeout(() => {
                            dispatch(disableMainFetch());
                        }, 2000);
                        if (
                            !profileLoading &&
                            !membershipLevelsLoading &&
                            !licenseLoading &&
                            !invoicesLoading &&
                            !announcementsIsLoading
                        ) {
                            setIsMainLoading(false);
                        }
                    }
                    if (isProfileError) {
                        auth.logout();
                    }
                }
            }
            return () => {
                isSubscribed = false;
            };
        }, [profileSuccess, isProfileError]);

        return isMainLoading;
    };

    return {
        useRTKFetch,
        useRTKResponse,
        useRTKMainData,
    };
};

export default useRTK;
