import { useEffect, useState } from "react";

export const useDate = () => {
    const locale = "en";
    const [today, setDate] = useState(new Date()); // Save the current date to be able to trigger an update

    useEffect(() => {
        const timer = setInterval(() => {
            setDate(new Date());
        }, 60);
        return () => {
            clearInterval(timer);
        };
    }, []);

    const day = today.toLocaleDateString(locale, { weekday: "long" });
    const date = `${today.getDate()}, ${today.toLocaleDateString(locale, {
        month: "long",
    })}\n\n
        ${today.getFullYear()}`;

    const date2 = `${today.toLocaleDateString(locale, {
        month: "long",
    })} \n\n ${today.getDate()}, \n\n
        ${today.getFullYear()}`;

    const hour = today.getHours();
    const wish_1 = `Good ${
        (hour < 12 && "morning") || (hour < 17 && "Afternoon") || "Evening"
    }, spider`;
    const wish_2 = `Happy ${day}, `;

    const time = today.toLocaleTimeString(locale, {
        hour: "numeric",
        hour12: true,
        minute: "numeric",
        second: "numeric",
    });

    return {
        date,
        date2,
        time,
        wish_1,
        wish_2,
        day,
    };
};
