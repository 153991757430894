// => Verify Cert
const VERIFY_CERTIFICATE = "/licenses/verify";

// => Verify Cert QR
const VERIFY_CERTIFICATE_QR = "/licenses/verify";

export const certificateEndpoints = {
    VERIFY_CERTIFICATE,
    VERIFY_CERTIFICATE_QR,
};
