import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { memberEndpoints } from "./memberEndpoints";
import { baseURL } from "../baseUrl/baseURL";
import { headers } from "../baseUrl/setHeaders";
import { profileAPI } from "../profileAPI/profileAPI";

export const memberAPI = createApi({
    reducerPath: "memberAPI",
    baseQuery: fetchBaseQuery({ baseUrl: baseURL, prepareHeaders: headers }),
    endpoints: (builder) => ({
        // => Update member details
        updateMemberDetails: builder.mutation({
            query: (payload) => ({
                url: `${memberEndpoints.UPDATE_DETAILS}`,
                method: `PUT`,
                body: payload,
            }),
            async onQueryStarted(_, { queryFulfilled, dispatch }) {
                try {
                    await queryFulfilled;
                    dispatch(profileAPI.util.invalidateTags(["Profile"]));
                } catch (err) {}
            },
        }),
        // => Request verification
        requestMemberVerification: builder.mutation({
            query: (payload) => ({
                url: `${memberEndpoints.REQUEST_VERIFICATION}`,
                method: `PUT`,
                body: payload,
            }),
        }),
    }),
});

export const {
    useUpdateMemberDetailsMutation,
    useRequestMemberVerificationMutation,
} = memberAPI;
