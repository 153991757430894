import React from "react";
import { Avatar, Box, Grid, styled } from "@mui/material";
import logo from "../assets/media/images/logo.png";
import Loader from "react-js-loader";
import { colors } from "../assets/utils/colors";

// ########## MUI Custom ############
const PageBgImageCon = styled(Box)({
    position: "fixed",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
    zIndex: -1,
});

const FullAppLoader = () => {
    return (
        <>
            <Box>
                <PageBgImageCon>
                    <Box
                        sx={{
                            position: "fixed",
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            bgcolor: colors.primary,
                            backgroundImage: `linear-gradient(rgba(23, 148, 202, .5), rgba(23, 148, 202, .5)), radial-gradient(circle at left top, rgb(1, 110, 218), rgb(49, 180, 76, .5))`,
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "relative",
                            }}
                        >
                            <Grid container justifyContent={"center"}>
                                <Grid item sm={6} xs={8}>
                                    <Box
                                        sx={{
                                            position: "relative",
                                        }}
                                    >
                                        <Avatar
                                            variant="square"
                                            src={logo}
                                            sx={{
                                                height: 90,
                                                zIndex: 15,
                                                width: 90,
                                                img: {
                                                    objectFit: "contain",
                                                },
                                            }}
                                        />
                                        <Box
                                            sx={{
                                                position: "absolute",
                                                top: 0,
                                                zIndex: 5,
                                                mt: -0.5,
                                                ml: -0.6,
                                            }}
                                        >
                                            <Loader
                                                type="spinner-cub"
                                                bgColor={colors.bgColor5}
                                                color={colors.bgColor5}
                                                size={170}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid
                                    item
                                    sm={12}
                                    xs={12}
                                    sx={{ mt: -15 }}
                                ></Grid>
                            </Grid>
                        </Box>
                    </Box>
                </PageBgImageCon>
            </Box>
        </>
    );
};

export default FullAppLoader;
