// => Update contacts
const GET_ACTIVE_SECTORS = "/sectors/active";

// => All announcement
const GET_ANNOUNCEMENTS = "/announcements/members";

// => Single announcement
const GET_SINGLE_ANNOUNCEMENT = "/announcements";

// => Bank accounts
const GET_BANK_ACCOUNTS = "/bank-details/active";

// => Regions
const GET_ACTIVE_REGIONS = "/regions/active";

export const adminEndpoints = {
    GET_ACTIVE_SECTORS,
    GET_ANNOUNCEMENTS,
    GET_SINGLE_ANNOUNCEMENT,
    GET_BANK_ACCOUNTS,
    GET_ACTIVE_REGIONS,
};
