import React from "react";
import Banner from "../components/Banner";
import MemberShipLevels from "../components/MemberShipLevels";
import VerifyCertficate from "../components/VerifyCertficate";

const HomePage = () => {
    return (
        <>
            {/* ############# Banner ############## */}
            <Banner />

            {/* ############# MemberShip ############## */}
            <MemberShipLevels />

            {/* ############# VerifyCertficate ############## */}
            <VerifyCertficate />
        </>
    );
};

export default HomePage;
