import React from "react";
import { useSelector } from "react-redux";
import { memberProfileSelector } from "../../../state/features/selectors";
import { Box, Typography } from "@mui/material";
import { colors } from "../../../assets/utils/colors";
import { Pending, PendingActions, Verified } from "@mui/icons-material";

const ProfileStatus2 = () => {
    const { memberProfile: profile } = useSelector(memberProfileSelector);
    return (
        <>
            <Box>
                <Box>
                    {profile.verificationStatus === "pending" ? (
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                mt: 1,
                                py: 1,
                                px: 4,
                                borderRadius: 2,
                                border: `1px dotted ${"brown"}`,
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor5})`,
                            }}
                        >
                            <Pending
                                sx={{
                                    fontSize: 25,
                                    color: "brown",
                                }}
                            />
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: "bold",
                                    ml: 0.5,
                                    color: "brown",
                                }}
                            >
                                {profile.verificationStatus}
                            </Typography>
                        </Box>
                    ) : profile.verificationStatus === "requested" ? (
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                mt: 1,
                                py: 1,
                                px: 4,
                                borderRadius: 2,
                                border: `1px dotted ${colors.primary}`,
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor5})`,
                            }}
                        >
                            <PendingActions
                                sx={{
                                    fontSize: 25,
                                    color: colors.primary,
                                }}
                            />
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: "bold",
                                    ml: 0.5,
                                    color: colors.primary,
                                }}
                            >
                                {profile.verificationStatus}
                            </Typography>
                        </Box>
                    ) : profile.verificationStatus === "verified" ? (
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                mt: 1,
                                py: 1,
                                px: 4,
                                borderRadius: 2,
                                border: `1px dotted ${colors.secondary}`,
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor5})`,
                            }}
                        >
                            <Verified
                                sx={{
                                    fontSize: 25,
                                    color: colors.secondary,
                                }}
                            />
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: "bold",
                                    ml: 0.5,
                                    color: colors.secondary,
                                }}
                            >
                                Verified
                            </Typography>
                        </Box>
                    ) : profile.verificationStatus === "denied" ? (
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                mt: 1,
                                py: 1,
                                px: 4,
                                borderRadius: 2,
                                border: `1px dotted ${"red"}`,
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor5})`,
                            }}
                        >
                            <Verified
                                sx={{
                                    fontSize: 25,
                                    color: "red",
                                }}
                            />
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: "bold",
                                    ml: 0.5,
                                    color: "red",
                                }}
                            >
                                Verified
                            </Typography>
                        </Box>
                    ) : (
                        ""
                    )}
                </Box>
            </Box>
        </>
    );
};

export default ProfileStatus2;
