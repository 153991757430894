import { ArrowRight, Person2Outlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";
import { colors } from "../../../assets/utils/colors";

const CustomButton1 = ({ title, startIcon, endIcon, action }) => {
    return (
        <>
            <Button
                size={"large"}
                onClick={action}
                startIcon={startIcon}
                endIcon={endIcon}
                fullWidth
                sx={{
                    py: { sm: 1.5, xs: 1 },
                    fontSize: { sm: 14, xs: 12 },
                    color: colors.bgColor1,
                    fontWeight: "bold",
                    backgroundImage: `linear-gradient(rgba(23, 148, 202, .5), rgba(23, 148, 202, .5)), radial-gradient(circle at left top, rgb(1, 110, 218), rgb(49, 180, 76, .5))`,
                }}
            >
                {title}
            </Button>
        </>
    );
};

export default CustomButton1;
