import React from 'react';
import { Box } from '@mui/material';
import Loader from 'react-js-loader';
import { colors } from '../assets/utils/colors';

const InnerContentLoader = ({ height }) => {
    return (
        <>
            <Box
                sx={{
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: height ? height : "100%",
                    position: height ? "relative" : "absolute",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                    }}
                >
                    <Box sx={{ position: "absolute" }}>
                        <Loader
                            // type="bubble-ping"
                            type="spinner-cub"
                            bgColor={colors.primary}
                            color={colors.primary}
                            size={100}
                        />
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default InnerContentLoader;
