import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    sectors: [],
    regions: [],
    bankDetails: [],
    announcements: [],
    announcementDetails: {},
};

// !########## SLICE #############
const adminSlice = createSlice({
    name: "adminSlice",
    initialState,
    reducers: {
        setActiveSectors: (state, action) => {
            state.sectors = action.payload;
        },
        setActiveRegions: (state, action) => {
            state.regions = action.payload;
        },
        setBankAccounts: (state, action) => {
            state.bankDetails = action.payload;
        },
        setAnnouncements: (state, action) => {
            state.announcements = action.payload;
        },
        setAnnouncementDetails: (state, action) => {
            state.announcementDetails = action.payload;
        },
    },
});

export const {
    setActiveSectors,
    setAnnouncementDetails,
    setBankAccounts,
    setAnnouncements,
    setActiveRegions,
} = adminSlice.actions;
export default adminSlice.reducer;
