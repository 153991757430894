import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { contactEndpoints } from "./contactEndpoints";
import { baseURL } from "../baseUrl/baseURL";
import { headers } from "../baseUrl/setHeaders";
import { profileAPI } from "../profileAPI/profileAPI";

export const contactsAPI = createApi({
    reducerPath: "contactsAPI",
    baseQuery: fetchBaseQuery({ baseUrl: baseURL, prepareHeaders: headers }),
    endpoints: (builder) => ({
        updateCompanyContacts: builder.mutation({
            query: (payload) => ({
                url: `${contactEndpoints.UPDATE_CONTACTS}`,
                method: `PUT`,
                body: payload,
            }),
            async onQueryStarted(_, { queryFulfilled, dispatch }) {
                try {
                    await queryFulfilled;
                    dispatch(profileAPI.util.invalidateTags(["Profile"]));
                } catch (err) {}
            },
        }),
    }),
});

export const { useUpdateCompanyContactsMutation } = contactsAPI;
