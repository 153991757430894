import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    licenses: [],
};

// !########## SLICE #############
const licenseSlice = createSlice({
    name: "licenseSlice",
    initialState,
    reducers: {
        setLicenses: (state, action) => {
            state.licenses = action.payload;
        },
    },
});

export const { setLicenses } = licenseSlice.actions;
export default licenseSlice.reducer;
