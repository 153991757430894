import React from "react";
import { Box, Button } from "@mui/material";
import {
    KeyboardDoubleArrowLeft,
    KeyboardDoubleArrowRight,
} from "@mui/icons-material";
// import CustomSelectFilter from "../Forms/CustomSelectFilter";
import { colors } from "../../assets/utils/colors";

const DataPagnation = ({ data, handleNext, handlePrev }) => {
    // => Page
    const total_pages = data?.total_pages;
    // => Links
    const previousUrl = data?.pre_page;
    const nextUrl = data?.next_page;
    let links = [];
    for (let index = 1; index <= total_pages; index++) {
        links.push(index);
    }
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: 40,
                    borderBottomRightRadius: 5,
                    borderBottomLeftRadius: 5,
                    px: 2,
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                }}
            >
                {/* PREVIOUS BTN */}
                <Button
                    size="small"
                    variant="outlined"
                    color="info"
                    startIcon={<KeyboardDoubleArrowLeft />}
                    disabled={previousUrl === null ? true : false}
                    onClick={() => {
                        handlePrev(previousUrl);
                    }}
                ></Button>

                {/* NEXT BTN */}
                <Button
                    size="small"
                    variant="outlined"
                    color="info"
                    disabled={nextUrl === null ? true : false}
                    endIcon={<KeyboardDoubleArrowRight />}
                    onClick={() => {
                        handleNext(nextUrl);
                    }}
                ></Button>
            </Box>
        </>
    );
};

export default DataPagnation;
