import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { CancelOutlined, OpenInFull, Verified } from "@mui/icons-material";
import { isEmpty } from "lodash";
import NoContent from "../NoContent";
import { colors } from "../../assets/utils/colors";
import PreviewAttachment from "../../pages/profile/components/PreviewAttachment";
import AddAttachment from "../../pages/profile/CRUD/AddAttachment";

const columns = [{ id: "attachmment", label: "" }];

function createData(attachmment) {
    return {
        attachmment,
    };
}

const AttachmentTable = ({ attachments, expandToggle }) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const rows = attachments?.map((attachment, index) => {
        return createData(
            <Box
                sx={{
                    background: `linear-gradient(180deg, ${colors.bgColor5}, ${colors.bgColor5})`,
                    p: 2,
                    ".header": {
                        fontSize: 14,
                        fontWeight: "bold",
                        mb: 1,
                        background: `linear-gradient(rgba(23, 148, 202, .8), rgb(49, 180, 76, .8))`,
                        backgroundClip: "text",
                        textFillColor: "transparent",
                    },
                    ".title": {
                        fontSize: 14,
                        fontWeight: "bold",
                        opacity: 0.8,
                    },
                    ".content-con": {
                        height: "100%",
                        bgcolor: "green",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    },
                }}
            >
                <Grid container spacing={2}>
                    <Grid item sm={1} xs={2}>
                        <Box
                            className="content-co"
                            sx={{
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: 2,
                                background: `linear-gradient(180deg, ${colors.bgColor5}, ${colors.bgColor4})`,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "bold",
                                    opacity: 0.8,
                                    fontStyle: "italic",
                                }}
                            >
                                {++index}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={5} xs={10}>
                        <Typography className="header">Attachment</Typography>
                        <Typography className="title">
                            {attachment.attachmentName}
                        </Typography>
                        <Typography sx={{ fontSize: 13 }}>
                            {attachment.attachmentNumber}
                        </Typography>
                    </Grid>
                    <Grid item sm={2} xs={4} sx={{ textAlign: "center" }}>
                        <Typography className="header">Status</Typography>
                        {attachment.status ? (
                            <Verified color="success" />
                        ) : (
                            <CancelOutlined color="error" />
                        )}
                    </Grid>
                    <Grid item sm={2} xs={4} sx={{ textAlign: "center" }}>
                        <Typography className="header">Preview</Typography>
                        <PreviewAttachment
                            attachment={attachment?.attachmentDocument}
                        />
                    </Grid>
                    <Grid item sm={2} xs={4} sx={{ textAlign: "center" }}>
                        <Typography className="header">Action</Typography>
                        <AddAttachment attachment={attachment} />
                    </Grid>
                </Grid>
            </Box>
        );
    });

    return (
        <Paper
            sx={{
                width: "100%",
                overflow: "hidden",
                boxShadow: "none !important",
            }}
        >
            <Box
                sx={{
                    position: "absolute",
                    bottom: 10,
                    left: 15,
                    zIndex: 999,
                }}
            >
                {expandToggle ? (
                    <IconButton
                        size="small"
                        color="info"
                        sx={{
                            background: `linear-gradient(90deg, ${colors.bgColor4}, ${colors.bgColor4})`,
                        }}
                        onClick={expandToggle}
                    >
                        <OpenInFull sx={{ fontSize: 20 }} />
                    </IconButton>
                ) : (
                    ""
                )}
            </Box>
            <TableContainer
                sx={{
                    height: expandToggle ? "100%" : "100%",
                    position: "relative",
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                }}
            >
                {isEmpty(attachments) ? (
                    <>
                        <NoContent
                            height={expandToggle ? "30vh" : "30vh"}
                            message={"No attachment added"}
                        />
                    </>
                ) : (
                    <Table stickyHeader>
                        {/* <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        sx={{
                                            textTransform: "uppercase",
                                            fontWeight: "bold",
                                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                                            fontSize: 12,
                                            color: colors.primary,
                                            whiteSpace: "nowrap",
                                            opacity: 0.8,
                                        }}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead> */}
                        <TableBody>
                            {rows
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((row) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.s_n}
                                            sx={{
                                                p: 0,
                                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                                            }}
                                        >
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        width={column.width}
                                                        sx={{
                                                            p: 0,
                                                        }}
                                                    >
                                                        {column.format &&
                                                        typeof value ===
                                                            "number"
                                                            ? column.format(
                                                                  value
                                                              )
                                                            : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                )}
            </TableContainer>
            {/* {!isEmpty(attachments) && (
                <TablePagination
                    rowsPerPageOptions={[1, 2, 5, 10]}
                    component="div"
                    count={rows?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{
                        ".MuiTablePagination-selectLabel, .MuiInputBase-root": {
                            display: "none",
                        },
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                    }}
                />
            )} */}
        </Paper>
    );
};

export default AttachmentTable;
