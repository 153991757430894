import * as React from "react";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { Avatar, Box, Stack, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { colors } from "../assets/utils/colors";
import {
    CircleNotifications,
    AccountCircle,
    WidgetsOutlined,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useDate } from "../hooks/useDate";
import { useSelector } from "react-redux";
import { memberProfileSelector } from "../state/features/selectors";
import NotificationBar from "./NotificationBar";

// ################# CONSTANT ###############
const drawerWidth = 250;

// ################# STYLED APPBAR ###############
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    boxShadow: "none",
    backgroundImage: `linear-gradient(rgba(23, 148, 202, .5), rgba(23, 148, 202, .5)), radial-gradient(circle at left top, rgb(49, 180, 76, .5), rgb(49, 180, 76, .2))`,
}));

// ################# TOPBAR RIGHT ###############
const TopbarRight = styled(Stack)({});

// !###################### MAIN FUNC ############################
const TopBar = ({ mobileOpen, handleDrawerToggle }) => {
    // -> date
    const date = useDate();
    const { memberProfile: profile } = useSelector(memberProfileSelector);

    const [openNotBar, setOpenNotBar] = React.useState(false);

    const toggleNotDrawer = () => {
        setOpenNotBar(true);
    };

    const closeNotDrawer = () => {
        setOpenNotBar(false);
    };

    return (
        <>
            <AppBar
                position="fixed"
                sx={{
                    width: {
                        xs: "100%",
                        sm: true
                            ? `calc(100% - ${drawerWidth}px)`
                            : `calc(100% - ${drawerWidth}px)`,
                    },
                }}
            >
                <Toolbar
                    sx={{
                        justifyContent: "space-between",
                    }}
                >
                    {/* ############### Notificationbar ###############*/}
                    <NotificationBar
                        openNotBar={openNotBar}
                        closeNotDrawer={closeNotDrawer}
                    />

                    {/* ################### LEFT ################# */}
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {mobileOpen ? (
                            <IconButton
                                onClick={handleDrawerToggle}
                                color="inherit"
                                size="small"
                                sx={{
                                    display: { xs: "block", sm: "none" },
                                }}
                            >
                                <CloseIcon sx={{ fontSize: 30 }} />
                            </IconButton>
                        ) : (
                            <IconButton
                                onClick={handleDrawerToggle}
                                color="inherit"
                                size="small"
                                sx={{
                                    display: { xs: "block", sm: "none" },
                                }}
                            >
                                <WidgetsOutlined sx={{ fontSize: 30 }} />
                            </IconButton>
                        )}
                        {/* <Typography
                            noWrap
                            component="div"
                            sx={{ display: "flex", alignItems: "center" }}
                        >
                            <Avatar src={logo} />
                        </Typography> */}
                        <Box
                            sx={{
                                display: {
                                    md: "flex",
                                    sm: "flex",
                                    xs: "none",
                                    flexDirection: "column",
                                },
                                width: "100%",
                            }}
                        >
                            <Typography sx={{ fontSize: 14 }}>
                                Welcome back
                            </Typography>
                            <Typography
                                sx={{ fontSize: 18, fontWeight: "bold" }}
                            >
                                {profile.companyName}
                            </Typography>
                        </Box>
                    </Box>

                    <Box>
                        <Stack
                            variant="div"
                            sx={{
                                display: { md: "none", sm: "none", xs: "flex" },
                                width: 100,
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: 2,
                                    p: 1,
                                    backgroundImage: `linear-gradient(rgba(23, 148, 202, .5), rgba(23, 148, 202, .5)), radial-gradient(circle at left top, rgb(1, 110, 218), rgb(49, 180, 76, .5))`,
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 13,
                                        color: colors.bgColor5,
                                        fontWeight: "bold",
                                    }}
                                >
                                    {date.time}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 11,
                                        color: colors.warning,
                                    }}
                                >
                                    {date.date}
                                </Typography>
                            </Box>
                        </Stack>
                    </Box>

                    {/* ################### RIGHT ################# */}
                    <TopbarRight
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                    >
                        <Link to={"/profile"}>
                            <IconButton
                                size="small"
                                sx={{
                                    background: `linear-gradient(180deg, ${colors.bgColor4}, ${colors.bgColor3})`,
                                }}
                            >
                                <AccountCircle color="primary" />
                            </IconButton>
                        </Link>
                        <IconButton
                            size="small"
                            sx={{
                                background: `linear-gradient(180deg, ${colors.bgColor4}, ${colors.bgColor3})`,
                            }}
                            onClick={() => toggleNotDrawer()}
                        >
                            <CircleNotifications color="primary" />
                        </IconButton>
                        <Stack
                            variant="div"
                            sx={{
                                display: { md: "flex", sm: "flex", xs: "none" },
                                width: 100,
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: 2,
                                    p: 1,
                                    backgroundImage: `linear-gradient(rgba(23, 148, 202, .5), rgba(23, 148, 202, .5)), radial-gradient(circle at left top, rgb(1, 110, 218), rgb(49, 180, 76, .5))`,
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 13,
                                        color: colors.bgColor5,
                                        fontWeight: "bold",
                                    }}
                                >
                                    {date.time}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 11,
                                        color: colors.warning,
                                    }}
                                >
                                    {date.date}
                                </Typography>
                            </Box>
                        </Stack>
                    </TopbarRight>
                </Toolbar>
            </AppBar>
        </>
    );
};

export default TopBar;
