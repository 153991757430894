// => Update member details
const UPDATE_DETAILS = "/members";

// => Request verification
const REQUEST_VERIFICATION = "/members/request-verification";

export const memberEndpoints = {
    UPDATE_DETAILS,
    REQUEST_VERIFICATION,
};
