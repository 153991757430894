import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { colors } from "../../assets/utils/colors";
import VerifyRegID from "../../pages/auth/CRUD/VerifyRegID";
import PhoneVerify from "../../pages/auth/CRUD/PhoneVerify";
import CompanyName from "../../pages/auth/CRUD/CompanyName";
import PasswordConfimation from "../../pages/auth/CRUD/PasswordConfimation";
import InfoConfimation from "../../pages/auth/CRUD/InfoConfimation";

const steps = [
    "Phone number verification",
    "Phone Verification",
    "Program | Occupation | Section",
    "Addition Infos",
];

const RegStepper = () => {
    // ########### Comp State ############
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());

    // ############ Redux State ##############

    // ############ FUNC ##############
    const handleNext = () => {
        let newSkipped = skipped;
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <Box sx={{ width: "100%", py: 2 }}>
            <Grid container padding={2} justifyContent={"center"}>
                <Grid
                    item
                    sm={12}
                    xs={12}
                    sx={{
                        display: { md: "flex", xs: "none" },
                        justifyContent: "center",
                    }}
                >
                    {/* <Stepper
                        activeStep={activeStep}
                        sx={{
                            py: 1.5,
                            opacity: 0.8,
                            borderRadius: 2,
                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.secondary})`,
                        }}
                    >
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>
                                        {label}
                                    </StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper> */}
                </Grid>
                <Grid item sm={11.5} xs={12}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: 22,
                                    mt: 1,
                                    ml: 1,
                                    color: colors.secondary,
                                    fontWeight: "bold",
                                    background: `linear-gradient(rgba(23, 148, 202, .8), rgb(49, 180, 76, .8))`,
                                    backgroundClip: "text",
                                    textFillColor: "transparent",
                                }}
                            >
                                Step {activeStep + 1} out of {5}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    ml: 1,
                                    color: colors.primary,
                                    fontWeight: "bold",
                                }}
                            >
                                {activeStep === 0
                                    ? "Member company name"
                                    : activeStep === 1
                                    ? "Taxpayer Identification Number (TIN)"
                                    : activeStep === 2
                                    ? "Member phone number verification"
                                    : activeStep === 3
                                    ? "Security setting (Login password)"
                                    : "Provided details confimation"}
                            </Typography>
                        </Box>
                        {/* <Typography>dsadsadsadas</Typography> */}
                    </Box>
                </Grid>
            </Grid>

            {activeStep === steps.length ? (
                <Box>
                    <InfoConfimation
                        steps={steps}
                        activeStep={activeStep}
                        handleNext={handleNext}
                        handleBack={handleBack}
                    />
                    {/* <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                        <Box sx={{ flex: "1 1 auto" }} />
                        <Button onClick={handleReset}>Reset</Button>
                    </Box> */}
                </Box>
            ) : (
                <Box>
                    {activeStep === 0 && (
                        <Box
                            sx={{
                                borderRadius: 2,
                                // position: "relative",
                            }}
                        >
                            <CompanyName
                                steps={steps}
                                activeStep={activeStep}
                                handleNext={handleNext}
                                handleBack={handleBack}
                            />
                        </Box>
                    )}
                    {activeStep === 1 && (
                        <Box
                            sx={{
                                mt: 2,
                                borderRadius: 2,
                                // position: "relative",
                                overflow: "auto",
                            }}
                        >
                            <VerifyRegID
                                steps={steps}
                                activeStep={activeStep}
                                handleNext={handleNext}
                                handleBack={handleBack}
                            />
                        </Box>
                    )}
                    {activeStep === 2 && (
                        <Box
                            sx={{
                                mt: 2,
                                borderRadius: 2,
                                // position: "relative",
                                overflow: "auto",
                            }}
                        >
                            <PhoneVerify
                                steps={steps}
                                activeStep={activeStep}
                                handleNext={handleNext}
                                handleBack={handleBack}
                            />
                        </Box>
                    )}
                    {activeStep === 3 && (
                        <Box
                            sx={{
                                mt: 2,
                                borderRadius: 2,
                                // position: "relative",
                                overflow: "auto",
                            }}
                        >
                            <PasswordConfimation
                                steps={steps}
                                activeStep={activeStep}
                                handleNext={handleNext}
                                handleBack={handleBack}
                            />
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default RegStepper;
