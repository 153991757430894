import { OutboundOutlined } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { colors } from "../../assets/utils/colors";
import { Link } from "react-router-dom";

const PageInfobox = ({ data }) => {
    return (
        <>
            <Box
                sx={{
                    height: 75,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    p: 2,
                    position: "relative",
                    borderRadius: 2,
                    overflow: "hidden",
                    border: `1px solid ${colors.bgColor2}`,
                    borderRight: `4px solid ${
                        data.color ? data.color : colors.primary
                    }`,
                    background: `linear-gradient(90deg, ${colors.bgColor5}, ${colors.bgColor4})`,
                }}
            >
                <Typography
                    sx={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        bottom: 0,
                        width: 40,
                        borderTopRightRadius: 50,
                        borderBottomRightRadius: 50,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "bold",
                        color: colors.bgColor5,
                        fontStyle: "italic",
                        background: `linear-gradient(45deg, ${colors.primary}, ${colors.secondary})`,
                    }}
                >
                    {data.total}
                </Typography>
                <Box>
                    <Typography
                        sx={{
                            textAlign: "center",
                            fontWeight: "bold",
                            fontSize: 11,
                            color: colors.primary,
                        }}
                    >
                        {data.title1}
                    </Typography>
                    <Typography
                        sx={{
                            textAlign: "center",
                            fontWeight: "bold",
                            fontSize: 13,
                            color: colors.primary,
                        }}
                    >
                        {data.title2}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        position: "absolute",
                        right: 0,
                        bottom: 0,
                    }}
                >
                    <Link to={data.url}>
                        <IconButton
                            color="info"
                            size="small"
                            sx={{
                                background: `linear-gradient(45deg, ${colors.bgColor2}, ${colors.bgColor5})`,
                                ".icon": {
                                    transition: `.3s`,
                                },
                                ":hover": {
                                    ".icon": {
                                        transition: `.3s`,
                                        transform: `rotate(45deg)`,
                                    },
                                },
                            }}
                        >
                            <OutboundOutlined className="icon" />
                        </IconButton>
                    </Link>
                </Box>
            </Box>
        </>
    );
};

export default PageInfobox;
