import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../baseUrl/baseURL";
import { headers } from "../baseUrl/setHeaders";
import { profileEndpoints } from "./profileEndpoints";

export const profileAPI = createApi({
    reducerPath: "profileAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: baseURL,
        prepareHeaders: headers,
    }),
    tagTypes: ["Profile"],
    endpoints: (builder) => ({
        // => Profile
        getProfile: builder.query({
            query: () => `${profileEndpoints.GET_PROFILE}`,
            providesTags: ["Profile"],
        }),
        // => Update profile
        updateProfile: builder.mutation({
            query: (payload) => ({
                url: `${profileEndpoints.UPDATE_PROFILE}`,
                method: `PUT`,
                body: payload,
            }),
            invalidatesTags: ["Profile"],
        }),
        // => Update profile picture
        updateProfilePicture: builder.mutation({
            query: (payload) => ({
                url: `${profileEndpoints.UPDATE_PROFILE_PICTURE}`,
                method: `POST`,
                body: payload,
            }),
            invalidatesTags: ["Profile"],
        }),
        // => Change password
        changePassword: builder.mutation({
            query: (payload) => ({
                url: `${profileEndpoints.CHANGE_PASSWORD}`,
                method: `PUT`,
                body: payload,
            }),
            invalidatesTags: ["Profile"],
        }),
    }),
});

export const {
    useGetProfileQuery,
    useLazyGetProfileQuery,
    useUpdateProfileMutation,
    useUpdateProfilePictureMutation,
    useChangePasswordMutation,
} = profileAPI;
