import React from "react";
import { Grid, Typography } from "@mui/material";
import { Formik } from "formik";
import {
    ArrowRightAltOutlined,
    VerifiedUserOutlined,
} from "@mui/icons-material";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import useRTK from "../../../hooks/useRTK";
import PhoneVerification from "./PhoneVerification";
import { colors } from "../../../assets/utils/colors";
import CustomTextField from "../../../components/forms/CustomTextField";
import { registerSelector } from "../../../state/features/selectors";
import { setPinId } from "../../../state/features/register/registerSlice";
import { useRequestOTPMutation } from "../../../api/registerAPI/registerAPI";
import NextAndBackBtn from "../../../components/steppers/NextAndBackBtn";
import CustomSubmitButton1 from "../../../components/forms/buttons/CustomSubmitButton1";
import DialogLinerProgress from "../../../components/DialogLinerProgress";
import { isEmpty } from "lodash";

// ############# Validation Schema ##############
const validationSchema = Yup.object({
    phoneNumber: Yup.string()
        .required("Phone number is required field")
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            "Positions must be a number"
        )
        .max(10, "Phone number must be at most 10 characters")
        .min(10, "Phone number must be at least 10 characters"),
});

const PhoneVerify = ({ steps, activeStep, handleNext, handleBack }) => {
    // ################# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############## Redux status ############
    const { phoneNumber, isPhoneVerified, pinId } =
        useSelector(registerSelector);

    // ############### RTK #####################
    // => Request OTP
    const [
        requestOTP,
        { isLoading, isSuccess, isError, error, data: otpData },
    ] = useRequestOTPMutation();

    // ############### useEffect ################
    // => RTK action
    const rtkActions = (data) => {
        dispatch(setPinId(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, otpData, rtkActions);

    return (
        <>
            <DialogLinerProgress isLoading={isLoading} />
            {isEmpty(pinId) ? (
                <>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            phoneNumber: phoneNumber,
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(payload) => {
                            requestOTP(payload);
                        }}
                    >
                        {(formik) => (
                            <Grid
                                container
                                justifyContent={"center"}
                                padding={2}
                                py={{ md: 2 }}
                            >
                                <Grid item sm={11} xs={12}>
                                    <Typography sx={{ fontSize: 14 }}>
                                        Please provide member phone number for
                                        verification
                                    </Typography>
                                </Grid>
                                <Grid item sm={11} xs={12} sx={{ mb: 4 }}>
                                    <CustomTextField
                                        className="form-input"
                                        label={`Phone number`}
                                        type="text"
                                        name="phoneNumber"
                                        bBottom={colors.secondary}
                                    />
                                </Grid>

                                {/* ################ ACTION BUTONS ###################### */}
                                <Grid
                                    item
                                    sm={11}
                                    xs={12}
                                    sx={{
                                        textAlign: "center",
                                    }}
                                >
                                    <CustomSubmitButton1
                                        startIcon={<VerifiedUserOutlined />}
                                        endIcon={<ArrowRightAltOutlined />}
                                        variant={"contained"}
                                        loading={isLoading}
                                        onClick={() => {
                                            formik.handleSubmit();
                                        }}
                                    >
                                        Request verification OTP
                                    </CustomSubmitButton1>
                                </Grid>
                                <Grid
                                    item
                                    sm={11}
                                    xs={12}
                                    sx={{
                                        marginTop: 2.5,
                                        textAlign: "center",
                                    }}
                                >
                                    <NextAndBackBtn
                                        steps={steps}
                                        activeStep={activeStep}
                                        handleBack={handleBack}
                                        action={handleNext}
                                        disabled={
                                            isPhoneVerified === true
                                                ? false
                                                : true
                                        }
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Formik>
                </>
            ) : (
                <>
                    <PhoneVerification
                        handleNext={handleNext}
                        phoneNumber={phoneNumber}
                    />
                </>
            )}
        </>
    );
};

export default PhoneVerify;
