import React from "react";
import MemberShipLevelWidget2 from "../../components/widgets/MemberShipLevelWidget2";
import { Grid } from "@mui/material";
import PageLayout from "../layouts/PageLayout";
import {
    memberProfileSelector,
    membershipLevelSelector,
} from "../../state/features/selectors";
import { useSelector } from "react-redux";
import NoContent from "../../components/NoContent";
import { isEmpty } from "lodash";

const MemberShipLevels = () => {
    // ########### Redux state #################
    const { membershipLevels } = useSelector(membershipLevelSelector);
    const { memberProfile: profile } = useSelector(memberProfileSelector);

    return (
        <>
            <PageLayout head={"Membership level's"} noSidebar>
                <Grid
                    container
                    columnSpacing={{ sm: 2, xs: 0 }}
                    rowSpacing={4}
                    justifyContent={{ sm: "left", xs: "center" }}
                >
                    {isEmpty(membershipLevels) ? (
                        <Grid item sm={12}>
                            <NoContent
                                height={"55vh"}
                                message={"No active membership level"}
                            />
                        </Grid>
                    ) : (
                        <>
                            {membershipLevels.map((level) => (
                                <Grid item md={4} sm={6} xs={11} key={level.id}>
                                    <MemberShipLevelWidget2
                                        level={level}
                                        isSubscribed={
                                            level.id ===
                                            profile?.membershipLevel
                                                ? true
                                                : false
                                        }
                                    />
                                </Grid>
                            ))}
                        </>
                    )}
                </Grid>
            </PageLayout>
        </>
    );
};

export default MemberShipLevels;
