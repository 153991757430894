import React from "react";
import { useSelector } from "react-redux";
import { memberProfileSelector } from "../../../state/features/selectors";
import { Box, Typography } from "@mui/material";

const ProfileStatus = () => {
    const { memberProfile: profile } = useSelector(memberProfileSelector);
    return (
        <>
            <Box>
                <Box>
                    {profile.verificationStatus === "pending" ? (
                        <Typography className="error-box" sx={{ fontSize: 12 }}>
                            Ensure you submit all necessary informations for
                            company verification. Remember that accurate and
                            complete information is crucial for a successful
                            verification process. Good luck! 🌟 Certainly! When
                            submitting information for company verification,
                            make sure to include the following essential
                            details:
                        </Typography>
                    ) : profile.verificationStatus === "requested" ? (
                        <Typography className="info-box" sx={{ fontSize: 12 }}>
                            Congratulations! on submitting your profile
                            information for verification! 🎉 Please be patient
                            while our team reviews it. We'll notify you once the
                            verification process is complete. Thank you for your
                            cooperation! 😊
                        </Typography>
                    ) : profile.verificationStatus === "verified" ? (
                        <Typography
                            className="success-box"
                            sx={{ fontSize: 12 }}
                        >
                            Thank you for your patience and cooperation! We
                            appreciate the time you took to submit your profile
                            information, and we're pleased to inform you that
                            your verification process has been successfully
                            completed. 🎉
                        </Typography>
                    ) : profile.verificationStatus === "denied" ? (
                        <Typography
                            className="success-box"
                            sx={{ fontSize: 12 }}
                        >
                            We apologize for any inconvenience caused. It
                            appears that some of the information you submitted
                            did not pass our verification process, resulting in
                            a denial. Please take a moment to carefully review
                            the details you provided and cross-check them with
                            the message we sent to you. Once you have corrected
                            any discrepancies, feel free to request verification
                            again. Thank you for your understanding and
                            cooperation. 🙏
                        </Typography>
                    ) : (
                        ""
                    )}
                </Box>
            </Box>
        </>
    );
};

export default ProfileStatus;
