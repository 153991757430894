import React from "react";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import { APP_FOR_1, APP_FOR_2, APP_FOR_3 } from "../assets/utils/constant";
import SpacingContent from "../components/SpacingContent";
import { colors } from "../assets/utils/colors";
import { AccountBalance } from "@mui/icons-material";
import { SwiperSlide } from "swiper/react";
import CustomSwiper from "./swipers/CustomSwiper";
import logo from "../assets/media/images/logo.png";
import img2 from "../assets/media/images/img2.jpg";
import img4 from "../assets/media/images/img4.png";
import Register from "../pages/auth/Register";
import CustomButton1 from "./forms/buttons/CustomButton1";

const Banner = () => {
    const goToMemberShipLevels = () => {
        window.scrollTo(0, 550);
    };

    const showCaseImages = [
        {
            id: 3,
            img: img2,
        },
        {
            id: 4,
            img: img4,
        },
    ];
    return (
        <div>
            <Box
                sx={{
                    height: "100vh",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: `linear-gradient(360deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                }}
            >
                <Box sx={{ position: "absolute", opacity: 0.05, zIndex: 10 }}>
                    <Avatar
                        src={logo}
                        sx={{
                            height: 500,
                            width: { sm: 500, xs: "100%" },
                            img: {
                                objectFit: "contain",
                            },
                        }}
                    />
                </Box>
                <SpacingContent>
                    <Grid
                        container
                        sx={{ zIndex: 999 }}
                        justifyContent={"space-between"}
                    >
                        <Grid
                            item
                            sm={6}
                            xs={12}
                            sx={{
                                zIndex: 999,
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: { sm: 60, xs: 45 },
                                    fontWeight: "bold",
                                    lineHeight: 1,
                                    color: colors.primary,
                                    textAlign: { sm: "left", xs: "center" },
                                }}
                            >
                                Tanzania
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: { sm: 60, xs: 45 },
                                    fontWeight: "bold",
                                    color: colors.primary,
                                    textAlign: { sm: "left", xs: "center" },
                                }}
                            >
                                Chamber of
                            </Typography>
                            <Typography
                                sx={{ textAlign: { sm: "left", xs: "center" } }}
                            >
                                <Typography
                                    component={"span"}
                                    sx={{
                                        fontSize: { sm: 25, xs: 15 },
                                        textTransform: "uppercase",
                                        fontWeight: "bold",
                                        color: colors.primary,
                                        textAlign: "center",
                                        pr: 2,
                                    }}
                                >
                                    {APP_FOR_1}
                                </Typography>
                                <Typography
                                    component={"span"}
                                    sx={{
                                        fontSize: { sm: 25, xs: 15 },
                                        textTransform: "uppercase",
                                        fontWeight: "bold",
                                        color: colors.secondary,
                                        borderRight: `3px double ${colors.orange}`,
                                        borderLeft: `3px double ${colors.orange}`,
                                        textAlign: "center",
                                        px: 2,
                                    }}
                                >
                                    {APP_FOR_2}
                                </Typography>
                                <Typography
                                    component={"span"}
                                    sx={{
                                        fontSize: { sm: 25, xs: 15 },
                                        textTransform: "uppercase",
                                        fontWeight: "bold",
                                        color: colors.primary,
                                        textAlign: "center",
                                        pl: 2,
                                    }}
                                >
                                    {APP_FOR_3}
                                </Typography>
                            </Typography>
                            <Box sx={{ mt: 5 }}>
                                <Grid
                                    container
                                    justifyContent={{
                                        sm: "left",
                                        xs: "center",
                                    }}
                                    spacing={2}
                                    padding={{ md: 0, sm: 0, xs: 2 }}
                                >
                                    <Grid item md={4} sm={6} xs={6}>
                                        <CustomButton1
                                            startIcon={<AccountBalance />}
                                            title={"Membership levels"}
                                            action={goToMemberShipLevels}
                                        />
                                    </Grid>
                                    <Grid item md={4} sm={6} xs={6}>
                                        <Register />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            sm={5}
                            xs={12}
                            sx={{
                                display: { md: "flex" },
                                // display: { md: "flex", xs: "none", sm: "none" },
                                // justifyContent: "center",
                            }}
                        >
                            <Box
                                sx={{
                                    height: { sm: 550, xs: 150 },
                                    mt: { sm: 10, xs: 4 },
                                    width: "100%",
                                    zIndex: 999,
                                    borderRadius: 5,
                                    borderTopLeftRadius: { sm: 250, xs: 25 },
                                    borderBottomLeftRadius: { sm: 250, xs: 25 },
                                    borderTopRightRadius: { sm: 0, xs: 25 },
                                    borderBottomRightRadius: {
                                        sm: 0,
                                        xs: 25,
                                    },
                                    position: {
                                        sm: "relative",
                                        xs: "absolute",
                                    },
                                    overflow: "hidden",
                                    border: `5px solid ${colors.bgColor5}`,
                                    // borderLeft: `15px solid ${colors.secondary}`,
                                    background: `linear-gradient(360deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                                }}
                            >
                                <CustomSwiper
                                    element={"blog"}
                                    conHeight={{ sm: 550, xs: "100%" }}
                                >
                                    {showCaseImages.map((image) => (
                                        <SwiperSlide
                                            key={image.id}
                                            style={{
                                                // border: `4px solid ${colors.bgColor4}`,
                                                borderRadius: 25,
                                                background: "white",
                                            }}
                                        >
                                            <Box>
                                                <Avatar
                                                    variant="square"
                                                    src={image.img}
                                                    sx={{
                                                        height: {
                                                            sm: "80vh",
                                                            xs: "45vh",
                                                        },
                                                        width: "100%",
                                                        img: {
                                                            objectFit: "cover",
                                                        },
                                                    }}
                                                />
                                            </Box>
                                        </SwiperSlide>
                                    ))}
                                </CustomSwiper>
                            </Box>
                        </Grid>
                    </Grid>
                </SpacingContent>
            </Box>
        </div>
    );
};

export default Banner;
