import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../baseUrl/baseURL";
import { headers } from "../baseUrl/setHeaders";
import { membershipLevelEndpoints } from "./membershipLevelEndpoints";
import { invoiceAPI } from "../invoiceAPI/invoiceAPI";

export const membershipLevelAPI = createApi({
    reducerPath: "membershipLevelAPI",
    baseQuery: fetchBaseQuery({ baseUrl: baseURL, prepareHeaders: headers }),
    endpoints: (builder) => ({
        // GET membership levels
        getMemberShipLevels: builder.query({
            query: () => `${membershipLevelEndpoints.GET_MEMBERSHIP_LEVELS}`,
        }),

        // GET active membership levels
        getActiveMemberShipLevels: builder.query({
            query: () =>
                `${membershipLevelEndpoints.GET_ACTIVE_MEMBERSHIP_LEVELS}`,
        }),

        // => ADD membership level
        addMembershipLevel: builder.mutation({
            query: (payload) => ({
                url: `${membershipLevelEndpoints.ADD_MEMBERSHIP_LEVEL}`,
                method: `PUT`,
                body: payload,
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(
                        invoiceAPI.util.invalidateTags(["Invoice", "Invoices"])
                    );
                } catch (error) {}
            },
        }),

        // => UPDATE membership level
        updateMembershipLevel: builder.mutation({
            query: (payload) => ({
                url: `${membershipLevelEndpoints.UPDATE_MEMBERSHIP_LEVEL}`,
                method: `PUT`,
                body: payload,
            }),
        }),
    }),
});

export const {
    useAddMembershipLevelMutation,
    useUpdateMembershipLevelMutation,

    // => Lazy
    useLazyGetMemberShipLevelsQuery,
    useLazyGetActiveMemberShipLevelsQuery,
} = membershipLevelAPI;
