import React from "react";
import { Grid } from "@mui/material";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { ArrowRight, ReceiptLongOutlined } from "@mui/icons-material";
import useRTK from "../../../hooks/useRTK";
import { colors } from "../../../assets/utils/colors";
import { setMemberProfile } from "../../../state/features/profile/memberProfileSlice";
import CustomFileInput from "../../../components/forms/CustomFileInput";
import CustomSubmitButton1 from "../../../components/forms/buttons/CustomSubmitButton1";
import CustomTextField from "../../../components/forms/CustomTextField";
import { usePayInvoiceMutation } from "../../../api/invoiceAPI/invoiceAPI";

// ################## VALIDATION ####################
const attachmentValidationSchema = Yup.object().shape({
    invoice_id: Yup.string().required("Invoice id is required"),
    paymentSlip: Yup.mixed().required("Payment slip is required"),
    paymentDate: Yup.string().required("Payment date is required"),
});

// !############## MAIN FUNC ################
const PayInvoiceForm = ({ closeDialog, invoice }) => {
    // ################# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############## RTK ##################
    const [
        payInvoice,
        { isLoading, isSuccess, isError, error, data: updatedData },
    ] = usePayInvoiceMutation();

    // ############### useEffect ################
    // => RTK action
    const rtkActions = (data) => {
        closeDialog();
        dispatch(setMemberProfile(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, updatedData, rtkActions);

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    invoice_id: invoice.id,
                    paymentSlip: "",
                    paymentDate: "",
                }}
                validationSchema={attachmentValidationSchema}
                onSubmit={(payload) => {
                    const data = new FormData();
                    data.append("invoice_id", payload.invoice_id);
                    data.append("paymentSlip", payload.paymentSlip);
                    data.append("paymentDate", payload.paymentDate);
                    payInvoice(data);
                }}
            >
                {(formik) => (
                    <Form>
                        <Grid
                            container
                            justifyContent={"center"}
                            spacing={2}
                            sx={{ py: 2 }}
                        >
                            <Grid item sm={6} xs={12}>
                                <CustomTextField
                                    type="date"
                                    name="paymentDate"
                                    label={"Payment date"}
                                    bBottom={colors.secondary}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <CustomFileInput
                                    name="paymentSlip"
                                    type="file"
                                    bBottom={colors.secondary}
                                    formik={formik}
                                    inputName={"paymentSlip"}
                                />
                            </Grid>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <CustomSubmitButton1
                                loading={isLoading}
                                btnColor={"primary"}
                                startIcon={<ReceiptLongOutlined />}
                                endIcon={<ArrowRight />}
                                onClick={formik.handleSubmit}
                            >
                                Upload payment receipt
                            </CustomSubmitButton1>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default PayInvoiceForm;
