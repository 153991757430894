import React from "react";
import PageLayout from "../layouts/PageLayout";
import CustomPagination2 from "../../components/paginations/CustomPagination2";
import { Box, Grid } from "@mui/material";
import { paginator } from "../../helpers/paginationHelper";
import NoContent from "../../components/NoContent";
import { filter, isEmpty, size } from "lodash";
import PageInfobox from "../../components/infoboxes/PageInfobox";
import InvoiceWidget from "../../components/widgets/InvoiceWidget";
import { colors } from "../../assets/utils/colors";
import { invoiceSelector } from "../../state/features/selectors";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import InvoiceSidebar from "../../components/sidebars/InvoiceSidebar";

const InvoiceByStatusPage = () => {
    // ############## Comp state ##################
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(5);
    const { status } = useParams();

    // ########### Redux state #################
    const { invoices } = useSelector(invoiceSelector);

    // ############## FUNC ####################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };
    // => Get invoice by status
    const getInvoiceByStatus = () => {
        let filteredInvoices = [];
        // => Paid
        if (status === "paid") {
            filteredInvoices = filter(invoices, { isPaid: 1 });
            // =>  Un-paid
        } else if (status === "un-paid") {
            filteredInvoices = filter(invoices, { isPaid: 0 });
            // => Pending approved
        } else if (status === "pending-approved") {
            filteredInvoices = filter(invoices, {
                invoiceStatus: "waiting_approval",
            });
            // => Rejected invoice
        } else if (status === "rejected") {
            filteredInvoices = filter(invoices, { invoiceStatus: "rejected" });
        }

        return filteredInvoices;
    };
    const invoiceByStatus = getInvoiceByStatus();

    // => Pagination data
    const paginatedInvoices = paginator(invoiceByStatus, page, perPage);

    return (
        <>
            <PageLayout
                head={`${status} invoice management`}
                sidebar={<InvoiceSidebar />}
            >
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <Box>
                            <CustomPagination2
                                head={`All ${status} invoices`}
                                height={"62vh"}
                                handlePageChange={handlePageChange}
                                handlePerPageChage={handlePerPageChage}
                                data={paginatedInvoices}
                            >
                                {isEmpty(invoiceByStatus) ? (
                                    <>
                                        <NoContent
                                            height={300}
                                            message={`No ${status} invoices`}
                                        />
                                    </>
                                ) : (
                                    <Grid container spacing={3}>
                                        {paginatedInvoices.data.map(
                                            (invoice) => (
                                                <Grid
                                                    item
                                                    sm={12}
                                                    key={invoice.id}
                                                >
                                                    <InvoiceWidget
                                                        invoice={invoice}
                                                    />
                                                </Grid>
                                            )
                                        )}
                                    </Grid>
                                )}
                            </CustomPagination2>
                        </Box>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default InvoiceByStatusPage;
