import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Slide, Tooltip } from "@mui/material";
import { colors } from "../../assets/utils/colors";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            <Tooltip title={"Close dialog"} arrow>
                <IconButton
                    className="neo-bulge"
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: colors.secondary,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </Tooltip>
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const CustomDialog = ({
    openDialog,
    closeDialog,
    head,
    noDropClose,
    children,
    fullScreen,
    customWidth,
}) => {
    return (
        <>
            <BootstrapDialog
                onClose={noDropClose ? undefined : closeDialog}
                open={openDialog}
                TransitionComponent={Transition}
                fullWidth
                fullScreen={fullScreen}
                maxWidth={customWidth && customWidth}
                sx={{
                    " .MuiPaper-root": {
                        borderRadius: fullScreen ? 0 : 5,
                        boxShadow:
                            "-8px -8px 12px 0 rgba(0, 0, 0, .1) inset !important",
                        border: `10px solid ${colors.bgColor2}`,
                    },
                }}
            >
                {head && (
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={closeDialog}
                        sx={{
                            fontSize: 16,
                            fontWeight: "bold",
                            backgroundColor: head
                                ? colors.bgColor2
                                : "transparent",
                            color: colors.primary,
                        }}
                    >
                        {head}
                    </BootstrapDialogTitle>
                )}
                <DialogContent
                    sx={{
                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                    }}
                >
                    {children}
                </DialogContent>
            </BootstrapDialog>
        </>
    );
};

export default CustomDialog;
