import { Grid } from "@mui/material";
import React from "react";
import ActiveSubscriptionWidgetTwo from "../widgets/ActiveSubscriptionWidgetTwo";

const SubscriptionSidebar = () => {
    return (
        <div>
            <Grid container rowGap={2}>
                <Grid item sm={12} xs={12}>
                    <ActiveSubscriptionWidgetTwo />
                </Grid>
            </Grid>
        </div>
    );
};

export default SubscriptionSidebar;
