import React from "react";
import CustomCard from "../../../components/cards/CustomCard";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import { colors } from "../../../assets/utils/colors";
import UpdateGeneralDetails from "../CRUD/UpdateGeneralDetails";
import Infobox2 from "../../../components/infoboxes/Infobox2";
import Infobox3 from "../../../components/infoboxes/Infobox3";
import { filter } from "lodash";

const CompanyDetails = ({ profile }) => {
    const getAttachment = (type) => {
        let attachment = filter(
            profile?.attachments,
            ({ attachmentType }) => attachmentType === type
        );
        if (attachment) {
            return attachment[0];
        } else {
            return false;
        }
    };

    const logo = getAttachment("logo");

    return (
        <>
            <CustomCard
                title={"Company details"}
                action={<UpdateGeneralDetails memberCompany={profile} />}
            >
                <Grid container padding={{ sm: 4, md: 4, xs: 2 }}>
                    <Grid item sm={3} xs={12}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Box>
                                <Avatar
                                    src={logo?.attachmentDocument}
                                    variant="square"
                                    sx={{
                                        height: { sm: 150, md: 150, xs: 90 },
                                        width: { sm: 150, md: 150, xs: 200 },
                                        p: 1,
                                        borderRadius: 5,
                                        color: colors.secondary,
                                        border: `2px dotted ${colors.primary}`,
                                        background: `linear-gradient(360deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                                        img: {
                                            objectFit: "contain",
                                        },
                                    }}
                                />
                            </Box>
                            <Typography
                                sx={{
                                    fontSize: { md: 16, sm: 16, xs: 11 },
                                    fontWeight: "bold",
                                    opacity: 0.8,
                                    textAlign: "center",
                                    my: { sm: 3, xs: 3 },
                                }}
                            >
                                Company logo
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={9} xs={12}>
                        <Grid container spacing={2}>
                            <Grid item sm={6}>
                                <Typography
                                    sx={{
                                        fontSize: { md: 22, xs: 16 },
                                        fontWeight: "bold",
                                        background: `linear-gradient(rgba(23, 148, 202, .8), rgb(49, 180, 76, .8))`,
                                        backgroundClip: "text",
                                        textFillColor: "transparent",
                                    }}
                                >
                                    {profile.companyName}
                                </Typography>
                                {profile.companySector ? (
                                    <Infobox3
                                        title={"Company sector"}
                                        value={profile.companySector.sectorName}
                                    />
                                ) : (
                                    <Infobox2 title={"Company sector"} />
                                )}
                                {profile.companyShortName ? (
                                    <Infobox3
                                        title={"Short name"}
                                        value={profile.companyShortName}
                                    />
                                ) : (
                                    <Infobox2 title={"Short name"} />
                                )}
                                {profile.establishmentYear ? (
                                    <Infobox3
                                        title={"Establishement year"}
                                        value={profile.establishmentYear}
                                    />
                                ) : (
                                    <Infobox2 title={"Establishement year"} />
                                )}
                            </Grid>
                            <Grid item sm={6}>
                                {profile.certOfIncoporation ? (
                                    <Infobox3
                                        title={"Incoporate certificate number"}
                                        value={
                                            profile.certOfIncoporation
                                                .attachmentNumber
                                        }
                                    />
                                ) : (
                                    <Infobox2
                                        title={"Incoporate certificate number"}
                                    />
                                )}
                                {profile.businessLicense ? (
                                    <Infobox3
                                        title={"Business lisence number"}
                                        value={
                                            profile.businessLicense
                                                .attachmentNumber
                                        }
                                    />
                                ) : (
                                    <Infobox2
                                        title={"Business lisence number"}
                                    />
                                )}
                            </Grid>
                            <Grid item sm={12}>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            fontWeight: "bold",
                                            color: colors.primary,
                                        }}
                                    >
                                        Company description
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: {
                                                ms: 13,
                                                sm: 13,
                                                xs: 12,
                                            },
                                        }}
                                    >
                                        {profile.description ? (
                                            profile.description
                                        ) : (
                                            <Infobox2 title={""} />
                                        )}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CustomCard>
        </>
    );
};

export default CompanyDetails;
