// => Add contact person
const ADD_CONTACT_PERSON = "/contact-people";

// => Update contact person
const UPDATE_CONTACT_PERSON = "/contact-people";

export const contactPersonEndpoints = {
    ADD_CONTACT_PERSON,
    UPDATE_CONTACT_PERSON,
};
