import React from "react";
import { Box, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ErrorMessage, useField } from "formik";

const CustomInput = styled(TextField)({});

const CustomMultlineTextField = ({
    label,
    type,
    value,
    bBottom,
    rows,
    ...props
}) => {
    const [field, meta] = useField(props);
    return (
        <>
            <Box sx={{ marginTop: 2 }}>
                <CustomInput
                    error={meta.touched && meta.error ? true : false}
                    // label={label}
                    variant="outlined"
                    fullWidth
                    type={type}
                    placeholder={label}
                    {...field}
                    {...props}
                    size="small"
                    multiline
                    rows={rows ? rows : 6}
                    // value={value && value}
                    sx={{
                        borderBottom: `1px solid ${bBottom}`,
                    }}
                />
                <ErrorMessage
                    component="span"
                    name={field.name}
                    className="error-text"
                />
            </Box>
        </>
    );
};

export default CustomMultlineTextField;
