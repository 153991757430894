import React from "react";
import PageLayout from "../layouts/PageLayout";
import CustomPagination2 from "../../components/paginations/CustomPagination2";
import { Box, Grid } from "@mui/material";
import { paginator } from "../../helpers/paginationHelper";
import NoContent from "../../components/NoContent";
import { isEmpty, size } from "lodash";
import { licenseSelector } from "../../state/features/selectors";
import { useSelector } from "react-redux";
import CountBadge from "../../components/CountBadge";
import SubscriptionWidget from "../../components/widgets/SubscriptionWidget";
import SubscriptionSidebar from "../../components/sidebars/SubscriptionSidebar";

const SubscriptionsPage = () => {
    // ############## Comp state ##################
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(5);

    // ########### Redux state #################
    const { licenses } = useSelector(licenseSelector);

    // ############## FUNC ####################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };

    // => Pagination data
    const paginatedSubscriptions = paginator(licenses, page, perPage);

    return (
        <>
            <PageLayout
                head={"Member certificate management"}
                sidebar={<SubscriptionSidebar />}
                noSidebar
            >
                <Grid container spacing={2}>
                    <Grid item sm={12} xs={12}>
                        <Box>
                            <CustomPagination2
                                head={"All certificates"}
                                height={"60vh"}
                                handlePageChange={handlePageChange}
                                handlePerPageChage={handlePerPageChage}
                                data={paginatedSubscriptions}
                                action={
                                    <CountBadge
                                        item={"Certificates"}
                                        total={size(licenses)}
                                    />
                                }
                            >
                                {isEmpty(licenses) ? (
                                    <>
                                        <NoContent
                                            height={300}
                                            message={"No generated certificate"}
                                        />
                                    </>
                                ) : (
                                    <Grid container spacing={3}>
                                        {paginatedSubscriptions.data.map(
                                            (license) => (
                                                <Grid
                                                    item
                                                    sm={12}
                                                    xs={12}
                                                    key={license.id}
                                                >
                                                    <SubscriptionWidget
                                                        license={license}
                                                    />
                                                </Grid>
                                            )
                                        )}
                                    </Grid>
                                )}
                            </CustomPagination2>
                        </Box>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default SubscriptionsPage;
