import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { registerEndpoints } from "./registerEndpoints";
import { baseURL } from "../baseUrl/baseURL";

export const registerAPI = createApi({
    reducerPath: "registerAPI",
    baseQuery: fetchBaseQuery({ baseUrl: baseURL }),
    endpoints: (builder) => ({
        // => Verify Company name
        verifyCompanyName: builder.mutation({
            query: (payload) => ({
                url: `${registerEndpoints.VERIFY_COMPANY_NAME}`,
                method: `POST`,
                body: payload,
            }),
        }),
        // => Verify ICN
        verifyICN: builder.mutation({
            query: (payload) => ({
                url: `${registerEndpoints.VERIFY_ICN}`,
                method: `POST`,
                body: payload,
            }),
        }),
        // => Register
        register: builder.mutation({
            query: (payload) => ({
                url: `${registerEndpoints.REGISTER}`,
                method: "POST",
                body: payload,
            }),
        }),
        // => Request OTP
        requestOTP: builder.mutation({
            query: (payload) => ({
                url: `${registerEndpoints.REQUEST_OTP}`,
                method: `POST`,
                body: payload,
            }),
        }),
        // => Verify OTP
        verifyOTP: builder.mutation({
            query: (payload) => ({
                url: `${registerEndpoints.VERIFY_OTP}`,
                method: `POST`,
                body: payload,
            }),
        }),
        // => Forgot Password
        forgotPassword: builder.mutation({
            query: (payload) => ({
                url: `${registerEndpoints.FORGOT_PASSWORD}`,
                method: `POST`,
                body: payload,
            }),
        }),
        // => Reset password
        resetPassword: builder.mutation({
            query: (payload) => ({
                url: `${registerEndpoints.FORGOT_PASSWORD}`,
                method: `PUT`,
                body: payload,
            }),
        }),
    }),
});

export const {
    useVerifyCompanyNameMutation,
    useVerifyICNMutation,
    useRegisterMutation,
    useRequestOTPMutation,
    useVerifyOTPMutation,
    useForgotPasswordMutation,
    useResetPasswordMutation,
} = registerAPI;
