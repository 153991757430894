import React from "react";
import { Box, Button } from "@mui/material";
import Loader from "react-js-loader";
import { colors } from "../../../assets/utils/colors";

const CustomSubmitButton1 = ({
    sx,
    loading,
    startIcon,
    onClick,
    btnColor,
    endIcon,
    variant,
    mt,
    children,
}) => {
    return (
        <>
            <Box sx={{ mt: mt ? 0 : 2 }}>
                <Button
                    variant={variant ? variant : "contained"}
                    onClick={onClick}
                    {...(loading ? "" : "")}
                    disabled={loading ? true : false}
                    startIcon={startIcon}
                    endIcon={endIcon}
                    color={btnColor}
                    className="app-btn"
                    fullWidth
                    sx={{
                        position: "relative",
                        py: 1.5,
                        fontSize: 14,
                        color: colors.bgColor1,
                        fontWeight: "bold",
                        backgroundImage: `linear-gradient(rgb(49, 180, 76, .5), rgb(49, 180, 76, .5)), radial-gradient(circle at left top, rgb(1, 110, 218), rgb(49, 180, 76, .5))`,
                        ...sx,
                    }}
                >
                    {children}
                    {loading && (
                        <Box
                            sx={{
                                position: "absolute",
                                left: 0,
                                right: 0,
                            }}
                        >
                            <Loader
                                type="bubble-loop"
                                bgColor={colors.info}
                                color={colors.info}
                                size={50}
                            />
                        </Box>
                    )}
                </Button>
            </Box>
        </>
    );
};

export default CustomSubmitButton1;
