import React from "react";
import { Box, Typography } from "@mui/material";

const WelcomeHead = ({ profile }) => {
    return (
        <>
            <Box>
                <Typography
                    sx={{
                        fontSize: 15,
                        fontWeight: "bold",
                        opacity: 0.6,
                    }}
                >
                    Welcome back
                </Typography>
                <Typography
                    sx={{
                        fontSize: 20,
                        fontWeight: "bold",
                        opacity: 0.6,
                    }}
                >
                    {profile.companyName}
                </Typography>
            </Box>
        </>
    );
};

export default WelcomeHead;
