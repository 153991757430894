import React, { useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { ArrowRight, Receipt } from "@mui/icons-material";
import CustomButton2 from "../../../components/forms/buttons/CustomButton2";
import InvoicePDF from "../../reports/InvoicePDF";
import ReportDialog from "../../../components/dialogs/ReportDialog";
import { PDFDownloadLink } from "@react-pdf/renderer";
import {
    adminSelector,
    memberProfileSelector,
} from "../../../state/features/selectors";
import { useSelector } from "react-redux";
import { take } from "lodash";

const GetInvoiceRecept = ({ fullWidth, invoice }) => {
    // ############## Comp State ##############
    const [openDialog, setOpenDialog] = useState(false);
    const screenWidth = useMediaQuery("(min-width:600px)");

    // ############# Func ###############
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    // ############# Redux state ##################
    const { bankDetails: bankAccounts } = useSelector(adminSelector);
    const { memberProfile: profile } = useSelector(memberProfileSelector);

    // => Get single bank acc
    let bankAcc = take(bankAccounts, 1);
    bankAcc = bankAcc && bankAcc[0];

    return (
        <>
            <Box>
                {/* ################ Dialogy ################# */}
                <ReportDialog
                    head={"Invoice generator"}
                    customWidth={"md"}
                    openDialog={openDialog}
                    handleDialogClose={handleCloseDialog}
                >
                    <InvoicePDF
                        bankAcc={bankAcc}
                        invoice={invoice}
                        profile={profile}
                    />
                </ReportDialog>
                {!screenWidth ? (
                    <PDFDownloadLink
                        document={
                            <InvoicePDF
                                bankAcc={bankAcc}
                                invoice={invoice}
                                profile={profile}
                            />
                        }
                        fileName="invoice.pdf"
                    >
                        {({ blob, url, loading, error }) => (
                            <CustomButton2
                                loading={loading}
                                size={"large"}
                                btColor="warning"
                                fullWidth={fullWidth && fullWidth}
                                startIcon={<Receipt />}
                                endIcon={<ArrowRight />}
                                title={"Invoice PDF"}
                            />
                        )}
                    </PDFDownloadLink>
                ) : (
                    <CustomButton2
                        size={"large"}
                        btColor="warning"
                        fullWidth={fullWidth && fullWidth}
                        action={handleOpenDialog}
                        startIcon={<Receipt />}
                        endIcon={<ArrowRight />}
                        title={"Invoice PDF"}
                    />
                )}
            </Box>
        </>
    );
};

export default GetInvoiceRecept;
