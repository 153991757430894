import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
    Box,
    Grid,
    IconButton,
    LinearProgress,
    Typography,
} from "@mui/material";
import {
    ArrowRightAltOutlined,
    VerifiedUserOutlined,
} from "@mui/icons-material";
import OTPInput, { ResendOTP } from "otp-input-react";
import { useDispatch, useSelector } from "react-redux";
import useRTK from "../../../hooks/useRTK";
import { getRTKErrorMessage } from "../../../helpers/RTKHelpers";
import CustomSubmitButton from "../../../components/forms/CustomSubmitButton";
import { colors } from "../../../assets/utils/colors";
import {
    phoneVerified,
    resetPinId,
    setPinId,
} from "../../../state/features/register/registerSlice";
import { registerSelector } from "../../../state/features/selectors";
import {
    useRequestOTPMutation,
    useVerifyOTPMutation,
} from "../../../api/registerAPI/registerAPI";
import DialogLinerProgress from "../../../components/DialogLinerProgress";

// * -> verify schema
const otpValidationSchema = Yup.object({
    pin: Yup.number().required("Fill all field").min(6),
});

const PhoneVerification = ({ phoneNumber, handleNext, closeDialogy }) => {
    // ############## Comp State ################
    const [OTP, setOTP] = useState("");

    // ################# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############## Redux state ################
    const { pinId } = useSelector(registerSelector);

    // ############### RTK #####################
    // => Request
    const [
        resendOTP,
        {
            isLoading: resendLoading,
            isSuccess: resendSuccess,
            isError: resendIsError,
            error: resendError,
            data: resendData,
        },
    ] = useRequestOTPMutation();
    // => Verify
    const [
        verifyPhoneNumber,
        { isLoading, isSuccess, isError, error, data: updatedData },
    ] = useVerifyOTPMutation();

    // ############### useEffect ################
    // => RTK resend action
    const rtkResendActions = (data) => {
        dispatch(setPinId(data));
    };
    RTK.useRTKResponse(
        resendSuccess,
        resendIsError,
        resendError,
        resendData,
        rtkResendActions
    );

    // => RTK verified action
    const rtkVerifiedActions = (data) => {
        handleNext();
        dispatch(phoneVerified(true));
        dispatch(resetPinId());
    };
    RTK.useRTKResponse(
        isSuccess,
        isError,
        error,
        updatedData,
        rtkVerifiedActions
    );

    return (
        <>
            <DialogLinerProgress isLoading={isLoading || resendLoading} />
            <Formik
                enableReinitialize
                initialValues={{
                    pin: OTP,
                }}
                validationSchema={otpValidationSchema}
                onSubmit={(payload) => {
                    payload = {
                        pinId: pinId,
                        ...payload,
                    };
                    verifyPhoneNumber(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        {resendLoading && (
                            <LinearProgress
                                sx={{
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    left: 0,
                                }}
                            />
                        )}
                        <Grid
                            container
                            justifyContent={"center"}
                            padding={2}
                            py={{ md: 1.5 }}
                        >
                            <Grid item sm={11}>
                                <Typography
                                    sx={{
                                        fontSize: 15,
                                    }}
                                >
                                    An verification code has been sent to the
                                    phone provided :{" "}
                                    <strong style={{ color: colors.secondary }}>
                                        {phoneNumber}
                                    </strong>
                                    <IconButton
                                        size="small"
                                        color="primary"
                                        sx={{ fontSize: 12 }}
                                        onClick={() => dispatch(resetPinId())}
                                    >
                                        change number
                                    </IconButton>
                                </Typography>
                            </Grid>
                            <Grid item sm={11}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        my: 5,
                                        flexDirection: "column",
                                    }}
                                >
                                    <OTPInput
                                        value={OTP}
                                        onChange={setOTP}
                                        autoFocus
                                        OTPLength={6}
                                        otpType="number"
                                        disabled={false}
                                    />
                                    {isError ? (
                                        <>
                                            <Typography
                                                sx={{
                                                    fontSize: 14,
                                                    color: "red",
                                                    textAlign: "center",
                                                    marginTop: 4,
                                                }}
                                            >
                                                {getRTKErrorMessage(error)}
                                            </Typography>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </Box>
                                <ResendOTP
                                    onResendClick={() => {
                                        let phone =
                                            0 + phoneNumber.substring(3);
                                        let payload = {
                                            phone_number: phone,
                                        };
                                        resendOTP(payload);
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                sm={11}
                                xs={12}
                                sx={{
                                    textAlign: "center",
                                }}
                            >
                                <Box sx={{}}>
                                    <CustomSubmitButton
                                        startIcon={<VerifiedUserOutlined />}
                                        endIcon={<ArrowRightAltOutlined />}
                                        btnColor={`secondary`}
                                        variant={"contained"}
                                        loading={isLoading}
                                        onClick={() => {
                                            formik.handleSubmit();
                                        }}
                                        sx={{
                                            py: 1.5,
                                            fontSize: 14,
                                            color: colors.bgColor1,
                                            fontWeight: "bold",
                                            backgroundImage: `linear-gradient(rgb(49, 180, 76, .5), rgb(49, 180, 76, .5)), radial-gradient(circle at left top, rgb(1, 110, 218), rgb(49, 180, 76, .5))`,
                                        }}
                                    >
                                        Verify phone number
                                    </CustomSubmitButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default PhoneVerification;
