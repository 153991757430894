import React, { useState } from "react";
import { Avatar, Box, Grid, IconButton } from "@mui/material";
import {
    AppRegistrationOutlined,
    ArrowRight,
    Close,
} from "@mui/icons-material";
import { colors } from "../../assets/utils/colors";
import CustomDialog from "../../components/dialogs/CustomDialog";
import RegStepper from "../../components/steppers/RegStepper";
import CustomButton2 from "../../components/forms/buttons/CustomButton2";
import logo from "../../assets/media/images/logo.png";

const Register = ({ fullWidth }) => {
    // ############## Comp State ##############
    const [openDialog, setOpenDialog] = useState(false);
    // const screenWidth = useMediaQuery("(min-width:600px)");

    // ############# Func ###############
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <>
            <Box>
                {/* ################ Dialogy ################# */}
                <CustomDialog
                    openDialog={openDialog}
                    customWidth={"md"}
                    // fullScreen={screenWidth}
                    // {...(screenWidth && ...fullScreen)}
                >
                    <Box
                        sx={{
                            position: "absolute",
                            top: { sm: 30, xs: 15 },
                            right: { sm: 30, xs: 15 },
                        }}
                    >
                        <IconButton
                            aria-label="close"
                            onClick={handleCloseDialog}
                            sx={{
                                color: colors.orange,
                            }}
                        >
                            <Close />
                        </IconButton>
                    </Box>
                    <Grid container>
                        <Grid item sm={6} xs={12}>
                            <Box
                                sx={{
                                    height: { sm: 500, xs: 70 },
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    background: {
                                        sm: `linear-gradient(360deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                                        xs: "transparent",
                                    },
                                }}
                            >
                                <Box>
                                    <Avatar
                                        src={logo}
                                        sx={{
                                            height: { sm: 300, xs: 90 },
                                            width: { sm: 300, xs: "100%" },
                                            mt: 3,
                                            img: {
                                                objectFit: "contain",
                                            },
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <RegStepper />
                        </Grid>
                    </Grid>
                </CustomDialog>
                <CustomButton2
                    size={"large"}
                    btColor="secondary"
                    fullWidth={fullWidth && fullWidth}
                    startIcon={<AppRegistrationOutlined />}
                    endIcon={<ArrowRight />}
                    action={handleOpenDialog}
                    title={"Register"}
                />
            </Box>
        </>
    );
};

export default Register;
