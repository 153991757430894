import React from "react";
import { Typography } from "@mui/material";
import { colors } from "../assets/utils/colors";

const CountBadge = ({ total, item }) => {
    return (
        <>
            <Typography
                sx={{
                    p: 1,
                    opacity: 0.8,
                    fontSize: 13,
                    borderRadius: 1,
                    fontWeight: "bold",
                    color: colors.primary,
                    height: "100%",
                    px: 3,
                    py: 1,
                    border: `1px dotted ${colors.primary}`,
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor4})`,
                }}
            >
                {`${total} ${item}`}
            </Typography>
        </>
    );
};

export default CountBadge;
