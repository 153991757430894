import { Box, Typography } from "@mui/material";
import React from "react";

const Infobox2 = ({ title }) => {
    return (
        <>
            <Box
                component={"span"}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    border: `1px dotted silver`,
                    p: 1,
                }}
            >
                <Typography
                    component={"span"}
                    sx={{ fontSize: 12, opacity: 0.8 }}
                >
                    {title}
                </Typography>
                {/* <Remove
                    sx={{
                        mt: -1,
                        opacity: 0.8,
                        textAlign: "center",
                    }}
                /> */}
                <Typography sx={{ fontSize: 13 }}>N/A</Typography>
            </Box>
        </>
    );
};

export default Infobox2;
