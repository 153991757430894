import React from "react";
import { Clear, Done } from "@mui/icons-material";
import { Box, Chip, Grid, Typography } from "@mui/material";
// import CustomDialog from "../../components/Dialogs/CustomDialog";
import { colors } from "../../../assets/utils/colors";
import { filter, isEmpty } from "lodash";

// !############## MAIN FUNC ################
const RequredDetails = ({ profile }) => {
    const profileReqDetails = [
        {
            label: `Company name`,
            status: !isEmpty(profile.companyName) ? true : false,
        },
        {
            label: `Company description`,
            status: !isEmpty(profile.description) ? true : false,
        },
        {
            label: `Year of establishement`,
            status: profile.establishmentYear != null ? true : false,
        },
        {
            label: `Company contacts`,
            status: !isEmpty(profile.contacts) ? true : false,
        },
        {
            label: `Sector or industry type`,
            status: !isEmpty(profile.companySector) ? true : false,
        },
        {
            label: `Company directors`,
            status: !isEmpty(profile.directors) ? true : false,
        },
        {
            label: `Contact person`,
            status: !isEmpty(profile.contactPerson) ? true : false,
        },
    ];
    const profileReqAttachments = [
        {
            label: `Certificate of incoporation`,
            status: !isEmpty(
                filter(profile.certOfIncoporation?.attachmentDocument)
            )
                ? true
                : false,
        },
        {
            label: `Business license`,
            status: !isEmpty(filter(profile.businessLicense)) ? true : false,
        },
        {
            label: `Company profile`,
            status: !isEmpty(filter(profile.companyProfile)) ? true : false,
        },
        {
            label: `Tax identification number`,
            status: !isEmpty(filter(profile.TIN)) ? true : false,
        },
        {
            label: `Company logo (optional)`,
            status: !isEmpty(filter(profile.logo)) ? true : false,
        },
    ];

    return (
        <>
            {/* ##################### CONTENT ################# */}
            <Grid item sm={12} xs={12} sx={{ py: 2 }}>
                <Typography
                    sx={{
                        fontSize: 12,
                        fontWeight: "bold",
                        color: colors.primary,
                        my: 1,
                    }}
                >
                    Required informations
                </Typography>
                <Box sx={{ textAlign: "left" }}>
                    {profileReqDetails?.map((detail, index) => (
                        <Box component={"span"} key={index} sx={{ mr: 2 }}>
                            <Chip
                                label={detail.label}
                                size="small"
                                sx={{
                                    mb: 1,
                                    bgcolor: colors.bgColor3,
                                    fontSize: 10,
                                    border: `1px solid ${colors.bgColor5}`,
                                }}
                                icon={
                                    detail.status ? (
                                        <Done
                                            sx={{
                                                color: "green !important",
                                                fontSize: 18,
                                            }}
                                        />
                                    ) : (
                                        <Clear
                                            sx={{
                                                color: "red !important",
                                                fontSize: 18,
                                            }}
                                        />
                                    )
                                }
                            />
                        </Box>
                    ))}
                </Box>
                <Typography
                    sx={{
                        fontSize: 12,
                        fontWeight: "bold",
                        color: colors.primary,
                        my: 1,
                    }}
                >
                    Required attachments
                </Typography>
                <Box sx={{ textAlign: "left" }}>
                    {profileReqAttachments?.map((detail, index) => (
                        <Box component={"span"} key={index} sx={{ mr: 2 }}>
                            <Chip
                                label={detail.label}
                                size="small"
                                sx={{
                                    mb: 1,
                                    bgcolor: colors.bgColor3,
                                    fontSize: 10,
                                    border: `1px solid ${colors.bgColor5}`,
                                }}
                                icon={
                                    detail.status ? (
                                        <Done
                                            sx={{
                                                color: "green !important",
                                                fontSize: 18,
                                            }}
                                        />
                                    ) : (
                                        <Clear
                                            sx={{
                                                color: "red !important",
                                                fontSize: 18,
                                            }}
                                        />
                                    )
                                }
                            />
                        </Box>
                    ))}
                </Box>
            </Grid>
        </>
    );
};

export default RequredDetails;
