// => Certificate of incoporation
const COI_CERT = "/attachments/incorporation-certificate";

// => TIN Certificate
const TIN_CERT = "/attachments/tin-certificate";

// => Business License
const BL_CERT = "/attachments/business-license";

// => Company Profile
const CP_CERT = "/attachments/company-profile";

// => Business Logo
const LOGO_CERT = "/attachments/business-logo";

export const memberAttachmentEndpoints = {
    COI_CERT,
    TIN_CERT,
    BL_CERT,
    CP_CERT,
    LOGO_CERT,
};
