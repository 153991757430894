import { LocalPolice, PriceCheck } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { colors } from "../../assets/utils/colors";
import useCurrency from "../../hooks/useCurrency";

const MiniMemberShipLevelWidget = ({ level }) => {
    const currency = useCurrency();

    return (
        <>
            <Box
                sx={{
                    width: "100%",
                    borderRadius: 2,
                    p: 1.5,
                    position: "relative",
                    border: `1px solid ${colors.primary}`,
                    background: `linear-gradient(360deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                }}
            >
                <Box
                    sx={{
                        height: 25,
                        width: 25,
                        position: "absolute",
                        top: -12,
                        left: -5,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 5,
                        background: `linear-gradient(360deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                    }}
                >
                    <LocalPolice
                        sx={{
                            fontSize: 25,
                            color:
                                level.levelName === "Platinum"
                                    ? "gray"
                                        ? colors.secondary
                                        : level.levelName === "Silver"
                                    : colors.orange,
                        }}
                    />
                </Box>
                <Grid container spacing={1}>
                    <Grid item sm={12} xs={12}>
                        <Typography
                            sx={{
                                fontSize: 14,
                                fontWeight: "bold",
                                opacity: 0.7,
                            }}
                        >
                            {level.levelName}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Typography
                            sx={{
                                fontSize: 12,
                                fontWeight: "bold",
                                border: `1px solid ${colors.primary}`,
                                px: 2,
                                py: 1,
                                borderRadius: 1,
                                display: "flex",
                                opacity: 0.7,
                            }}
                        >
                            <PriceCheck sx={{ mr: 0.5, fontSize: 14 }} />
                            {currency.formatCurrency("TZS", level.levelFee)}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default MiniMemberShipLevelWidget;
