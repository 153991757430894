import React from "react";
import PageLayout from "../layouts/PageLayout";
import CustomPagination2 from "../../components/paginations/CustomPagination2";
import { Box, Grid } from "@mui/material";
import { paginator } from "../../helpers/paginationHelper";
import NoContent from "../../components/NoContent";
import { filter, isEmpty, size } from "lodash";
import PageInfobox from "../../components/infoboxes/PageInfobox";
import InvoiceWidget from "../../components/widgets/InvoiceWidget";
import { colors } from "../../assets/utils/colors";
import { invoiceSelector } from "../../state/features/selectors";
import { useSelector } from "react-redux";
import InvoiceSidebar from "../../components/sidebars/InvoiceSidebar";
import CountBadge from "../../components/CountBadge";

const InvoicePage = () => {
    // ############## Comp state ##################
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(5);

    // ########### Redux state #################
    const { invoices } = useSelector(invoiceSelector);

    // ############## FUNC ####################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };
    // => Get invoice by status
    const getInvoiceByStatus = () => {
        // => Paid
        const paidInvoice = filter(invoices, { isPaid: 1 });

        // =>  Un-paid
        const unpaidInvoice = filter(invoices, { isPaid: 0 });

        // => Pending approved
        const unapprovedInvoice = filter(invoices, {
            invoiceStatus: "waiting_approval",
        });

        // => Rejected
        const rejectedInvoice = filter(invoices, { invoiceStatus: "rejected" });

        return {
            paidInvoice,
            unpaidInvoice,
            unapprovedInvoice,
            rejectedInvoice,
        };
    };

    // => Pagination data
    const paginatedInvoices = paginator(invoices, page, perPage);

    const infoBoxes = [
        {
            id: 1,
            title1: "Pending",
            title2: "Paid",
            total: size(getInvoiceByStatus().unpaidInvoice),
            url: "status/un-paid",
            color: colors.orange,
        },
        {
            id: 3,
            title1: "Pending",
            title2: "Approved",
            total: size(getInvoiceByStatus().unapprovedInvoice),
            url: "status/pending-approved",
        },
        {
            id: 2,
            title1: "Paid",
            title2: "Invoices",
            total: size(getInvoiceByStatus().paidInvoice),
            url: "status/paid",
            color: colors.secondary,
        },
        {
            id: 4,
            title1: "Rejected",
            title2: "Invoices",
            total: size(getInvoiceByStatus().rejectedInvoice),
            url: "status/rejected",
            color: colors.red,
        },
    ];

    return (
        <>
            <PageLayout
                head={"Invoice management"}
                sidebar={<InvoiceSidebar />}
            >
                <Grid container spacing={2}>
                    {infoBoxes.map((data) => (
                        <Grid item xs key={data.id}>
                            <PageInfobox data={data} />
                        </Grid>
                    ))}
                    <Grid item sm={12}>
                        <Box>
                            <CustomPagination2
                                head={"All generated invoices"}
                                height={"46vh"}
                                handlePageChange={handlePageChange}
                                handlePerPageChage={handlePerPageChage}
                                data={paginatedInvoices}
                                action={
                                    <CountBadge
                                        item={"Invoices"}
                                        total={size(invoices)}
                                    />
                                }
                            >
                                {isEmpty(invoices) ? (
                                    <>
                                        <NoContent
                                            height={250}
                                            message={"No generated invoice"}
                                        />
                                    </>
                                ) : (
                                    <Grid container spacing={3}>
                                        {paginatedInvoices.data.map(
                                            (invoice) => (
                                                <Grid
                                                    item
                                                    sm={12}
                                                    xs={12}
                                                    key={invoice.id}
                                                >
                                                    <InvoiceWidget
                                                        invoice={invoice}
                                                    />
                                                </Grid>
                                            )
                                        )}
                                    </Grid>
                                )}
                            </CustomPagination2>
                        </Box>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default InvoicePage;
