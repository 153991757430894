import React, { useState } from "react";
import {
    Add,
    ArrowRightAltOutlined,
    ContactEmergencyOutlined,
    Edit,
} from "@mui/icons-material";
import { Button, Grid, IconButton, MenuItem, Tooltip } from "@mui/material";
import CustomCrudDialog from "../../../components/dialogs/CustomCrudDialog";
import CustomSubmitButton1 from "../../../components/forms/buttons/CustomSubmitButton1";
import { useDispatch } from "react-redux";
import useRTK from "../../../hooks/useRTK";
import { setMemberProfile } from "../../../state/features/profile/memberProfileSlice";
import { isEmpty } from "lodash";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import CustomTextField from "../../../components/forms/CustomTextField";
import { colors } from "../../../assets/utils/colors";
import CustomSelect from "../../../components/forms/CustomSelect";
import { nationalities } from "../../../assets/utils/ntionalities";
import {
    useAddDirectorMutation,
    useUpdateDirectorMutation,
} from "../../../api/directorsAPI/directorsAPI";

// ################## VALIDATION ####################
const directorValidationSchema = Yup.object().shape({
    firstName: Yup.string().required("First is required field"),
    lastName: Yup.string().required("Last is required field"),
    middleName: Yup.string().nullable(),
    nationality: Yup.string().required(),
    phoneNumber: Yup.string()
        .required("Phone number is required field")
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            "Positions must be a number"
        )
        .max(10, "Phone number must be at most 10 characters")
        .min(10, "Phone number must be at least 10 characters"),
});

const AddDirector = ({ director }) => {
    // ################# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############## Comp State ##############
    const [openDialog, setOpenDialog] = useState(false);

    // ############# Func ###############
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    // ############## RTK ##################
    // => Add
    const [
        addDirector,
        { isLoading, isSuccess, isError, error, data: addedData },
    ] = useAddDirectorMutation();
    // => Update
    const [
        updateDirector,
        {
            isLoading: updateLoading,
            isSuccess: updateIsSuccess,
            isError: updateIsError,
            error: updateError,
            data: updatedData,
        },
    ] = useUpdateDirectorMutation();

    // ############### useEffect ################
    // => RTK action
    const rtkActions = (data) => {
        handleCloseDialog();
        dispatch(setMemberProfile(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, addedData, rtkActions);
    RTK.useRTKResponse(
        updateIsSuccess,
        updateIsError,
        updateError,
        updatedData,
        rtkActions
    );

    return (
        <>
            <CustomCrudDialog
                openDialog={openDialog}
                closeDialog={handleCloseDialog}
                head={
                    isEmpty(director)
                        ? "Add company company director"
                        : "Update company company director"
                }
            >
                <Formik
                    enableReinitialize
                    initialValues={{
                        firstName: !isEmpty(director) ? director.firstName : "",
                        lastName: !isEmpty(director) ? director.lastName : "",
                        middleName: !isEmpty(director)
                            ? director.middleName
                            : "",
                        phoneNumber: !isEmpty(director)
                            ? director.phoneNumber
                            : "",
                        nationality: !isEmpty(director)
                            ? director.nationality
                            : "",
                    }}
                    validationSchema={directorValidationSchema}
                    onSubmit={(payload) => {
                        isEmpty(director)
                            ? addDirector(payload)
                            : updateDirector(payload);
                    }}
                >
                    {(formik) => (
                        <Form>
                            <Grid
                                container
                                justifyContent={"center"}
                                columnSpacing={2}
                            >
                                <Grid item sm={4} xs={12}>
                                    <CustomTextField
                                        type="text"
                                        name="firstName"
                                        label={"First name"}
                                        bBottom={colors.secondary}
                                    />
                                </Grid>
                                <Grid item sm={4} xs={12}>
                                    <CustomTextField
                                        type="text"
                                        name="middleName"
                                        label={"Middle name"}
                                        bBottom={colors.secondary}
                                        optional
                                    />
                                </Grid>
                                <Grid item sm={4} xs={12}>
                                    <CustomTextField
                                        type="text"
                                        name="lastName"
                                        label={"Last name"}
                                        bBottom={colors.secondary}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <CustomTextField
                                        type="text"
                                        name="phoneNumber"
                                        label={"Phone number"}
                                        bBottom={colors.secondary}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={6}>
                                    <CustomSelect
                                        label={"Nationality"}
                                        formik={formik}
                                        bBottom={colors.secondary}
                                        name="nationality"
                                    >
                                        {nationalities.map((nation) => (
                                            <MenuItem
                                                value={nation.nationality}
                                            >
                                                {nation.nationality}
                                            </MenuItem>
                                        ))}
                                    </CustomSelect>
                                </Grid>
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <CustomSubmitButton1
                                    loading={isLoading || updateLoading}
                                    btnColor={"primary"}
                                    startIcon={<ContactEmergencyOutlined />}
                                    endIcon={<ArrowRightAltOutlined />}
                                    onClick={formik.handleSubmit}
                                >
                                    {isEmpty(director)
                                        ? " Add company director"
                                        : " Update company director"}
                                </CustomSubmitButton1>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </CustomCrudDialog>
            {isEmpty(director) ? (
                <Button
                    size="small"
                    startIcon={isEmpty(director) ? <Add /> : <Edit />}
                    variant="outlined"
                    sx={{
                        fontSize: 12,
                        fontWeight: "bold",
                        color: "#fff",
                        backgroundImage: `linear-gradient(rgba(23, 148, 202, .5), rgba(23, 148, 202, .5)), radial-gradient(circle at left top, rgb(1, 110, 218), rgb(49, 180, 76, .5))`,
                    }}
                    onClick={() => {
                        handleOpenDialog();
                    }}
                >
                    Add director
                </Button>
            ) : (
                <Tooltip title="Edit" arrow>
                    <IconButton
                        size="small"
                        onClick={() => {
                            handleOpenDialog();
                        }}
                        sx={{
                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                        }}
                    >
                        <Edit color="warning" sx={{ fontSize: 16 }} />
                    </IconButton>
                </Tooltip>
            )}
        </>
    );
};

export default AddDirector;
