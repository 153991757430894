import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../baseUrl/baseURL";
import { headers } from "../baseUrl/setHeaders";
import { directorEndpoints } from "./directorEndpoints";
import { profileAPI } from "../profileAPI/profileAPI";

export const directorsAPI = createApi({
    reducerPath: "directorsAPI",
    baseQuery: fetchBaseQuery({ baseUrl: baseURL, prepareHeaders: headers }),
    endpoints: (builder) => ({
        addDirector: builder.mutation({
            query: (payload) => ({
                url: `${directorEndpoints.ADD_DIRECTOR}`,
                method: `POST`,
                body: payload,
            }),
            async onQueryStarted(_, { queryFulfilled, dispatch }) {
                try {
                    await queryFulfilled;
                    dispatch(profileAPI.util.invalidateTags(["Profile"]));
                } catch (err) {}
            },
        }),
        updateDirector: builder.mutation({
            query: (payload) => ({
                url: `${directorEndpoints.UPDATE_DIRECTOR}`,
                method: `PUT`,
                body: payload,
            }),
            async onQueryStarted(_, { queryFulfilled, dispatch }) {
                try {
                    await queryFulfilled;
                    dispatch(profileAPI.util.invalidateTags(["Profile"]));
                } catch (err) {}
            },
        }),
    }),
});

export const { useAddDirectorMutation, useUpdateDirectorMutation } =
    directorsAPI;
