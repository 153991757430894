import React from "react";
import { Image, View, Text } from "@react-pdf/renderer";
import logo from "../../assets/media/images/logo.png";
import moment from "moment/moment";
import { colors } from "../../assets/utils/colors";
import ReportLayout from "../layouts/ReportLayout";
import useCurrency from "../../hooks/useCurrency";
import { isEmpty } from "lodash";

// Create Document Component
const InvoicePDF = ({ bankAcc, invoice, profile }) => {
    const currency = useCurrency();

    const paymentMethod = [
        {
            id: 1,
            item: "Name of Bank",
            value: `${bankAcc?.bankName} ,`,
        },
        {
            id: 2,
            item: "Account name",
            value: `${bankAcc?.accountName} ,`,
        },
        {
            id: 3,
            item: "Bank address",
            value: `${bankAcc?.bankAddress} ,`,
        },
        {
            id: 4,
            item: "Swift code",
            value: `${bankAcc?.swiftCode} ,`,
        },
        {
            id: 5,
            item: "Account number",
            value: `${bankAcc?.accountNumber} .`,
        },
    ];

    const controlPayment = [
        {
            id: 1,
            item: "SimBanking Mobile",
            value: `USSD *150*03# ,`,
        },
        {
            id: 2,
            item: "Vodacom (M-Pesa)",
            value: `USSD *150*00# ,`,
        },
        {
            id: 3,
            item: "Airtel (Airtel Money)",
            value: `USSD *150*60# ,`,
        },
        {
            id: 4,
            item: "Tigo (Tigo Pesa)",
            value: `USSD *150*01# ,`,
        },
    ];
    return (
        <ReportLayout>
            <View
                style={{
                    height: "100%",
                    display: "flex",
                    // justifyContent: "space-around",
                }}
            >
                {/* ################# Bg Logo ############### */}
                <View
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "absolute",
                        left: 0,
                        right: 0,
                        bottom: 0,
                        top: 0,
                    }}
                >
                    <Image style={{ opacity: 0.05, width: "70%" }} src={logo} />
                </View>

                {/* ################# Header ############### */}
                <View
                    style={{
                        marginTop: 25,
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between",
                    }}
                >
                    <View>
                        <View>
                            <Text
                                style={{
                                    fontSize: 8,
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                    marginBottom: 5,
                                }}
                            >
                                Invoice To
                            </Text>
                            <Text
                                style={{
                                    fontSize: 14,
                                    fontWeight: "bold",
                                }}
                            >
                                {profile?.companyName}
                            </Text>
                            <Text
                                style={{
                                    fontSize: 10,
                                    marginTop: 10,
                                    fontWeight: "bold",
                                }}
                            >
                                {profile?.memberShipCategory}
                            </Text>
                        </View>
                        <View
                            style={{
                                marginTop: 20,
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: 8,
                                    fontWeight: "bold",
                                    marginBottom: 5,
                                    textTransform: "uppercase",
                                }}
                            >
                                Contact person
                            </Text>
                            <Text
                                style={{
                                    fontSize: 8,
                                    fontWeight: "bold",
                                    marginBottom: 2,
                                }}
                            >
                                Phone :&nbsp;&nbsp;
                                {profile?.contactPerson.phoneNumber}
                            </Text>
                            <Text
                                style={{
                                    fontSize: 8,
                                    fontWeight: "bold",
                                    marginTop: 2,
                                }}
                            >
                                Email &nbsp;&nbsp;:&nbsp;&nbsp;
                                {profile?.contactPerson.email}
                            </Text>
                        </View>
                    </View>
                    <View>
                        <View>
                            <Text
                                style={{
                                    fontSize: 20,
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                    marginBottom: 25,
                                    color: colors.primary,
                                }}
                            >
                                Invoice
                            </Text>
                        </View>
                        <View>
                            <Text
                                style={{
                                    fontSize: 8,
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                    marginBottom: 5,
                                }}
                            >
                                Invoice No:
                            </Text>
                            <Text
                                style={{
                                    fontSize: 10,
                                    fontWeight: "bold",
                                }}
                            >
                                {invoice.invoiceNumber}
                            </Text>
                            <Text
                                style={{
                                    fontSize: 8,
                                    fontWeight: "bold",
                                    marginBottom: 5,
                                    marginTop: 10,
                                    textTransform: "uppercase",
                                }}
                            >
                                Invoice Date
                            </Text>
                            <Text
                                style={{
                                    fontSize: 8,
                                    fontWeight: "bold",
                                    marginBottom: 2,
                                }}
                            >
                                {moment(invoice.created_at).format("ll")}
                            </Text>
                        </View>
                    </View>
                </View>

                {/* ################# Content ############### */}
                <View
                    style={{
                        width: "100%",
                        marginTop: 50,
                    }}
                >
                    <View
                        style={{
                            borderTop: `2px solid ${colors.primary}`,
                            borderBottom: `2px solid ${colors.primary}`,
                            paddingTop: 15,
                            paddingBottom: 15,
                        }}
                    >
                        <View
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: 12,
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                    textAlign: "left",
                                }}
                            >
                                Membership level
                            </Text>
                            <Text
                                style={{
                                    fontSize: 12,
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                    textAlign: "left",
                                }}
                            >
                                Membership duration
                            </Text>
                            <Text
                                style={{
                                    fontSize: 12,
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                    textAlign: "left",
                                }}
                            >
                                Membership fee
                            </Text>
                        </View>
                        <View
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                marginTop: 25,
                                justifyContent: "space-between",
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: 12,
                                    fontWeight: "bold",
                                    textAlign: "left",
                                }}
                            >
                                {invoice?.membership_level.levelName}
                            </Text>
                            <Text
                                style={{
                                    fontSize: 12,
                                    fontWeight: "bold",
                                    textAlign: "left",
                                }}
                            >
                                1(One) year
                            </Text>
                            <Text
                                style={{
                                    fontSize: 12,
                                    fontWeight: "bold",
                                    textAlign: "left",
                                }}
                            >
                                {currency.formatCurrency("TZS", invoice.amount)}
                            </Text>
                        </View>
                    </View>
                    <View
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: 10,
                            marginBottom: 50,
                            justifyContent: "space-between",
                        }}
                    >
                        {/* <View
                            style={{
                                height: 50,
                                width: 100,
                                padding: 10,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: 50,
                                marginTop: 10,
                                opacity: 0.6,
                                transform: `rotate(-25deg)`,
                                backgroundColor: `${
                                    isEmpty(invoice.isPaid) ? "red" : "green"
                                }`,
                            }}
                        >
                            <>
                                <Text
                                    style={{
                                        fontSize: 12,
                                        fontWeight: "bold",
                                        textTransform: "uppercase",
                                    }}
                                >
                                    {`${
                                        isEmpty(invoice.isPaid)
                                            ? "Not paid"
                                            : "Paid"
                                    }`}
                                </Text>
                            </>
                        </View> */}
                        <Text
                            style={{
                                fontSize: 12,
                                fontWeight: "bold",
                                textAlign: "left",
                            }}
                        ></Text>
                        <View
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "flex-end",
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: 18,
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                    marginBottom: 10,
                                    color: colors.primary,
                                }}
                            >
                                Total Due
                            </Text>
                            <Text
                                style={{
                                    fontSize: 15,
                                    fontWeight: "bold",
                                    textAlign: "left",
                                    color: colors.orange,
                                }}
                            >
                                {currency.formatCurrency("TZS", invoice.amount)}
                            </Text>
                            {invoice?.isPaid === 1 && (
                                <Text
                                    style={{
                                        fontSize: 15,
                                        fontWeight: "bold",
                                        textAlign: "left",
                                        color: colors.secondary,
                                        marginTop: 15,
                                    }}
                                >
                                    PAID
                                </Text>
                            )}
                        </View>
                    </View>
                </View>

                {/* ################# Bank Details ############### */}
                {invoice?.isPaid !== 1 && (
                    <View
                        style={{
                            width: "100%",
                            position: "relative",
                        }}
                    >
                        {bankAcc && (
                            <View
                                style={{
                                    marginTop: 20,
                                }}
                            >
                                <Text
                                    style={{
                                        fontSize: 10,
                                        fontWeight: "bold",
                                        marginBottom: 15,
                                        textTransform: "uppercase",
                                    }}
                                >
                                    Direct Bank Payment
                                </Text>
                                <View>
                                    {paymentMethod.map((detail) => (
                                        <View
                                            key={detail.id}
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "",
                                                marginBottom: 5,
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    fontSize: 9,
                                                    fontWeight: "bold",
                                                    marginBottom: 2,
                                                    width: 100,
                                                }}
                                            >
                                                {detail.item}
                                            </Text>
                                            <Text
                                                style={{
                                                    fontSize: 10,
                                                    marginBottom: 2,
                                                }}
                                            >
                                                {detail.value}
                                            </Text>
                                        </View>
                                    ))}
                                </View>
                            </View>
                        )}
                        <View
                            style={{
                                position: "absolute",
                                marginTop: 20,
                                right: 0,
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: 10,
                                    fontWeight: "bold",
                                    marginBottom: 10,
                                    textTransform: "uppercase",
                                    textAlign: "center",
                                }}
                            >
                                Control Number Payment
                            </Text>
                            <View>
                                <Text
                                    style={{
                                        fontSize: 15,
                                        fontWeight: "bold",
                                        marginBottom: 15,
                                        textAlign: "center",
                                        width: 150,
                                        color: colors.secondary,
                                    }}
                                >
                                    {invoice.invoiceNumber}
                                </Text>
                                <Text
                                    style={{
                                        fontSize: 12,
                                        marginBottom: 10,
                                        width: 150,
                                    }}
                                >
                                    Payments Through :
                                </Text>
                                <View>
                                    {controlPayment.map((detail) => (
                                        <View
                                            key={detail.id}
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "",
                                                marginBottom: 5,
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    fontSize: 9,
                                                    fontWeight: "bold",
                                                    marginBottom: 2,
                                                    width: 100,
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                {detail.item}
                                            </Text>
                                            <Text
                                                style={{
                                                    fontSize: 10,
                                                    marginBottom: 2,
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                {detail.value}
                                            </Text>
                                        </View>
                                    ))}
                                </View>
                            </View>
                        </View>
                    </View>
                )}
            </View>
        </ReportLayout>
    );
};
export default InvoicePDF;
