import React from "react";
import SpacingContent from "./SpacingContent";
import Section from "./Section";
import MemberShipLevelWidget from "./widgets/MemberShipLevelWidget";
import { Grid } from "@mui/material";
import { colors } from "../assets/utils/colors";
import { useSelector } from "react-redux";
import { membershipLevelSelector } from "../state/features/selectors";

const MemberShipLevels = () => {
    const { activeMembershipLevels } = useSelector(membershipLevelSelector);

    const benefits = [
        {
            id: 1,
            name: "Participation in Presidential Delegation",
            available: true,
        },
        {
            id: 2,
            name: "Participation in the Policy advocacy issues",
            available: false,
        },
        {
            id: 3,
            name: "Customized Promotion and Advocacy",
            available: true,
        },
        {
            id: 4,
            name: "Annual Associate Dinner at Discounted Rate with high level Government Officials",
            available: true,
        },
        {
            id: 5,
            name: "Logo and link to your site from the Chamber website pages for three months",
            available: true,
        },
        {
            id: 6,
            name: "Advertising opportunity for discounted packages through Chamber promotional materials",
            available: true,
        },
        {
            id: 7,
            name: "Subsidized cost on Business travels related to Chamber Activities",
            available: true,
        },
        {
            id: 8,
            name: "Customized in house Training",
            available: true,
        },
        {
            id: 9,
            name: "Promote your Business in the TCCIA Exporters Directory at special discount",
            available: true,
        },
        {
            id: 10,
            name: "Free listing of your business to TCCIA Online Business Directory and Exporters Directory",
            available: true,
        },
        {
            id: 11,
            name: "Opportunity to Serve on Chamber Committees and Leadership",
            available: true,
        },
        {
            id: 12,
            name: "Business to business Networking",
            available: true,
        },
        {
            id: 12,
            name: "Trade Mission Participation",
            available: true,
        },
        {
            id: 14,
            name: "Sourcing and Recommendation to International Volunteering Organization",
            available: true,
        },
        {
            id: 15,
            name: "Weekly and Monthly Business Information updates",
            available: true,
        },
        {
            id: 16,
            name: "Training for high value business and supply chain",
            available: true,
        },
        {
            id: 17,
            name: "Recommendation to International Organization",
            available: true,
        },
        {
            id: 18,
            name: "Point of reference in case of Trade inquiry",
            available: true,
        },
    ];
    return (
        <>
            <Section
                bgColor={`linear-gradient(360deg, ${colors.tertiary}, ${colors.tertiary})`}
                head={"Become TCCIA Member"}
                subHead={
                    "The price for each plan represents a one-year payment."
                }
            >
                <SpacingContent>
                    <Grid
                        container
                        columnSpacing={{ sm: 5, xs: 0 }}
                        rowSpacing={8}
                        justifyContent={{ sm: "left", xs: "center" }}
                    >
                        {activeMembershipLevels.map((level) => (
                            <Grid item md={4} sm={6} xs={11} key={level.id}>
                                <MemberShipLevelWidget level={level} />
                            </Grid>
                        ))}
                    </Grid>
                </SpacingContent>
            </Section>
        </>
    );
};

export default MemberShipLevels;
