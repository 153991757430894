import React, { useState } from "react";
import Section from "./Section";
import { Box, Grid, Typography } from "@mui/material";
import SpacingContent from "./SpacingContent";
import { Form, Formik } from "formik";
import CustomTextField from "./forms/CustomTextField";
import { colors } from "../assets/utils/colors";
import CustomButton2 from "./forms/buttons/CustomButton2";
import useRTK from "../hooks/useRTK";
import { useDispatch } from "react-redux";
import { useVerifyCertificateMutation } from "../api/certificateAPI/certificateAPI";
import * as Yup from "yup";
import { setCertificate } from "../state/features/certificate/certificateSlice";
import { ArrowRightRounded, VerifiedUserOutlined } from "@mui/icons-material";
import CustomCrudDialog from "./dialogs/CustomCrudDialog";
import CertificateVerify from "./CertificateVerify";

// ################## VALIDATION ####################
const certificateValidationSchema = Yup.object().shape({
    cert_no: Yup.string().required("certificate number is required"),
});

function VerifyCertficate() {
    // ################# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############## Comp State ##############
    const [openDialog, setOpenDialog] = useState(false);

    // ############# Func ###############
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    // ############## RTK ##################
    const [
        verifyCertificate,
        { isLoading, isSuccess, isError, error, data: updatedData },
    ] = useVerifyCertificateMutation();

    // ############### useEffect ################
    // => RTK action
    const rtkActions = (data) => {
        handleOpenDialog();
        dispatch(setCertificate(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, updatedData, rtkActions);

    return (
        <Section>
            <CustomCrudDialog
                openDialog={openDialog}
                closeDialog={handleCloseDialog}
                head={"Certificate validation"}
            >
                <CertificateVerify />
            </CustomCrudDialog>
            <SpacingContent>
                <Box
                    sx={{
                        borderRadius: 2,
                        p: 2,
                        diplay: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <Grid container>
                        <Grid item sm={12} xs={12}>
                            <Box sx={{ width: "100%" }}>
                                <Typography
                                    sx={{
                                        fontSize: { md: 35, xs: 15 },
                                        fontWeight: "bold",
                                        textAlign: "center",
                                        color: colors.primary,
                                        opacity: 0.8,
                                        textTransform: "uppercase",
                                        position: "relative",
                                        background: `linear-gradient(rgba(23, 148, 202, .8), rgb(49, 180, 76, .8))`,
                                        backgroundClip: "text",
                                        textFillColor: "transparent",
                                    }}
                                >
                                    Verify member certificate
                                </Typography>
                                <Typography
                                    sx={{
                                        textAlign: "center",
                                        fontSize: { md: 15, xs: 12 },
                                    }}
                                >
                                    Please provide certificate number to verify
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <Box sx={{ width: "100%" }}>
                                <Formik
                                    initialValues={{ cert_no: "" }}
                                    validationSchema={
                                        certificateValidationSchema
                                    }
                                    onSubmit={(payload, { resetForm }) => {
                                        resetForm();
                                        verifyCertificate(payload);
                                    }}
                                >
                                    {(formik) => (
                                        <Form>
                                            <Grid
                                                container
                                                justifyContent={"center"}
                                            >
                                                <Grid item sm={5} xs={11}>
                                                    <CustomTextField
                                                        name="cert_no"
                                                        label={
                                                            "Certificate number"
                                                        }
                                                    />
                                                    <Box sx={{ mt: 2 }}>
                                                        <CustomButton2
                                                            startIcon={
                                                                <VerifiedUserOutlined />
                                                            }
                                                            endIcon={
                                                                <ArrowRightRounded />
                                                            }
                                                            variant={
                                                                "contained"
                                                            }
                                                            title={
                                                                "Verify certificate"
                                                            }
                                                            loading={isLoading}
                                                            action={() => {
                                                                formik.handleSubmit();
                                                            }}
                                                        />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    )}
                                </Formik>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </SpacingContent>
        </Section>
    );
}

export default VerifyCertficate;
