import {
    Box,
    Chip,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import React from "react";
import MiniMemberShipLevelWidget from "./MiniMemberShipLevelWidget";
import { colors } from "../../assets/utils/colors";
import { RemoveRedEyeOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import moment from "moment";
import { isEmpty } from "lodash";

const InvoiceWidget = ({ invoice }) => {
    return (
        <>
            <Box
                sx={{
                    p: 1,
                    borderRadius: 2,
                    background: `linear-gradient(180deg, ${colors.bgColor4}, ${colors.bgColor3})`,
                    boxShadow: `0 2px 5px ${colors.bgColor2}`,
                    transition: `.3s`,
                    border: `1px solid ${colors.primary}`,
                    ":hover": {
                        transition: `.3s`,
                        boxShadow: `0 2px 5px ${colors.primary}`,
                    },
                }}
            >
                <Grid container spacing={2}>
                    <Grid item sm={3} xs={12}>
                        <Typography
                            sx={{
                                fontSize: 14,
                                fontWeight: "bold",
                                mb: 1,
                                background: `linear-gradient(rgba(23, 148, 202, .8), rgb(49, 180, 76, .8))`,
                                backgroundClip: "text",
                                textFillColor: "transparent",
                            }}
                        >
                            Membership level
                        </Typography>
                        <MiniMemberShipLevelWidget
                            level={invoice?.membership_level}
                        />
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                height: "100%",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: "bold",
                                    mb: 2,
                                    background: `linear-gradient(rgba(23, 148, 202, .8), rgb(49, 180, 76, .8))`,
                                    backgroundClip: "text",
                                    textFillColor: "transparent",
                                }}
                            >
                                Invoice number
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: "bold",
                                    opacity: 0.8,
                                }}
                            >
                                {invoice.invoiceNumber}
                            </Typography>
                            <Box></Box>
                        </Box>
                    </Grid>
                    <Grid item sm={2} xs={6}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                height: "100%",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: "bold",
                                    mb: 2,
                                    background: `linear-gradient(rgba(23, 148, 202, .8), rgb(49, 180, 76, .8))`,
                                    backgroundClip: "text",
                                    textFillColor: "transparent",
                                }}
                            >
                                Created
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: "bold",
                                    opacity: 0.8,
                                }}
                            >
                                {moment(invoice.created_at).format("LL")}
                            </Typography>
                            <Box></Box>
                        </Box>
                    </Grid>
                    <Grid item sm={2} xs={6}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                height: "100%",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: "bold",
                                    mb: 2,
                                    background: `linear-gradient(rgba(23, 148, 202, .8), rgb(49, 180, 76, .8))`,
                                    backgroundClip: "text",
                                    textFillColor: "transparent",
                                }}
                            >
                                Payment
                            </Typography>
                            <Chip
                                label={
                                    invoice.isPaid !== 1 ? "Not-Paid" : "Paid"
                                }
                                color={
                                    invoice.isPaid !== 1 ? "warning" : "success"
                                }
                                sx={{ opacity: 0.8 }}
                            />
                            <Box></Box>
                        </Box>
                    </Grid>
                    <Grid item sm={2} xs={6}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                height: "100%",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: "bold",
                                    mb: 2,
                                    background: `linear-gradient(rgba(23, 148, 202, .8), rgb(49, 180, 76, .8))`,
                                    backgroundClip: "text",
                                    textFillColor: "transparent",
                                }}
                            >
                                Action
                            </Typography>
                            <Tooltip title="Invoice details" arrow>
                                <Link to={`/invoices/${invoice.id}`}>
                                    <IconButton
                                        color="info"
                                        sx={{
                                            background: `linear-gradient(rgba(23, 148, 202, .3), rgb(49, 180, 76, .3))`,
                                        }}
                                    >
                                        <RemoveRedEyeOutlined />
                                    </IconButton>
                                </Link>
                            </Tooltip>
                            <Box></Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default InvoiceWidget;
