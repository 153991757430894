import {
    Box,
    Chip,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import React from "react";
import MiniMemberShipLevelWidget from "./MiniMemberShipLevelWidget";
import { colors } from "../../assets/utils/colors";
import { SecurityOutlined } from "@mui/icons-material";
import moment from "moment";
import { isEmpty } from "lodash";
import GetCertificate from "../../pages/subscriptions/CRUD/GetCertificate";
import CertificateQrcode from "../CertificateQrcode";

const SubscriptionWidget = ({ license }) => {
    return (
        <>
            <Box
                sx={{
                    p: 1,
                    borderRadius: 2,
                    background: `linear-gradient(180deg, ${colors.bgColor4}, ${colors.bgColor3})`,
                    boxShadow: `0 2px 5px ${colors.bgColor2}`,
                    transition: `.3s`,
                    border: `1px solid ${colors.primary}`,
                    ":hover": {
                        transition: `.3s`,
                        boxShadow: `0 2px 5px ${colors.primary}`,
                    },
                }}
            >
                <CertificateQrcode license={license} />
                <Grid container spacing={2}>
                    <Grid item sm={4} xs={12}>
                        <Typography
                            sx={{
                                fontSize: 14,
                                fontWeight: "bold",
                                mb: 1,
                                background: `linear-gradient(rgba(23, 148, 202, .8), rgb(49, 180, 76, .8))`,
                                backgroundClip: "text",
                                textFillColor: "transparent",
                            }}
                        >
                            Membership level
                        </Typography>
                        <MiniMemberShipLevelWidget
                            level={license?.membershipLevel}
                        />
                    </Grid>
                    <Grid item sm={2} xs={6}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                height: "100%",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: "bold",
                                    mb: 2,
                                    background: `linear-gradient(rgba(23, 148, 202, .8), rgb(49, 180, 76, .8))`,
                                    backgroundClip: "text",
                                    textFillColor: "transparent",
                                }}
                            >
                                Subscided
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: "bold",
                                    opacity: 0.8,
                                    color: colors.secondary,
                                }}
                            >
                                {moment(license.startDate).format("LL")}
                            </Typography>
                            <Box></Box>
                        </Box>
                    </Grid>
                    <Grid item sm={2} xs={6}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                height: "100%",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: "bold",
                                    mb: 2,
                                    background: `linear-gradient(rgba(23, 148, 202, .8), rgb(49, 180, 76, .8))`,
                                    backgroundClip: "text",
                                    textFillColor: "transparent",
                                }}
                            >
                                Expiration
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: "bold",
                                    opacity: 0.8,
                                    color: "red",
                                }}
                            >
                                {moment(license.endDate).format("LL")}
                            </Typography>
                            <Box></Box>
                        </Box>
                    </Grid>
                    <Grid item sm={2} xs={6}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                height: "100%",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: "bold",
                                    mb: 2,
                                    background: `linear-gradient(rgba(23, 148, 202, .8), rgb(49, 180, 76, .8))`,
                                    backgroundClip: "text",
                                    textFillColor: "transparent",
                                }}
                            >
                                Status
                            </Typography>
                            <Chip
                                label={license.licenseStatus}
                                color={
                                    license.licenseStatus !== "active"
                                        ? "error"
                                        : "success"
                                }
                                sx={{
                                    opacity: 0.8,
                                    textTransform: "capitalize",
                                }}
                            />
                            <Box></Box>
                        </Box>
                    </Grid>
                    <Grid item sm={2} xs={6}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                height: "100%",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: "bold",
                                    mb: 2,
                                    background: `linear-gradient(rgba(23, 148, 202, .8), rgb(49, 180, 76, .8))`,
                                    backgroundClip: "text",
                                    textFillColor: "transparent",
                                }}
                            >
                                Action
                            </Typography>
                            <GetCertificate license={license} />
                            <Box></Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default SubscriptionWidget;
