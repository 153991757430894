import React, { useEffect, useState } from "react";
import PageLayout from "../layouts/PageLayout";
import { Box, Chip, Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import PayInvoice from "./CRUD/PayInvoice";
import { useGetSingleInvoiceQuery } from "../../api/invoiceAPI/invoiceAPI";
import { setInvoiceDetails } from "../../state/features/invoice/invoiceSlice";
import useRTK from "../../hooks/useRTK";
import MemberShipLevelInvoiceWidget from "../../components/widgets/MemberShipLevelInvoiceWidget";
import { isEmpty } from "lodash";
import { colors } from "../../assets/utils/colors";
import GetInvoiceRecept from "./CRUD/GetInvoiceRecept";
import ChangeMemberShipLevel from "./CRUD/ChangeMemberShipLevel";
import CustomCardTwo from "../../components/cards/CustomCardTwo";
import InnerContentLoader from "../../components/InnerContentLoader";
import { useDispatch, useSelector } from "react-redux";
import { invoiceSelector } from "../../state/features/selectors";
import moment from "moment";
import PreviewAttachment from "../profile/components/PreviewAttachment";
import GetCertificateTwo from "../subscriptions/CRUD/GetCertificateTwo";
import CertificateQrcode from "../../components/CertificateQrcode";
import ControllNumber from "./CRUD/ControllNumber";

const InvoiceDetailsPage = () => {
    // ############### CONST ######################
    const RTK = useRTK();
    const dispatch = useDispatch();
    const { invoice_id } = useParams();

    // ############## Comp state ##################
    const [invoice, setInvoice] = useState(null);

    // ############### Redux state ###############
    const { invoiceDetails } = useSelector(invoiceSelector);
    const { license: memberLicense, membership_level: level } = invoiceDetails;
    const license = {
        ...memberLicense,
        membershipLevel: level,
    };

    // ############## RTK ####################
    const {
        isLoading,
        isSuccess,
        data: invoiceData,
    } = useGetSingleInvoiceQuery(invoice_id);
    // => Actiion
    const rtkAction = (data) => {
        dispatch(setInvoiceDetails(data));
    };
    // => UseEffect
    RTK.useRTKFetch(isSuccess, invoiceData, rtkAction);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            setInvoice(invoiceDetails);
        }
        return () => {
            isSubscribed = false;
        };
    }, [invoiceDetails]);

    return (
        <>
            <PageLayout head={`Invoice details`} noSidebar>
                {isLoading ? (
                    <>
                        <InnerContentLoader height="60vh" />
                    </>
                ) : !isEmpty(invoice) ? (
                    <>
                        <CertificateQrcode license={license} />
                        <Grid container spacing={4}>
                            <Grid item sm={6}>
                                <Box sx={{ height: "100%" }}>
                                    <CustomCardTwo title={"Invoice membership"}>
                                        <MemberShipLevelInvoiceWidget
                                            level={invoice.membership_level}
                                        />
                                    </CustomCardTwo>
                                </Box>
                            </Grid>
                            <Grid item sm={6}>
                                <Grid container spacing={2}>
                                    <Grid item sm={12}>
                                        <Box
                                            sx={{
                                                borderRadius: 5,
                                                background: `linear-gradient(180deg, ${colors.bgColor4}, ${colors.bgColor4})`,
                                                p: 1,
                                                pb: 2,
                                            }}
                                        >
                                            <Grid container spacing={2}>
                                                <Grid item sm={12} xs={12}>
                                                    <Typography
                                                        sx={{
                                                            textAlign: "center",
                                                            fontSize: 20,
                                                            pt: 1,
                                                            background: `linear-gradient(rgba(23, 148, 202, .8), rgb(49, 180, 76, .8))`,
                                                            backgroundClip:
                                                                "text",
                                                            textFillColor:
                                                                "transparent",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        {invoice.invoiceNumber}
                                                    </Typography>
                                                </Grid>
                                                <Grid item sm={6} xs={6}>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            flexDirection:
                                                                "column",
                                                            height: "100%",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "space-between",
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontSize: 14,
                                                                fontWeight:
                                                                    "bold",
                                                                mb: 1,
                                                                background: `linear-gradient(rgba(23, 148, 202, .8), rgb(49, 180, 76, .8))`,
                                                                backgroundClip:
                                                                    "text",
                                                                textFillColor:
                                                                    "transparent",
                                                            }}
                                                        >
                                                            Payment status
                                                        </Typography>
                                                        <Chip
                                                            size="small"
                                                            label={
                                                                invoice.isPaid !==
                                                                1
                                                                    ? "Not-Paid"
                                                                    : "Paid"
                                                            }
                                                            color={
                                                                invoice.isPaid !==
                                                                1
                                                                    ? "warning"
                                                                    : "success"
                                                            }
                                                            sx={{
                                                                opacity: 0.8,
                                                                fontSize: 10,
                                                                px: 2,
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        />
                                                        <Box></Box>
                                                    </Box>
                                                </Grid>
                                                <Grid item sm={6} xs={6}>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            flexDirection:
                                                                "column",
                                                            height: "100%",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "space-between",
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontSize: 14,
                                                                fontWeight:
                                                                    "bold",
                                                                mb: 1,
                                                                background: `linear-gradient(rgba(23, 148, 202, .8), rgb(49, 180, 76, .8))`,
                                                                backgroundClip:
                                                                    "text",
                                                                textFillColor:
                                                                    "transparent",
                                                            }}
                                                        >
                                                            Approval status
                                                        </Typography>
                                                        <Chip
                                                            size="small"
                                                            label={
                                                                invoice.invoiceStatus
                                                            }
                                                            color={
                                                                invoice.invoiceStatus ===
                                                                "await-approval"
                                                                    ? "info"
                                                                    : invoice.invoiceStatus ===
                                                                      "rejected"
                                                                    ? "error"
                                                                    : invoice.invoiceStatus ===
                                                                      "approved"
                                                                    ? "success"
                                                                    : "warning"
                                                            }
                                                            sx={{
                                                                opacity: 0.8,
                                                                fontSize: 10,
                                                                px: 2,
                                                                fontWeight:
                                                                    "bold",
                                                                textTransform:
                                                                    "capitalize",
                                                            }}
                                                        />
                                                        <Box></Box>
                                                    </Box>
                                                </Grid>
                                                <Grid item sm={6} xs={6}>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            flexDirection:
                                                                "column",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "space-between",
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontSize: 14,
                                                                fontWeight:
                                                                    "bold",
                                                                background: `linear-gradient(rgba(23, 148, 202, .8), rgb(49, 180, 76, .8))`,
                                                                backgroundClip:
                                                                    "text",
                                                                textFillColor:
                                                                    "transparent",
                                                            }}
                                                        >
                                                            Created
                                                        </Typography>
                                                        <Box>
                                                            <Typography
                                                                sx={{
                                                                    opacity: 0.8,
                                                                    fontSize: 12,
                                                                    fontWeight:
                                                                        "bold",
                                                                }}
                                                            >
                                                                {moment(
                                                                    invoice.created_at
                                                                ).format("LL")}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                                <Grid item sm={6} xs={6}>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            flexDirection:
                                                                "column",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "space-between",
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontSize: 14,
                                                                fontWeight:
                                                                    "bold",
                                                                background: `linear-gradient(rgba(23, 148, 202, .8), rgb(49, 180, 76, .8))`,
                                                                backgroundClip:
                                                                    "text",
                                                                textFillColor:
                                                                    "transparent",
                                                            }}
                                                        >
                                                            Payment recept
                                                        </Typography>
                                                        <Box>
                                                            {invoice?.payment
                                                                ?.paymentSlip ? (
                                                                <PreviewAttachment
                                                                    attachment={
                                                                        invoice
                                                                            ?.payment
                                                                            ?.paymentSlip
                                                                    }
                                                                />
                                                            ) : (
                                                                <Typography
                                                                    sx={{
                                                                        opacity: 0.8,
                                                                        fontSize: 12,
                                                                        fontWeight:
                                                                            "bold",
                                                                    }}
                                                                >
                                                                    Not uploaded
                                                                </Typography>
                                                            )}
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    <Grid item sm={12}>
                                        <Box
                                            sx={{
                                                height: 200,
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "space-around",
                                            }}
                                        >
                                            <Grid container spacing={2}>
                                                <Grid item sm={6} xs={6}>
                                                    <Box>
                                                        <GetInvoiceRecept
                                                            invoice={invoice}
                                                        />
                                                    </Box>
                                                </Grid>
                                                {invoice.invoiceStatus ===
                                                    "approved" && (
                                                    <Grid item sm={6} xs={6}>
                                                        <Box>
                                                            <GetCertificateTwo
                                                                license={
                                                                    license
                                                                }
                                                            />
                                                        </Box>
                                                    </Grid>
                                                )}
                                                {!invoice?.payment
                                                    ?.paymentSlip && (
                                                    <Grid item sm={6} xs={6}>
                                                        <Box>
                                                            <ChangeMemberShipLevel />
                                                        </Box>
                                                    </Grid>
                                                )}
                                                {invoice.isPaid !== 1 && (
                                                    <Grid item sm={6} xs={6}>
                                                        <Box>
                                                            <PayInvoice
                                                                invoice={
                                                                    invoice
                                                                }
                                                            />
                                                        </Box>
                                                    </Grid>
                                                )}
                                                {invoice.isPaid !== 1 && (
                                                    <Grid item sm={6} xs={6}>
                                                        <Box>
                                                            <ControllNumber
                                                                invoice={
                                                                    invoice
                                                                }
                                                            />
                                                        </Box>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    ""
                )}
            </PageLayout>
        </>
    );
};

export default InvoiceDetailsPage;
