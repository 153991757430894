import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { OpenInFull } from "@mui/icons-material";
import { isEmpty } from "lodash";
import NoContent from "../NoContent";
import { colors } from "../../assets/utils/colors";
import AddDirector from "../../pages/profile/CRUD/AddDirector";

const columns = [
    { id: "s_n", label: "S/N", align: "center" },
    { id: "name", label: "Name" },
    {
        id: "action",
        label: "Action",
        align: "center",
    },
];

function createData(s_n, name, action) {
    return {
        s_n,
        name,

        action,
    };
}

const DirectorsTable = ({ directors, expandToggle }) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const rows = directors?.map((director, index) => {
        return createData(
            ++index,
            <Box>
                <Typography
                    noWrap
                    sx={{
                        fontSize: 14,
                        fontWeight: "bold",
                        opacity: 0.8,
                        alignItems: "center",
                        color: colors.info,
                    }}
                >
                    {`${director?.firstName}  ${director?.middleName || ""}  ${director?.lastName}`}
                </Typography>
                <Typography
                    noWrap
                    sx={{
                        fontSize: 12,
                        opacity: 0.8,
                        alignItems: "center",
                        color: colors.info,
                    }}
                >
                    {director.phoneNumber}
                </Typography>
                <Typography
                    noWrap
                    sx={{
                        fontSize: 12,
                        opacity: 0.8,
                        alignItems: "center",
                        color: colors.info,
                    }}
                >
                    {director.nationality}
                </Typography>
            </Box>,
            <Box>
                {/* <a href={`tel:${director.phoneNumber}`}>
                    <Tooltip title={director.phoneNumber} arrow>
                        <IconButton
                            size="small"
                            sx={{
                                mr: 1,
                                background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                            }}
                        >
                            <Call color="warning" sx={{ fontSize: 14 }} />
                        </IconButton>
                    </Tooltip>
                </a> */}
                <AddDirector director={director} />
            </Box>
        );
    });

    return (
        <Paper
            sx={{
                width: "100%",
                overflow: "hidden",
                boxShadow: "none !important",
            }}
        >
            <Box
                sx={{
                    position: "absolute",
                    bottom: 10,
                    left: 15,
                    zIndex: 999,
                }}
            >
                {expandToggle ? (
                    <IconButton
                        size="small"
                        color="info"
                        sx={{
                            background: `linear-gradient(90deg, ${colors.bgColor4}, ${colors.bgColor4})`,
                        }}
                        onClick={expandToggle}
                    >
                        <OpenInFull sx={{ fontSize: 20 }} />
                    </IconButton>
                ) : (
                    ""
                )}
            </Box>
            <TableContainer
                sx={{
                    height: expandToggle ? "100%" : "100%",
                    position: "relative",
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                }}
            >
                {isEmpty(directors) ? (
                    <>
                        <NoContent
                            height={expandToggle ? "30vh" : "30vh"}
                            message={"No director added"}
                        />
                    </>
                ) : (
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        sx={{
                                            textTransform: "uppercase",
                                            fontWeight: "bold",
                                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                                            fontSize: 12,
                                            color: colors.primary,
                                            whiteSpace: "nowrap",
                                            opacity: 0.8,
                                        }}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((row) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.s_n}
                                            sx={{
                                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                                            }}
                                        >
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                    >
                                                        {column.format &&
                                                        typeof value ===
                                                            "number"
                                                            ? column.format(
                                                                  value
                                                              )
                                                            : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                )}
            </TableContainer>
            {/* {!isEmpty(directors) && (
                <TablePagination
                    rowsPerPageOptions={[1, 2, 5, 10]}
                    component="div"
                    count={rows?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{
                        ".MuiTablePagination-selectLabel, .MuiInputBase-root": {
                            display: "none",
                        },
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                    }}
                />
            )} */}
        </Paper>
    );
};

export default DirectorsTable;
