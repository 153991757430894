import { Box, Grid, styled } from "@mui/material";
import React from "react";
import { colors } from "../../assets/utils/colors";

const DrawerHeader = styled(Box)(({ theme }) => ({
    ...theme.mixins.toolbar,
}));

const DashboardLayout = ({ sidebar, noSidebar, children }) => {
    return (
        <>
            <Grid
                container
                spacing={2}
                justifyContent={"center"}
                padding={{ md: 0, sm: 0, xs: 1 }}
            >
                <Grid item sm={sidebar ? 8.5 : 11.5} xs={11.5}>
                    <DrawerHeader />
                    <Box
                        sx={{
                            pt: 2,
                            pl: {
                                md: sidebar ? 2 : 0,
                                sm: sidebar ? 2 : 0,
                                xs: 0,
                            },
                        }}
                    >
                        <Box>{children}</Box>
                    </Box>
                </Grid>
                {sidebar && (
                    <Grid item sm={3.5} xs={11.5}>
                        <Box
                            sx={{
                                height: {
                                    md: "100vh",
                                    sm: "100vh",
                                    xs: "100%",
                                },
                                position: "relative",
                            }}
                        >
                            <Box
                                sx={{
                                    position: {
                                        md: "absolute",
                                        sm: "absolute",
                                        xs: "relative",
                                    },
                                    top: { md: 77, sm: 77, xs: 5 },
                                    bottom: 10,
                                    right: 10,
                                    left: 0,
                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor4}) !important`,
                                    borderRadius: 4,
                                    boxShadow: `0 2px 10px ${colors.bgColor2}`,
                                }}
                            >
                                {sidebar}
                            </Box>
                        </Box>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default DashboardLayout;
