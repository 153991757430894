import React, { useState } from "react";
import { Box, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import { ArrowRight, PublishedWithChangesOutlined } from "@mui/icons-material";
import CustomDialog from "../../../components/dialogs/CustomDialog";
import CustomButton2 from "../../../components/forms/buttons/CustomButton2";
import { membershipLevelSelector } from "../../../state/features/selectors";
import MiniMemberShipLevelWidget from "../../../components/widgets/MiniMemberShipLevelWidget";
import { useDispatch, useSelector } from "react-redux";
import { colors } from "../../../assets/utils/colors";
import CustomSubmitButton1 from "../../../components/forms/buttons/CustomSubmitButton1";
import { isEmpty } from "lodash";
import { errorNotify } from "../../../helpers/notify";
import useRTK from "../../../hooks/useRTK";
import { useUpdateMembershipLevelMutation } from "../../../api/membershipLevelAPI/membershipLevelAPI";
import { setMemberProfile } from "../../../state/features/profile/memberProfileSlice";
import { Form, Formik } from "formik";
import { setInvoiceDetails } from "../../../state/features/invoice/invoiceSlice";

const ChangeMemberShipLevel = ({ fullWidth, level }) => {
    // ################# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############## Comp State ##############
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedValue, setSelectedValue] = useState("");

    // ########### Redux state #################
    const { membershipLevels } = useSelector(membershipLevelSelector);

    // ############# Func ###############
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handleChange = (e) => {
        setSelectedValue(e.target.value);
    };

    // ############### RTK #####################
    const [
        updateMemberShipLevel,
        { isLoading, isSuccess, isError, error, data: updatedData },
    ] = useUpdateMembershipLevelMutation();

    // ############### useEffect ################
    // => RTK action
    const rtkActions = (data) => {
        handleCloseDialog();
        dispatch(setInvoiceDetails(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, updatedData, rtkActions);

    return (
        <>
            <Box>
                {/* ################ Dialogy ################# */}
                <CustomDialog
                    openDialog={openDialog}
                    closeDialog={handleCloseDialog}
                    head={"Change member-ship level"}
                    customWidth={"md"}
                >
                    <Box>
                        <Grid container spacing={2} padding={2}>
                            {membershipLevels?.map((level) => (
                                <Grid item sm={4} key={level.id}>
                                    <RadioGroup
                                        value={selectedValue}
                                        onChange={handleChange}
                                    >
                                        <Box
                                            sx={{
                                                background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                                                border: `3px solid ${colors.bgColor3}`,
                                                borderRadius: 2,
                                            }}
                                        >
                                            <MiniMemberShipLevelWidget
                                                level={level}
                                            />
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <FormControlLabel
                                                    value={level.id}
                                                    control={
                                                        <Radio
                                                            size="medium"
                                                            color="primary"
                                                        />
                                                    }
                                                    sx={{ ml: 2 }}
                                                />
                                            </Box>
                                        </Box>
                                    </RadioGroup>
                                </Grid>
                            ))}
                            <Grid item sm={12}>
                                <Formik
                                    initialValues={{
                                        memberLevel: selectedValue,
                                    }}
                                    enableReinitialize
                                    onSubmit={(payload) => {
                                        updateMemberShipLevel(payload);
                                    }}
                                >
                                    {(formik) => (
                                        <Form>
                                            <CustomSubmitButton1
                                                loading={isLoading}
                                                startIcon={<PublishedWithChangesOutlined />}
                                                onClick={() => {
                                                    if (
                                                        isEmpty(selectedValue)
                                                    ) {
                                                        errorNotify(
                                                            "Please select membership level."
                                                        );
                                                    } else {
                                                        formik.handleSubmit();
                                                    }
                                                }}
                                            >
                                                Change member-ship level
                                            </CustomSubmitButton1>
                                        </Form>
                                    )}
                                </Formik>
                            </Grid>
                        </Grid>
                    </Box>
                </CustomDialog>
                <CustomButton2
                    size={"large"}
                    btColor="info"
                    fullWidth={fullWidth && fullWidth}
                    startIcon={<PublishedWithChangesOutlined />}
                    endIcon={<ArrowRight />}
                    action={handleOpenDialog}
                    title={"Change membership"}
                />
            </Box>
        </>
    );
};

export default ChangeMemberShipLevel;
