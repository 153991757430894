import React, { useState } from "react";
import {
    Avatar,
    Box,
    Grid,
    IconButton,
    Typography,
} from "@mui/material";
import { ArrowRight, Close, Person2Outlined } from "@mui/icons-material";
import { colors } from "../../assets/utils/colors";
import CustomDialog from "../../components/dialogs/CustomDialog";
import logo from "../../assets/media/images/logo.png";
import * as Yup from "yup";
import { Formik } from "formik";
import CustomTextField from "../../components/forms/CustomTextField";
import CustomButton1 from "../../components/forms/buttons/CustomButton1";
import CustomSubmitButton1 from "../../components/forms/buttons/CustomSubmitButton1";
import { useLoginMutation } from "../../api/authAPI/authAPI";
import useRTK from "../../hooks/useRTK";
import useAuth from "../../hooks/useAuth";
import DialogLinerProgress from "../../components/DialogLinerProgress";

// ################### Validation ###############
const loginValidationSchema = Yup.object({
    phoneNumber: Yup.string().required("Phone number is a required field"),
    password: Yup.string().required("Password is a required field"),
});

const Login = () => {
    // ############## CONST ##########
    const RTK = useRTK();
    const auth = useAuth();

    // ############## Comp State ##############
    const [openDialog, setOpenDialog] = useState(false);

    // ############# Func ###############
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    // ############# RTK ##############
    const [login, { isLoading, isSuccess, isError, error, data: memberData }] =
        useLoginMutation();

    // ############# useEffect ##################
    const rtkActions = (data) => {
        auth.login(data);
    };
    RTK.useRTKResponse(isSuccess, isError, error, memberData, rtkActions);

    return (
        <>
            <Box>
                {/* ################ Dialogy ################# */}
                <CustomDialog
                    openDialog={openDialog}
                    customWidth={"md"}
                    // fullScreen
                >
                    <Grid container>
                        <Grid item sm={6} xs={12}>
                            <Box
                                sx={{
                                    height: { sm: 500, xs: "100%" },
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    background: `linear-gradient(360deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                                }}
                            >
                                <Box sx={{}}>
                                    <Avatar
                                        src={logo}
                                        sx={{
                                            height: { sm: 300, xs: 90 },
                                            width: { sm: 300, xs: 90 },
                                            mt: 3,
                                            img: {
                                                objectFit: "contain",
                                            },
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <Box
                                sx={{
                                    height: { sm: 500, xs: "100%" },
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    background: {
                                        sm: "none",
                                        xs: `linear-gradient(360deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                                    },
                                }}
                            >
                                <DialogLinerProgress isLoading={isLoading} />
                                <Grid
                                    container
                                    justifyContent={"center"}
                                    padding={{ sm: 4, xs: 1 }}
                                    spacing={2}
                                >
                                    <Grid item sm={10}>
                                        <Typography
                                            sx={{
                                                fontSize: { sm: 35, xs: 25 },
                                                fontWeight: "bold",
                                                textAlign: "center",
                                                background: `linear-gradient(rgba(23, 148, 202, .8), rgb(49, 180, 76, .8))`,
                                                backgroundClip: "text",
                                                textFillColor: "transparent",
                                            }}
                                        >
                                            Welcome back
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                textAlign: "center",
                                            }}
                                        >
                                            Login to your member dashboard
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={12} xs={12}>
                                        <Box
                                            sx={{
                                                position: "absolute",
                                                top: 30,
                                                right: 30,
                                            }}
                                        >
                                            <IconButton
                                                aria-label="close"
                                                onClick={handleCloseDialog}
                                                sx={{
                                                    color: colors.orange,
                                                }}
                                            >
                                                <Close />
                                            </IconButton>
                                        </Box>
                                        <Formik
                                            initialValues={{
                                                phoneNumber: "",
                                                password: "",
                                            }}
                                            validationSchema={
                                                loginValidationSchema
                                            }
                                            onSubmit={(payload) => {
                                                login(payload);
                                            }}
                                        >
                                            {(formik) => (
                                                <Box className="input-card">
                                                    <Box sx={{ mb: 2 }}>
                                                        <Box>
                                                            <CustomTextField
                                                                type="text"
                                                                name="phoneNumber"
                                                                label={
                                                                    "Type your phone number"
                                                                }
                                                                bBottom={
                                                                    colors.secondary
                                                                }
                                                            />
                                                            <CustomTextField
                                                                type="password"
                                                                name="password"
                                                                label={
                                                                    "Type your password"
                                                                }
                                                                bBottom={
                                                                    colors.secondary
                                                                }
                                                            />
                                                        </Box>
                                                    </Box>
                                                    <Box sx={{ mt: 4 }}>
                                                        <CustomSubmitButton1
                                                            loading={isLoading}
                                                            startIcon={
                                                                <Person2Outlined />
                                                            }
                                                            endIcon={
                                                                <ArrowRight />
                                                            }
                                                            onClick={
                                                                formik.handleSubmit
                                                            }
                                                        >
                                                            Sign in to member
                                                            area
                                                        </CustomSubmitButton1>
                                                    </Box>
                                                </Box>
                                            )}
                                        </Formik>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </CustomDialog>
                <CustomButton1
                    startIcon={<Person2Outlined />}
                    endIcon={<ArrowRight />}
                    title={"Login"}
                    action={handleOpenDialog}
                />
            </Box>
        </>
    );
};

export default Login;
