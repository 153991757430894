import { Box, Typography } from "@mui/material";
import React from "react";
import { colors } from "../../assets/utils/colors";

const Infobox1 = ({ data }) => {
    return (
        <>
            <Box
                sx={{
                    border: `1px solid ${colors.bgColor4}`,
                    p: 1,
                    height: "100%",
                }}
            >
                <Typography
                    sx={{
                        fontSize: 12,
                        fontWeight: "bold",
                        color: colors.primary,
                    }}
                >
                    {data.title}
                </Typography>
                <Typography
                    sx={{
                        fontSize: 13,
                        opacity: 0.8,
                        whiteSpace: "collapse !important",
                        overflow: "auto",
                    }}
                >
                    {`${data.value ? data.value : "N/A"}`}
                </Typography>
            </Box>
        </>
    );
};

export default Infobox1;
