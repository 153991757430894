import React from "react";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { colors } from "../assets/utils/colors";
import { ArrowBackIosNewTwoTone } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const PageHeader = ({ head, action }) => {
    const navigate = useNavigate();

    return (
        <>
            <Box
                sx={{
                    position: "sticky",
                    top: { md: 64, sm: 64, xs: 60 },
                    zIndex: 999,
                    background: "#fff",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    boxShadow: `2px 0px 8px 0px ${colors.bgColor4}`,
                    p: 2,
                    height: 64,
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            ml: 1,
                            fontWeight: "bold",
                            opacity: 0.8,
                            fontSize: 15,
                            textTransform: "uppercase",
                            background: `linear-gradient(rgba(23, 148, 202, .8), rgb(49, 180, 76, .8))`,
                            backgroundClip: "text",
                            textFillColor: "transparent",
                        }}
                    >
                        {head}
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box>{action}</Box>
                    <Box>
                        <Tooltip title={"Go back"} arrow>
                            <Button
                                startIcon={<ArrowBackIosNewTwoTone />}
                                size="small"
                                sx={{ ml: 2, p: 1 }}
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                Go Back
                            </Button>
                        </Tooltip>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default PageHeader;
