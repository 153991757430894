import React, { useState } from "react";
import {
    Add,
    ArrowRightAltOutlined,
    ContactEmergencyOutlined,
    Edit,
} from "@mui/icons-material";
import { Button, Grid, MenuItem } from "@mui/material";
import CustomCrudDialog from "../../../components/dialogs/CustomCrudDialog";
import CustomSubmitButton1 from "../../../components/forms/buttons/CustomSubmitButton1";
import {
    useAddContactPersonMutation,
    useUpdateContactPersonMutation,
} from "../../../api/contactPersonAPI/contactPersonAPI";
import { useDispatch } from "react-redux";
import useRTK from "../../../hooks/useRTK";
import { setMemberProfile } from "../../../state/features/profile/memberProfileSlice";
import { isEmpty } from "lodash";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import CustomTextField from "../../../components/forms/CustomTextField";
import { colors } from "../../../assets/utils/colors";
import CustomSelect from "../../../components/forms/CustomSelect";

// ################## VALIDATION ####################
const contactPersonValidationSchema = Yup.object().shape({
    firstName: Yup.string().required("First is required field"),
    lastName: Yup.string().required("Last is required field"),
    middleName: Yup.string().nullable(),
    position: Yup.string().required(),
    gender: Yup.string().required(),
    phoneNumber: Yup.string()
        .required("Phone number is required field")
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            "Positions must be a number"
        )
        .max(10, "Phone number must be at most 10 characters")
        .min(10, "Phone number must be at least 10 characters"),
    email: Yup.string().nullable(),
});

const AddContactPerson = ({ contactPerson }) => {
    // ################# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############## Comp State ##############
    const [openDialog, setOpenDialog] = useState(false);

    // ############# Func ###############
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    // ############## RTK ##################
    // => Add
    const [
        addCompanyContacts,
        { isLoading, isSuccess, isError, error, data: addedData },
    ] = useAddContactPersonMutation();
    // => Update
    const [
        updateCompanyContacts,
        {
            isLoading: updateLoading,
            isSuccess: updateIsSuccess,
            isError: updateIsError,
            error: updateError,
            data: updatedData,
        },
    ] = useUpdateContactPersonMutation();

    // ############### useEffect ################
    // => RTK action
    const rtkActions = (data) => {
        handleCloseDialog();
        dispatch(setMemberProfile(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, addedData, rtkActions);
    RTK.useRTKResponse(
        updateIsSuccess,
        updateIsError,
        updateError,
        updatedData,
        rtkActions
    );

    return (
        <>
            <CustomCrudDialog
                openDialog={openDialog}
                closeDialog={handleCloseDialog}
                head={
                    isEmpty(contactPerson)
                        ? "Add company contact person"
                        : "Update company contact person"
                }
            >
                <Formik
                    enableReinitialize
                    initialValues={{
                        firstName: !isEmpty(contactPerson)
                            ? contactPerson.firstName
                            : "",
                        lastName: !isEmpty(contactPerson)
                            ? contactPerson.lastName
                            : "",
                        middleName: !isEmpty(contactPerson)
                            ? contactPerson.middleName
                            : "",
                        position: !isEmpty(contactPerson)
                            ? contactPerson.position
                            : "",
                        gender: !isEmpty(contactPerson)
                            ? contactPerson.gender
                            : "",
                        phoneNumber: !isEmpty(contactPerson)
                            ? contactPerson.phoneNumber
                            : "",
                        email: !isEmpty(contactPerson)
                            ? contactPerson.email
                            : "",
                    }}
                    validationSchema={contactPersonValidationSchema}
                    onSubmit={(payload) => {
                        isEmpty(contactPerson)
                            ? addCompanyContacts(payload)
                            : updateCompanyContacts(payload);
                    }}
                >
                    {(formik) => (
                        <Form>
                            <Grid
                                container
                                justifyContent={"center"}
                                columnSpacing={2}
                            >
                                <Grid item sm={4} xs={12}>
                                    <CustomTextField
                                        type="text"
                                        name="firstName"
                                        label={"First name"}
                                        bBottom={colors.secondary}
                                    />
                                </Grid>
                                <Grid item sm={4} xs={12}>
                                    <CustomTextField
                                        type="text"
                                        name="middleName"
                                        label={"Middle name"}
                                        bBottom={colors.secondary}
                                        optional
                                    />
                                </Grid>
                                <Grid item sm={4} xs={12}>
                                    <CustomTextField
                                        type="text"
                                        name="lastName"
                                        label={"Last name"}
                                        bBottom={colors.secondary}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={6}>
                                    <CustomTextField
                                        type="text"
                                        name="position"
                                        label={"Position"}
                                        bBottom={colors.secondary}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={6}>
                                    <CustomSelect
                                        label={"Gender"}
                                        formik={formik}
                                        bBottom={colors.secondary}
                                        name="gender"
                                    >
                                        <MenuItem value={"male"}>Male</MenuItem>
                                        <MenuItem value={"female"}>
                                            Female
                                        </MenuItem>
                                    </CustomSelect>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <CustomTextField
                                        type="text"
                                        name="phoneNumber"
                                        label={"Phone number"}
                                        bBottom={colors.secondary}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <CustomTextField
                                        type="text"
                                        name="email"
                                        label={"Email"}
                                        bBottom={colors.secondary}
                                        optional
                                    />
                                </Grid>
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <CustomSubmitButton1
                                    loading={isLoading || updateLoading}
                                    btnColor={"primary"}
                                    startIcon={<ContactEmergencyOutlined />}
                                    endIcon={<ArrowRightAltOutlined />}
                                    onClick={formik.handleSubmit}
                                >
                                    {isEmpty(contactPerson)
                                        ? " Add contact person"
                                        : " Update contact person"}
                                </CustomSubmitButton1>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </CustomCrudDialog>
            <Button
                size="small"
                startIcon={isEmpty(contactPerson) ? <Add /> : <Edit />}
                variant="outlined"
                sx={{
                    fontSize: 12,
                    fontWeight: "bold",
                    color: "#fff",
                    backgroundImage: `linear-gradient(rgba(23, 148, 202, .5), rgba(23, 148, 202, .5)), radial-gradient(circle at left top, rgb(1, 110, 218), rgb(49, 180, 76, .5))`,
                }}
                onClick={() => {
                    handleOpenDialog();
                }}
            >
                {isEmpty(contactPerson) ? " Add C.P" : "Update C.P"}
            </Button>
        </>
    );
};

export default AddContactPerson;
