import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Drawer from "@mui/material/Drawer";
import { colors } from "../assets/utils/colors";
import {
    Avatar,
    Badge,
    Box,
    Button,
    Grid,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";
import useColor from "../hooks/useColor";
import logo from "../assets/media/images/logo.png";
import {
    AccountBalance,
    CampaignOutlined,
    Construction,
    Home,
    LogoutOutlined,
    ManageAccounts,
    PaidOutlined,
    PaymentOutlined,
    Person2,
    SecurityOutlined,
    WorkOutline,
} from "@mui/icons-material";
import useRTK from "../hooks/useRTK";
import useAuth from "../hooks/useAuth";
import { useLogoutMutation } from "../api/authAPI/authAPI";
import CustomSubmitButton1 from "./forms/buttons/CustomSubmitButton1";
import CustomSubmitButton from "./forms/CustomSubmitButton";
import { Link, NavLink } from "react-router-dom";

// ############### CONSTANTS #################
const drawerWidth = 250;
const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    border: "none !important",
    padding: `0px !important`,
    background: `linear-gradient(360deg, ${colors.bgColor1}, ${colors.bgColor1})`,
});
const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

// ############### STYLED DRAWER HEADER #################
const DrawerToggleBtn = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(1, 1),
    "& .icon": {
        padding: 1,
        backgroundColor: colors.secondary,
        color: colors.primary,
        fontWeight: "bold",
        "&:hover": {
            backgroundColor: colors.secondary,
            color: colors.primary,
            fontWeight: "bold",
        },
    },
}));

// ############### STYLED DESKTOP DRAWER #################
const DrawerDesktop = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    boxShadow: "none",
    border: "none !important",
    "& .MuiPaper-root": {
        backgroundColor: colors.primary,
    },
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

//############# SideNavListItem #################
const SideNavListItem = styled(ListItem)(({ theme }) => ({
    paddingTop: 0,
    transition: "all ease-in-out 0.3s",
    "&::before": {
        content: '""',
        position: "absolute",
        height: 0,
        bottom: "50%",
        width: 4,
        transition: "all ease-in-out 0.3s",
        backgroundColor: colors.primary,
    },
    "& .icon": {
        minWidth: 0,
        justifyContent: "center",
        color: colors.primary,
        opacity: 0.9,
    },
    "& .name": {
        color: colors.primary,
    },
}));

//! #################### MAIN FUNC ######################
const SideBar = ({ handleDrawerToggle, mobileOpen }) => {
    // ############ CONST ###################
    // const colors = useColor();
    const theme = useTheme();
    const RTK = useRTK();
    const auth = useAuth();
    const [open, setOpen] = React.useState(true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const AppBarHeight = styled("div")(({ theme }) => ({
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    const { window } = "props";
    const container =
        window !== undefined ? () => window().document.body : undefined;

    // ############## RTK ######################
    const [
        logoutUser,
        { isLoading, isSuccess, isError, error, data: logoutData },
    ] = useLogoutMutation();
    // ############# useEffect ##################
    const rtkActions = () => {
        auth.logout();
    };
    RTK.useRTKResponse(isSuccess, isError, error, logoutData, rtkActions);
    React.useEffect(() => {
        if (isError) {
            auth.logout();
        }
    }, [isError]);

    // ################# DRAWER CONTENT ################
    // -> Nav links
    let links = [
        {
            id: 1,
            name: "Dashboard",
            icon: <Home />,
            url: "/dashboard",
            tooltip: "member area",
        },
        {
            id: 2,
            name: "Invoices",
            icon: <PaymentOutlined />,
            url: "/invoices",
            tooltip: "invoices",
        },
        {
            id: 3,
            name: "Certificates",
            icon: <SecurityOutlined />,
            url: "/certificates",
            tooltip: "certificates",
        },
        {
            id: 4,
            name: "Announcements",
            icon: <CampaignOutlined />,
            url: "/announcements",
            tooltip: "announcements",
        },
        {
            id: 5,
            name: "Manage Profile",
            icon: <ManageAccounts />,
            url: "/profile",
            tooltip: "profile",
        },
    ];
    const drawer = (
        <>
            <Box
                sx={{
                    height: "100vh",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    background: `linear-gradient(90deg, ${colors.bgColor4}, ${colors.bgColor4})`,
                }}
            >
                <Box
                    sx={{
                        height: 200,
                        borderBottomRightRadius: 50,
                        mt: -0.1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundImage: `linear-gradient(rgba(23, 148, 202, .5), rgba(23, 148, 202, .5)), radial-gradient(circle at left top, rgb(1, 110, 218), rgb(49, 180, 76, .5))`,
                    }}
                >
                    <Avatar
                        src={logo}
                        sx={{
                            height: 100,
                            width: 100,
                            mt: 2,
                            img: {
                                objectFit: "contain",
                            },
                        }}
                    />
                    <Typography
                        sx={{
                            fontSize: 12,
                            textAlign: "center",
                            whiteSpace: "collapse",
                            p: 2,
                            fontWeight: "bold",
                            color: "#fff",
                        }}
                    >
                        TANZANIA CHAMBER OF COMMERCE, INDUSTRY AND AGRICULTURE
                        (TCCIA)
                    </Typography>
                </Box>
                <Box>
                    <Grid container justifyContent={"center"}>
                        <Grid item sm={11} xs={11}>
                            {links.map((link) => (
                                <NavLink to={link.url} key={link.id}>
                                    {({ isActive }) => (
                                        <SideNavListItem
                                            disablePadding
                                            sx={{
                                                display: "block",
                                                "&::before": isActive && {
                                                    content: '""',
                                                    position: "absolute",
                                                    height: "100%",
                                                    bottom: 0,
                                                    top: 0,
                                                    width: 4,
                                                    transition:
                                                        "all ease-in-out 0.3s",
                                                    backgroundColor:
                                                        colors.primary,
                                                },
                                                "&:hover": !isActive && {
                                                    transition:
                                                        "all ease-in-out 0.3s",
                                                    "&::before": {
                                                        transition:
                                                            "all ease-in-out 0.3s",
                                                        height: "100%",
                                                        bottom: 0,
                                                    },
                                                },
                                            }}
                                        >
                                            <ListItemButton
                                                sx={{
                                                    py: 0.5,
                                                }}
                                            >
                                                <ListItemIcon
                                                    className="icon"
                                                    sx={{
                                                        mr: open ? 1 : "auto",
                                                    }}
                                                >
                                                    {link.icon}
                                                </ListItemIcon>
                                                <ListItemText
                                                    className="name"
                                                    primary={link.name}
                                                    sx={{
                                                        opacity: open ? 1 : 0,
                                                    }}
                                                    primaryTypographyProps={{
                                                        fontSize: 14,
                                                        fontWeight: "bold",
                                                    }}
                                                />
                                                <Badge
                                                    className="badge"
                                                    badgeContent={link.total}
                                                    showZero
                                                    sx={{
                                                        "& .MuiBadge-badge": {
                                                            bgcolor:
                                                                colors.bgColor3,
                                                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                                            color: colors.warning,
                                                            fontWeight: "bold",
                                                        },
                                                    }}
                                                />
                                            </ListItemButton>
                                        </SideNavListItem>
                                    )}
                                </NavLink>
                            ))}
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ mt: 5 }}>
                    <Grid container justifyContent={"center"}>
                        <Grid item sm={11} xs={11}>
                            <Link to={"/membership-levels"}>
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    color="secondary"
                                    sx={{
                                        height: 80,
                                        borderTopRightRadius: `40px !important`,
                                        display: "flex",
                                        flexDirection: "column",
                                        borderBottomLeftRadius: `40px !important`,
                                        color: "#fff",
                                        backgroundImage: `linear-gradient(rgba(23, 148, 202, .5), rgba(23, 148, 202, .5)), radial-gradient(circle at left top, rgb(1, 110, 218), rgb(49, 180, 76, .5))`,
                                    }}
                                >
                                    <AccountBalance />
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            mt: 1,
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Membership levels
                                    </Typography>
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ width: "100%" }}>
                    <CustomSubmitButton
                        btnColor={"error"}
                        loading={isLoading}
                        startIcon={<Person2 />}
                        endIcon={<LogoutOutlined />}
                        sx={{
                            height: 60,
                            borderRadius: `0 !important`,
                        }}
                        onClick={() => {
                            logoutUser();
                        }}
                    >
                        Log out
                    </CustomSubmitButton>
                </Box>
            </Box>
        </>
    );

    return (
        <>
            {/* ##################### mobile ################# */}
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: "block", sm: "none" },
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: "75%", // Change the width of drawer in mobile
                        backgroundColor: colors.primary,
                    },
                }}
            >
                <Toolbar
                    sx={{ display: { md: "none", sm: "none", xs: "block" } }}
                />
                {drawer}
            </Drawer>

            {/* ##################### desktop ################ */}
            <DrawerDesktop
                variant="permanent"
                open={open}
                sx={{
                    display: { xs: "none", sm: "block" },
                }}
            >
                {/* <AppBarHeight /> */}
                {/* ========== Drawer desk toggle button ======== */}
                {/* <DrawerToggleBtn
                    sx={{
                        position: 'absolute',
                        top: 60,
                        zIndex: 1098,
                        right: -5,
                    }}
                >
                    {open ? (
                        <IconButton
                            className="icon"
                            onClick={handleDrawerClose}
                            sx={{
                                '&.icon': {
                                    border: `2.5px solid ${colors.primary}`,
                                },
                            }}
                        >
                            {theme.direction === 'rtl' ? (
                                <ChevronRightIcon />
                            ) : (
                                <ChevronLeftIcon />
                            )}
                        </IconButton>
                    ) : (
                        <IconButton
                            className="icon"
                            onClick={handleDrawerOpen}
                            sx={{
                                '&.icon': {
                                    border: `2.5px solid ${colors.primary}`,
                                },
                            }}
                        >
                            {theme.direction === 'rtl' ? (
                                <ChevronRightIcon />
                            ) : (
                                <ChevronRightIcon />
                            )}
                        </IconButton>
                    )}
                </DrawerToggleBtn> */}
                <Divider />
                {drawer}
            </DrawerDesktop>
        </>
    );
};

export default SideBar;
