import React, { useState } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import {
    ArrowRight,
    Receipt,
    SecurityOutlined,
    SecurityUpdateGood,
} from "@mui/icons-material";
import CustomButton2 from "../../../components/forms/buttons/CustomButton2";
import ReportDialog from "../../../components/dialogs/ReportDialog";
import { PDFDownloadLink } from "@react-pdf/renderer";
import CertificatePDF from "../../reports/CertificatePDF";

const GetCertificateTwo = ({ fullWidth, license }) => {
    // ############## Comp State ##############
    const [openDialog, setOpenDialog] = useState(false);

    // ############# Func ###############
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <>
            <Box>
                {/* ################ Dialogy ################# */}
                <ReportDialog
                    head={"Certificate generator"}
                    customWidth={"md"}
                    openDialog={openDialog}
                    handleDialogClose={handleCloseDialog}
                >
                    <CertificatePDF license={license} />
                </ReportDialog>
                {/* <PDFDownloadLink
                    document={
                        <InvoicePDF
                            bankAcc={bankAcc}
                            invoice={invoice}
                            profile={profile}
                        />
                    }
                    fileName="invoice.pdf"
                >
                    {({ blob, url, loading, error }) => (
                        <CustomButton2
                            loading={loading}
                            size={"large"}
                            btColor="warning"
                            fullWidth={fullWidth && fullWidth}
                            startIcon={<Receipt />}
                            endIcon={<ArrowRight />}
                            title={"Invoice PDF"}
                        />
                    )}
                </PDFDownloadLink> */}
                <CustomButton2
                    size={"large"}
                    btColor="success"
                    fullWidth={fullWidth && fullWidth}
                    action={handleOpenDialog}
                    startIcon={<SecurityUpdateGood />}
                    endIcon={<ArrowRight />}
                    title={"Get certificate"}
                />
            </Box>
        </>
    );
};

export default GetCertificateTwo;
