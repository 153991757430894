import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
} from "@react-pdf/renderer";
import logo from "../../assets/media/images/logo.png";
import { colors } from "../../assets/utils/colors";

// Create styles
const styles = StyleSheet.create({
    page: {
        paddingLeft: "50px",
        paddingRight: "50px",
        paddingTop: "50px",
        paddingBottom: "85px",
        backgroundColor: colors.bgColor4,
    },
    section: {},
    image: {
        height: "120px",
        width: "180px",
    },
    pageNumber: {
        position: "absolute",
        fontSize: 12,
        bottom: 15,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "grey",
    },
});

// Create Document Component
const ReportLayout = ({ head, orientation, children }) => (
    <Document>
        <Page
            size="A4"
            style={styles.page}
            dpi={70}
            orientation={orientation ? orientation : "portrait"}
        >
            <View
                style={{
                    display: "flex",
                    alignItems: "center",
                    position: "absolute",
                    flexDirection: "row",
                    left: 40,
                    right: 30,
                    paddingTop: 10,
                }}
            >
                <View
                    style={{
                        width: 80,
                        height: "100%",
                        padding: 5,
                        backgroundColor: colors.primary,
                        borderRadius: 5,
                    }}
                >
                    <Image src={logo} />
                </View>
                <View
                    style={{
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingLeft: 20,
                        paddingRight: 20,
                        marginLeft: 5,
                        position: "relative",
                        borderRadius: 5,
                    }}
                >
                    <Text
                        style={{
                            color: colors.primary,
                            fontSize: "14px",
                            textTransform: "uppercase",
                            marginBottom: `5px`,
                        }}
                    >
                        Tanzania chamber of
                    </Text>
                    <Text
                        style={{
                            color: colors.primary,
                            fontSize: "14px",
                            textTransform: "uppercase",
                            marginBottom: `5px`,
                        }}
                    >
                        commerce, industyr and agriculture
                    </Text>
                    <Text
                        style={{
                            color: colors.primary,
                            fontSize: "8px",
                            marginBottom: `5px`,
                        }}
                    >
                        P.O.BOX 9713 Dar Es Salaam, Tanzania, Tel: +255-22-2
                        119437 Fax: +255-22-2119436
                    </Text>
                    <View
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                        }}
                    >
                        <Text
                            style={{
                                color: colors.primary,
                                fontSize: "8px",
                                marginRight: "15px",
                            }}
                        >
                            E-mail: hq@tccia.com
                        </Text>
                        <Text
                            style={{
                                color: colors.primary,
                                fontSize: "8px",
                                marginLeft: `15px`,
                            }}
                        >
                            Website: http://www.tccia.or.tz
                        </Text>
                    </View>
                    <View
                        style={{
                            height: 2,
                            backgroundColor: colors.primary,
                            width: 400,
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                        }}
                    ></View>
                </View>
            </View>
            <View
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 40,
                    marginTop: 20,
                }}
            >
                <Text
                    style={{
                        textAlign: "center",
                        fontSize: "12px",
                        color: colors.primary,
                        fontWeight: "700px",
                        textTransform: "uppercase",
                        textDecoration: "underline",
                    }}
                >
                    {head}
                </Text>
            </View>
            {/* ################# Footer ############## */}
            <View
                style={{
                    position: "absolute",
                    left: "50px !important",
                    right: "50px !important",
                    bottom: "0 !important",
                    height: 80,
                }}
                fixed
            >
                <View
                    style={{
                        position: "relative",
                        bottom: -10,
                        left: 0,
                        right: 0,
                        paddingRight: 20,
                        paddingTop: 10,
                        borderTop: `2px solid ${colors.primary}`,
                    }}
                >
                    <Text
                        style={{
                            fontSize: "10px",
                            fontStyle: "italic",
                            textAlign: "center",
                            opacity: 0.8,
                        }}
                    >
                        Private sector, The engine of growth
                    </Text>
                </View>
            </View>
            {/*################### content ############## */}
            <View>{children}</View>

            <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) =>
                    `${pageNumber} out of ${totalPages}`
                }
                fixed
            />
        </Page>
    </Document>
);

export default ReportLayout;
