import React from "react";
import CustomCard from "../../../components/cards/CustomCard";
import AttachmentTable from "../../../components/tables/AttachmentTable";
import { filter } from "lodash";

const CompanyAttachment = ({ profile }) => {
    const attachments = profile.attachments;

    const getAttachment = (type) => {
        let attachment = filter(
            attachments,
            ({ attachmentType }) => attachmentType === type
        );
        if (attachment) {
            return attachment[0];
        } else {
            return false;
        }
    };

    const logo = getAttachment("logo");
    const cProfile = getAttachment("profile");
    const tin = getAttachment("tin");
    const cin = getAttachment("CIP");
    const license = getAttachment("license");

    const attachmentsList = [
        {
            id: 5,
            attachmentType: "logo",
            attachmentName: "Company logo",
            attachmentNumber: "",
            attachmentDocument: logo?.attachmentDocument,
            status: logo ? true : false,
        },
        {
            id: 4,
            attachmentType: "profile",
            attachmentName: "Company profile",
            attachmentNumber: "",
            attachmentDocument: cProfile?.attachmentDocument,
            status: cProfile ? true : false,
        },
        {
            id: 1,
            attachmentType: "tin",
            attachmentName: "Tax identification number",
            attachmentNumber: tin?.attachmentNumber,
            attachmentDocument: tin?.attachmentDocument,
            status: tin ? true : false,
        },
        {
            id: 2,
            attachmentType: "CIN",
            attachmentName: "Certificate of incoparation",
            attachmentNumber: cin?.attachmentNumber,
            attachmentDocument: cin?.attachmentDocument,
            status: cin ? true : false,
        },
        {
            id: 3,
            attachmentType: "license",
            attachmentName: "Business license",
            attachmentNumber: license?.attachmentNumber,
            attachmentDocument: license?.attachmentDocument,
            status: license ? true : false,
        },
    ];

    return (
        <div>
            <CustomCard
                title={"Company attachments"}
                // action={<AddAttachment />}
            >
                <AttachmentTable attachments={attachmentsList} />
            </CustomCard>
        </div>
    );
};

export default CompanyAttachment;
