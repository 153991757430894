import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import PageLayout from "../layouts/PageLayout";
import { colors } from "../../assets/utils/colors";
import useRTK from "../../hooks/useRTK";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { adminSelector } from "../../state/features/selectors";
import { setAnnouncementDetails } from "../../state/features/admin/adminSlice";
import { useGetSingleAnnouncementQuery } from "../../api/adminAPI/adminAPI";
import InnerContentLoader from "../../components/InnerContentLoader";
import { isEmpty } from "lodash";
import moment from "moment";

const AnnouncementDetailsPage = () => {
    // ############### CONST ######################
    const RTK = useRTK();
    const dispatch = useDispatch();
    const { announcement_id } = useParams();

    // ############## Comp state ##################
    const [announcement, setAnnouncement] = useState(null);

    // ############### Redux state ###############
    const { announcementDetails } = useSelector(adminSelector);

    // ############## RTK ####################
    const {
        isLoading,
        isSuccess,
        data: announcementData,
    } = useGetSingleAnnouncementQuery(announcement_id);
    // => Actiion
    const rtkAction = (data) => {
        dispatch(setAnnouncementDetails(data));
    };
    // => UseEffect
    RTK.useRTKFetch(isSuccess, announcementData, rtkAction);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            setAnnouncement(announcementDetails);
        }
        return () => {
            isSubscribed = false;
        };
    }, [announcementDetails]);
    return (
        <>
            <PageLayout head={"Announcement details"} noSidebar>
                {isLoading ? (
                    <>
                        <InnerContentLoader height="60vh" />
                    </>
                ) : !isEmpty(announcement) ? (
                    <>
                        <Grid container spacing={4} justifyContent={"center"}>
                            <Grid item sm={8}>
                                <Box
                                    sx={{
                                        height: 300,
                                        width: "100%",
                                        bgcolor: colors.bgColor1,
                                        boxShadow: `0 0 10px ${colors.bgColor2}`,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            p: 2,
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 25,
                                                fontWeight: "bold",
                                                background: `linear-gradient(rgba(23, 148, 202, .8), rgb(49, 180, 76, .8))`,
                                                backgroundClip: "text",
                                                textFillColor: "transparent",
                                            }}
                                        >
                                            {announcement.title}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 16,
                                                fontWeight: "bold",
                                                color: colors.primary,
                                            }}
                                        >
                                            {moment(
                                                announcement.created_at
                                            ).format("ll")}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ p: 2 }}>
                                        <Typography
                                            sx={{ fontSize: 15, opacity: 0.8 }}
                                        >
                                            {announcement.description}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    ""
                )}
            </PageLayout>
        </>
    );
};

export default AnnouncementDetailsPage;
