import React from "react";
import CustomCard from "../../../components/cards/CustomCard";
import DirectorsTable from "../../../components/tables/DirectorsTable";
import AddDirector from "../CRUD/AddDirector";

const CompanyDirectors = ({ profile }) => {
    const directors = profile.directors;
    return (
        <div>
            <CustomCard title={"Company directors"} action={<AddDirector />}>
                <DirectorsTable directors={directors} />
            </CustomCard>
        </div>
    );
};

export default CompanyDirectors;
