import { isEmpty } from 'lodash';
import Cookies from 'universal-cookie';

// => get token
const cookies = new Cookies();
let cookieToken = cookies.get('token');

export const headers = (headers, { getState }) => {
    const token = !isEmpty(cookieToken)
        ? cookieToken
        : getState().authReducer.authToken;
    if (token) {
        headers.set('authorization', `Bearer ${token}`);
        headers.set('accept', `application/json`);
        return headers;
    }
};
