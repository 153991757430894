import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Formik } from "formik";
import {
    ArrowRightAltOutlined,
    ListAltOutlined,
    PersonAddAlt1Outlined,
    VerifiedOutlined,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import useRTK from "../../../hooks/useRTK";
import { useRegisterMutation } from "../../../api/registerAPI/registerAPI";
import { registerSelector } from "../../../state/features/selectors";
import { colors } from "../../../assets/utils/colors";
import NextAndBackBtn from "../../../components/steppers/NextAndBackBtn";
import useAuth from "../../../hooks/useAuth";
import CustomSubmitButton1 from "../../../components/forms/buttons/CustomSubmitButton1";
import DialogLinerProgress from "../../../components/DialogLinerProgress";

const InfoConfimation = ({ steps, activeStep, handleNext, handleBack }) => {
    // ############## CONST #################
    const auth = useAuth();
    const RTK = useRTK();

    // ############ Redux State ##############
    const { memberICN, phoneNumber, companyName, password } =
        useSelector(registerSelector);
    const infoConfimation = [
        {
            id: 1,
            title: "Member company name",
            value: companyName,
        },
        {
            id: 2,
            title: "Taxpayer Identification Number (TIN)",
            value: memberICN,
        },
        {
            id: 3,
            title: "Member phone number",
            value: phoneNumber,
        },
        // {
        //     id: 4,
        //     title: "Member login Password",
        //     value: password,
        // },
    ];

    // ############# RTK ##################
    const [
        register,
        { isLoading, isSuccess, isError, error, data: studentData },
    ] = useRegisterMutation();

    // ############# useEffect ##################
    const rtkActions = (data) => {
        auth.login(data);
    };
    RTK.useRTKResponse(isSuccess, isError, error, studentData, rtkActions);

    return (
        <>
            <DialogLinerProgress isLoading={isLoading} />
            <Formik
                enableReinitialize
                initialValues={{
                    companyName: companyName,
                    tinNumber: memberICN,
                    phoneNumber: phoneNumber,
                    password: password,
                }}
                onSubmit={(payload) => {
                    register(payload);
                }}
            >
                {(formik) => (
                    <Grid
                        container
                        justifyContent={"center"}
                        padding={2}
                        py={{ md: 2 }}
                    >
                        <Grid item sm={11} xs={12}>
                            <Typography sx={{ fontSize: 14, mb: 2 }}>
                                Please confirm details that your provide then
                                complete registration.
                            </Typography>
                        </Grid>
                        <Grid item sm={11} xs={12} sx={{}}>
                            {infoConfimation.map((detail, index) => (
                                <Box sx={{ mb: 1 }}>
                                    <Typography
                                        sx={{
                                            color: colors.primary,
                                            fontSize: 12,
                                            display: "flex",
                                            alignItems: "center",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {`${++index}) ${detail.title}`}
                                        <VerifiedOutlined
                                            sx={{
                                                color: colors.secondary,
                                                fontSize: 20,
                                                ml: 1,
                                            }}
                                        />
                                    </Typography>
                                    <Typography
                                        sx={{
                                            opacity: 0.8,
                                            fontSize: 15,
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <ListAltOutlined
                                            sx={{
                                                color: colors.orange,
                                                fontSize: 15,
                                                ml: 1,
                                                mr: 0.5,
                                            }}
                                        />
                                        {detail.value}
                                    </Typography>
                                </Box>
                            ))}
                        </Grid>

                        {/* ################ ACTION BUTONS ###################### */}
                        <Grid
                            item
                            sm={11}
                            xs={12}
                            sx={{
                                mt: 2,
                                textAlign: "center",
                            }}
                        >
                            <CustomSubmitButton1
                                startIcon={<PersonAddAlt1Outlined />}
                                endIcon={<ArrowRightAltOutlined />}
                                variant={"contained"}
                                loading={isLoading}
                                onClick={() => {
                                    formik.handleSubmit();
                                }}
                            >
                                Complete registration
                            </CustomSubmitButton1>
                        </Grid>
                        <Grid
                            item
                            sm={11}
                            xs={12}
                            sx={{
                                marginTop: 2.5,
                                textAlign: "center",
                            }}
                        >
                            <NextAndBackBtn
                                steps={steps}
                                activeStep={activeStep}
                                handleBack={handleBack}
                                action={handleNext}
                            />
                        </Grid>
                    </Grid>
                )}
            </Formik>
        </>
    );
};

export default InfoConfimation;
