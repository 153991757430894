import React from "react";
import { Box, Grid, styled } from "@mui/material";
import DashSidebar from "../components/sidebars/DashSidebar";
import MiniMemberDetailsWidget from "../components/widgets/MiniMemberDetailsWidget";
import ActiveSubscriptionWidget from "../components/widgets/ActiveSubscriptionWidget";
import ProfileProgress from "./profile/components/ProfileProgress";
import { useSelector } from "react-redux";
import {
    invoiceSelector,
    memberProfileSelector,
    membershipLevelSelector,
    profileSelector,
} from "../state/features/selectors";
import DashboardLayout from "./layouts/DashboardLayout";
import PageLayout from "./layouts/PageLayout";
import WelcomeHead from "../components/WelcomeHead";
import { paginator } from "../helpers/paginationHelper";
import CustomPagination2 from "../components/paginations/CustomPagination2";
import NoContent from "../components/NoContent";
import { isEmpty, size } from "lodash";
import InvoiceWidget from "../components/widgets/InvoiceWidget";
import CountBadge from "../components/CountBadge";
import MemberShipLevelWidget2 from "../components/widgets/MemberShipLevelWidget2";
import Section from "../components/Section";
import { colors } from "../assets/utils/colors";

const DrawerHeader = styled(Box)(({ theme }) => ({
    ...theme.mixins.toolbar,
}));

const Dashboard = () => {
    // ############## Comp state ##################
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(5);

    // ############## FUNC ####################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };

    // ############# Redux state ################
    const { memberProfile: profile } = useSelector(memberProfileSelector);
    const { profile: authProfile } = useSelector(profileSelector);
    const { invoices } = useSelector(invoiceSelector);
    const { membershipLevels } = useSelector(membershipLevelSelector);

    // => Pagination data
    const paginatedInvoices = paginator(invoices, page, perPage);
    return (
        <>
            {authProfile.isEligible === false ? (
                <>
                    <PageLayout noSidebar>
                        <DrawerHeader />
                        <Box sx={{ mt: 4 }}>
                            <ProfileProgress />
                        </Box>
                        <Section
                            bgColor={`linear-gradient(360deg, ${colors.tertiary}, ${colors.tertiary})`}
                            head={"Become TCCIA Member"}
                            subHead={
                                "The price for each plan represents a one-year payment."
                            }
                        >
                            <Grid
                                container
                                columnSpacing={{ sm: 2, xs: 0 }}
                                rowSpacing={4}
                                justifyContent={{ sm: "left", xs: "center" }}
                            >
                                {membershipLevels.map((level) => (
                                    <Grid
                                        item
                                        md={4}
                                        sm={6}
                                        xs={11}
                                        key={level.id}
                                    >
                                        <MemberShipLevelWidget2
                                            level={level}
                                            isSubscribed={
                                                level.id ===
                                                profile?.membershipLevel
                                                    ? true
                                                    : false
                                            }
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Section>
                    </PageLayout>
                </>
            ) : (
                <>
                    <DashboardLayout sidebar={<DashSidebar />}>
                        <Grid container spacing={2}>
                            <Grid
                                item
                                sm={12}
                                sx={{
                                    display: {
                                        md: "none",
                                        sm: "none",
                                        xs: "flex",
                                        flexDirection: "column",
                                    },
                                }}
                            >
                                <WelcomeHead profile={profile} />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <MiniMemberDetailsWidget profile={profile} />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <ActiveSubscriptionWidget />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <CustomPagination2
                                    head={"Invoice history"}
                                    height={"39vh"}
                                    handlePageChange={handlePageChange}
                                    handlePerPageChage={handlePerPageChage}
                                    data={paginatedInvoices}
                                    action={
                                        <CountBadge
                                            item={"Invoices"}
                                            total={size(invoices)}
                                        />
                                    }
                                >
                                    {isEmpty(invoices) ? (
                                        <>
                                            <NoContent
                                                height={300}
                                                message={"No generated invoice"}
                                            />
                                        </>
                                    ) : (
                                        <Grid container spacing={3}>
                                            {paginatedInvoices.data.map(
                                                (invoice) => (
                                                    <Grid
                                                        item
                                                        sm={12}
                                                        key={invoice.id}
                                                    >
                                                        <InvoiceWidget
                                                            invoice={invoice}
                                                        />
                                                    </Grid>
                                                )
                                            )}
                                        </Grid>
                                    )}
                                </CustomPagination2>
                            </Grid>
                        </Grid>
                    </DashboardLayout>
                </>
            )}
        </>
    );
};

export default Dashboard;
