export const colors = {
    primary: "#1794CA",
    secondary: "#31B44C",
    tertiary: "#E6F3FF",
    bgColor1: "#e7f4f9",
    bgColor2: "#a2d4e9",
    bgColor3: "#b9deef",
    bgColor4: "#d0e9f4",
    bgColor5: "#e7f4f9",
    orange: "#fca311",
    yellow: "#FFD300",
    green: "#00EE76",
    red: "#F43249",
    gray: "#D9D9D9",
    blue: "#027BBD",
};
