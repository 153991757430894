import { configureStore } from "@reduxjs/toolkit";
import { RTKmiddleware } from "./RTKmiddlewares";

// => SLICE reduces
import authReducer from "../features/auth/authSlice";
import registerReducer from "../features/register/registerSlice";
import systemConfigReducer from "../features/systemConfig/systemConfigSlice";
import profileReducer from "../features/profile/profileSlice";
import memberProfileReducer from "../features/profile/memberProfileSlice";
import adminReducer from "../features/admin/adminSlice";
import invoiceReducer from "../features/invoice/invoiceSlice";
import membershipLevelReducer from "../features/membershipLevel/membershipLevelSlice";
import licenseReducer from "../features/license/licenseSlice";
import notificationsReducer from "../features/notifications/notificationsSlice";
import certificateReducer from "../features/certificate/certificateSlice";

// => RTK reducers
import { authAPI } from "../../api/authAPI/authAPI";
import { registerAPI } from "../../api/registerAPI/registerAPI";
import { memberAttachmentsAPI } from "../../api/memberAttachmentsAPI/memberAttachmentsAPI";
import { profileAPI } from "../../api/profileAPI/profileAPI";
import { contactsAPI } from "../../api/contactsAPI/contactsAPI";
import { contactPersonAPI } from "../../api/contactPersonAPI/contactPersonAPI";
import { directorsAPI } from "../../api/directorsAPI/directorsAPI";
import { memberAPI } from "../../api/memberAPI/memberAPI";
import { adminAPI } from "../../api/adminAPI/adminAPI";
import { invoiceAPI } from "../../api/invoiceAPI/invoiceAPI";
import { membershipLevelAPI } from "../../api/membershipLevelAPI/membershipLevelAPI";
import { licenseAPI } from "../../api/licenseAPI/licenseAPI";
import { notificationsAPI } from "../../api/notificationsAPI/notificationsAPI";
import { certificateAPI } from "../../api/certificateAPI/certificateAPI";

export const store = configureStore({
    reducer: {
        [authAPI.reducerPath]: authAPI.reducer,
        [registerAPI.reducerPath]: registerAPI.reducer,
        [memberAttachmentsAPI.reducerPath]: memberAttachmentsAPI.reducer,
        [profileAPI.reducerPath]: profileAPI.reducer,
        [contactsAPI.reducerPath]: contactsAPI.reducer,
        [contactPersonAPI.reducerPath]: contactPersonAPI.reducer,
        [directorsAPI.reducerPath]: directorsAPI.reducer,
        [memberAPI.reducerPath]: memberAPI.reducer,
        [adminAPI.reducerPath]: adminAPI.reducer,
        [invoiceAPI.reducerPath]: invoiceAPI.reducer,
        [membershipLevelAPI.reducerPath]: membershipLevelAPI.reducer,
        [licenseAPI.reducerPath]: licenseAPI.reducer,
        [notificationsAPI.reducerPath]: notificationsAPI.reducer,
        [certificateAPI.reducerPath]: certificateAPI.reducer,
        authReducer,
        registerReducer,
        profileReducer,
        memberProfileReducer,
        systemConfigReducer,
        adminReducer,
        invoiceReducer,
        licenseReducer,
        notificationsReducer,
        membershipLevelReducer,
        certificateReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(RTKmiddleware),
});
