import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { certificateEndpoints } from "./certificateEndpoints";
import { baseURL } from "../baseUrl/baseURL";
import { headers } from "../baseUrl/setHeaders";

export const certificateAPI = createApi({
    reducerPath: "certificateAPI",
    baseQuery: fetchBaseQuery({ baseUrl: baseURL, prepareHeaders: headers }),
    endpoints: (builder) => ({
        verifyCertificate: builder.mutation({
            query: (payload) => ({
                url: `${certificateEndpoints.VERIFY_CERTIFICATE}`,
                method: `POST`,
                body: payload,
            }),
        }),

        verifyCertificateQR: builder.query({
            query: (payload) => ({
                url: `${certificateEndpoints.VERIFY_CERTIFICATE_QR}`,
                method: `GET`,
            }),
        }),
    }),
});

export const { useVerifyCertificateMutation, useVerifyCertificateQRQuery } =
    certificateAPI;
