import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../baseUrl/baseURL";
import { headers } from "../baseUrl/setHeaders";
import { contactPersonEndpoints } from "./contactEndpoints";
import { profileAPI } from "../profileAPI/profileAPI";

export const contactPersonAPI = createApi({
    reducerPath: "contactPersonAPI",
    baseQuery: fetchBaseQuery({ baseUrl: baseURL, prepareHeaders: headers }),
    endpoints: (builder) => ({
        addContactPerson: builder.mutation({
            query: (payload) => ({
                url: `${contactPersonEndpoints.ADD_CONTACT_PERSON}`,
                method: `POST`,
                body: payload,
            }),
            async onQueryStarted(_, { queryFulfilled, dispatch }) {
                try {
                    await queryFulfilled;
                    dispatch(profileAPI.util.invalidateTags(["Profile"]));
                } catch (err) {}
            },
        }),
        updateContactPerson: builder.mutation({
            query: (payload) => ({
                url: `${contactPersonEndpoints.UPDATE_CONTACT_PERSON}`,
                method: `PUT`,
                body: payload,
            }),
            async onQueryStarted(_, { queryFulfilled, dispatch }) {
                try {
                    await queryFulfilled;
                    dispatch(profileAPI.util.invalidateTags(["Profile"]));
                } catch (err) {}
            },
        }),
    }),
});

export const { useAddContactPersonMutation, useUpdateContactPersonMutation } =
    contactPersonAPI;
