import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    certificate: null,
};

// !########## SLICE #############
const certificateSlice = createSlice({
    name: "certificateSlice",
    initialState,
    reducers: {
        setCertificate: (state, action) => {
            state.certificate = action.payload;
        },
    },
});

export const { setCertificate } = certificateSlice.actions;
export default certificateSlice.reducer;
