import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import AppLayout from "../pages/layouts/AppLayout";
import GuestRoutes from "./GuestRoutes";
import PrivateRoutes from "./PrivateRoutes";
import HomeLayout from "../pages/layouts/HomeLayout";
import HomePage from "../pages/HomePage";
import MemberShipLevels from "../pages/memberShip/MemberShipLevels";
import Profile from "../pages/profile/Profile";
import AppLayout2 from "../pages/layouts/AppLayout2";
import InvoicePage from "../pages/invoices/InvoicePage";
import AnnouncementPage from "../pages/announcement/AnnouncementPage";
import InvoiceDetailsPage from "../pages/invoices/InvoiceDetailsPage";
import InvoiceByStatusPage from "../pages/invoices/InvoiceByStatusPage";
import AnnouncementDetailsPage from "../pages/announcement/AnnouncementDetailsPage";
import SubscriptionsPage from "../pages/subscriptions/SubscriptionsPage";
import SubscriptionDetailsPage from "../pages/subscriptions/SubscriptionDetailsPage";
import CertificateVerifyPage from "../pages/certificate/CertificateVerifyPage";

// ?############## GUEST ELEMENTS ###########
const LoginElement = () => (
    <HomeLayout>
        <HomePage />
    </HomeLayout>
);
const CertificateVerifyElement = () => (
    <HomeLayout>
        <CertificateVerifyPage />
    </HomeLayout>
);

// ?############## PROTECTED ELEMENTS ###########
const DashboardElement = () => (
    <AppLayout2>
        <Dashboard />
    </AppLayout2>
);
const ProfileElement = () => (
    <AppLayout>
        <Profile />
    </AppLayout>
);
const MemberShipLevelsElement = () => (
    <AppLayout>
        <MemberShipLevels />
    </AppLayout>
);
const InvoiceElement = () => (
    <AppLayout>
        <InvoicePage />
    </AppLayout>
);
const InvoiceDetailsElement = () => (
    <AppLayout>
        <InvoiceDetailsPage />
    </AppLayout>
);
const InvoiceByStatusElement = () => (
    <AppLayout>
        <InvoiceByStatusPage />
    </AppLayout>
);
const AnnouncementElement = () => (
    <AppLayout>
        <AnnouncementPage />
    </AppLayout>
);
const AnnouncementDetailsElement = () => (
    <AppLayout>
        <AnnouncementDetailsPage />
    </AppLayout>
);
const SubscriptionsElement = () => (
    <AppLayout>
        <SubscriptionsPage />
    </AppLayout>
);
const SubscriptionDetailsElement = () => (
    <AppLayout>
        <SubscriptionDetailsPage />
    </AppLayout>
);

// !############## MAIN FUNC ###########
const App = () => {
    return (
        <React.Fragment>
            <Routes>
                {/* ################## GUEST ROUTES ################## */}
                <Route element={<GuestRoutes />}>
                    <Route path="/" element={<LoginElement />} />
                    <Route
                        path="/certificates/verify/:certificateNumber/:year"
                        element={<CertificateVerifyElement />}
                    />
                </Route>

                {/* ################## PROTECTED ROUTES ################## */}
                <Route element={<PrivateRoutes />}>
                    <Route path="/dashboard" element={<DashboardElement />} />
                    <Route path="/profile" element={<ProfileElement />} />
                    <Route path="/invoices" element={<InvoiceElement />} />
                    <Route
                        path="/invoices/:invoice_id"
                        element={<InvoiceDetailsElement />}
                    />
                    <Route
                        path="/invoices/status/:status"
                        element={<InvoiceByStatusElement />}
                    />
                    <Route
                        path="/announcements"
                        element={<AnnouncementElement />}
                    />
                    <Route
                        path="/announcements/:announcement_id"
                        element={<AnnouncementDetailsElement />}
                    />
                    <Route
                        path="/membership-levels"
                        element={<MemberShipLevelsElement />}
                    />
                    <Route
                        path="/certificates"
                        element={<SubscriptionsElement />}
                    />
                    <Route
                        path="/certificates/:license_id"
                        element={<SubscriptionDetailsElement />}
                    />
                </Route>
            </Routes>
        </React.Fragment>
    );
};

export default App;
