import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import SpacingContent from "../../components/SpacingContent";
import { Link, useParams } from "react-router-dom";
import { ArrowLeftOutlined } from "@mui/icons-material";
import { colors } from "../../assets/utils/colors";
import { useDispatch } from "react-redux";
import { useVerifyCertificateMutation } from "../../api/certificateAPI/certificateAPI";
import useRTK from "../../hooks/useRTK";
import { setCertificate } from "../../state/features/certificate/certificateSlice";
import CertificateVerify from "../../components/CertificateVerify";
import InnerContentLoader from "../../components/InnerContentLoader";

const CertificateVerifyPage = () => {
    // ################# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();
    const { certificateNumber, year } = useParams();
    const certID = certificateNumber + "/" + year;

    // ############## RTK ##################
    const [
        verifyCertificate,
        { isLoading, isSuccess, isError, error, data: updatedData },
    ] = useVerifyCertificateMutation();

    // ############### useEffect ################
    // => RTK action
    const rtkActions = (data) => {
        dispatch(setCertificate(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, updatedData, rtkActions);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            verifyCertificate({ cert_no: certID });
        }
        return () => {
            isSubscribed = false;
        };
    }, []);

    return (
        <>
            <Box
                sx={{
                    mt: 15,
                    mb: 10,
                    // height: "100vh",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                }}
            >
                <SpacingContent>
                    <Grid container justifyContent={"center"}>
                        <Grid item sm={6} xs={11}>
                            <Box
                                sx={{
                                    borderRadius: 2,
                                    overflow: "hidden",
                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                                }}
                            >
                                {isLoading ? (
                                    <>
                                        <Box>
                                            <InnerContentLoader height={400} />
                                        </Box>
                                    </>
                                ) : isError ? (
                                    <>
                                        <Box
                                            sx={{
                                                height: 400,
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                            className="error-message"
                                        >
                                            <Typography
                                                sx={{ textAlign: "center" }}
                                            >
                                                Certificate details not found
                                            </Typography>
                                        </Box>
                                    </>
                                ) : (
                                    <>
                                        <CertificateVerify />
                                    </>
                                )}
                            </Box>
                        </Grid>
                        <Grid item sm={6.1}>
                            <Box
                                sx={{
                                    width: "100%",
                                    mt: 2,
                                    p: 1,
                                    display: "flex",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <Link to={"/"}>
                                    <Button startIcon={<ArrowLeftOutlined />}>
                                        Back to home
                                    </Button>
                                </Link>
                            </Box>
                        </Grid>
                    </Grid>
                </SpacingContent>
            </Box>
        </>
    );
};

export default CertificateVerifyPage;
