import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { colors } from "../../assets/utils/colors";
import moment from "moment";

const MiniAnnouncementWidget = ({ announcement }) => {
    return (
        <>
            <Link to={`/announcements/${announcement.id}`}>
                <Box
                    sx={{
                        position: "relative",
                        ":before": {
                            content: "''",
                            width: "5%",
                            height: 3,
                            background: colors.primary,
                            position: "absolute",
                            top: 0,
                        },
                        ":after": {
                            content: "''",
                            width: "92%",
                            height: 3,
                            background: colors.bgColor2,
                            position: "absolute",
                            top: 0,
                            right: 0,
                        },
                        background: `linear-gradient(360deg, ${colors.bgColor1}, ${colors.bgColor4})`,
                        transition: `.3s`,
                        borderRadius: 2,
                        overflow: "hidden",
                        border: `1px solid ${colors.bgColor2}`,
                        ":hover": {
                            transition: `.3s`,
                            boxShadow: `4px 4px 10px 0 ${colors.bgColor2}`,
                        },
                    }}
                >
                    <Grid container>
                        <Grid item sm={3} xs={3}>
                            <Box
                                sx={{
                                    height: "100%",
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    ".icon": {
                                        fontSize: 45,
                                    },
                                    color: colors.secondary,
                                }}
                            >
                                <Box>
                                    <Typography
                                        sx={{
                                            fontSize: 25,
                                            textAlign: "center",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {moment(announcement.created_at).format(
                                            "d"
                                        )}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 10,
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            color: colors.primary,
                                        }}
                                        noWrap
                                    >
                                        {moment(announcement.created_at).format(
                                            "MMM YYYY"
                                        )}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item sm={9} xs={9}>
                            <Box sx={{ p: 2 }}>
                                <Typography
                                    sx={{
                                        fontSize: 12,
                                        fontWeight: "bold",
                                        mb: 1,
                                        opacity: 0.9,
                                        color: colors.primary,
                                    }}
                                >
                                    {announcement.title}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 12,
                                        color: "gray",
                                    }}
                                    noWrap
                                >
                                    {announcement.description}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Link>
        </>
    );
};

export default MiniAnnouncementWidget;
