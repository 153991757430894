import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    invoices: [],
    invoiceDetails: {},
};

// !########## SLICE #############
const invoiceSlice = createSlice({
    name: "invoiceSlice",
    initialState,
    reducers: {
        setInvoices: (state, action) => {
            state.invoices = action.payload;
        },
        setInvoiceDetails: (state, action) => {
            state.invoiceDetails = action.payload;
        },
    },
});

export const { setInvoices, setInvoiceDetails } = invoiceSlice.actions;
export default invoiceSlice.reducer;
