import React from "react";
import { Box, Typography } from "@mui/material";
import {
    ArrowRightAlt,
    ArrowRightAltOutlined,
    ManageAccounts,
    ReceiptLongOutlined,
} from "@mui/icons-material";
import CustomSubmitButton1 from "../../../components/forms/buttons/CustomSubmitButton1";
import {
    memberProfileSelector,
    profileSelector,
} from "../../../state/features/selectors";
import { useDispatch, useSelector } from "react-redux";
import MemberShipLevelWidget3 from "../../../components/widgets/MemberShipLevelWidget3";
import { setMemberProfile } from "../../../state/features/profile/memberProfileSlice";
import useRTK from "../../../hooks/useRTK";
import { Form, Formik } from "formik";
import { useAddMembershipLevelMutation } from "../../../api/membershipLevelAPI/membershipLevelAPI";
import { Link } from "react-router-dom";

const CreateIncoice = ({ level, closeDialog }) => {
    const { memberProfile: profile } = useSelector(memberProfileSelector);
    const { profile: authProfile } = useSelector(profileSelector);

    // ################# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############### RTK #####################
    const [
        addMemberShipLevel,
        { isLoading, isSuccess, isError, error, data: updatedData },
    ] = useAddMembershipLevelMutation();

    // ############### useEffect ################
    // => RTK action
    const rtkActions = (data) => {
        closeDialog();
        dispatch(setMemberProfile(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, updatedData, rtkActions);

    return (
        <>
            <Box sx={{ width: "100%" }}>
                <Box
                    sx={{
                        py:
                            profile.verificationStatus === "pending" ||
                            profile.verificationStatus === "requested"
                                ? 0
                                : 1,
                    }}
                >
                    {authProfile.isEligible === false ? (
                        <>
                            <Box className="warning-message">
                                <Typography
                                    sx={{ fontSize: 18, fontWeight: "bold" }}
                                >
                                    ⚠️ Attention: submit all required
                                    information ⚠️
                                </Typography>
                            </Box>
                            <Typography
                                sx={{ fontSize: 14, textAlign: "center", p: 4 }}
                            >
                                “Please make sure you submit all required
                                information and your profile is verified in
                                order to subscribe to our membership level's
                                packages.” If you have any further questions or
                                need assistance, feel free to ask! 😊
                            </Typography>
                            <Box>
                                <Link to={"/profile"}>
                                    <CustomSubmitButton1
                                        startIcon={<ManageAccounts />}
                                        endIcon={<ArrowRightAlt />}
                                    >
                                        Manage your profile details
                                    </CustomSubmitButton1>
                                </Link>
                            </Box>
                        </>
                    ) : (
                        <>
                            <Box
                                sx={{
                                    py: 0.5,
                                    width: "100%",
                                }}
                            >
                                <Box>
                                    <MemberShipLevelWidget3 level={level} />
                                </Box>
                                <Formik
                                    initialValues={{
                                        memberLevel: level.id,
                                    }}
                                    onSubmit={(payload) => {
                                        addMemberShipLevel(payload);
                                    }}
                                >
                                    {(formik) => (
                                        <Form>
                                            <CustomSubmitButton1
                                                startIcon={
                                                    <ReceiptLongOutlined color="success" />
                                                }
                                                endIcon={
                                                    <ArrowRightAltOutlined />
                                                }
                                                btnColor={`secondary`}
                                                variant={"contained"}
                                                onClick={formik.handleSubmit}
                                                loading={isLoading}
                                                sx={{ py: 1.5, fontSize: 16 }}
                                            >
                                                Generate now
                                            </CustomSubmitButton1>
                                        </Form>
                                    )}
                                </Formik>
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
        </>
    );
};

export default CreateIncoice;