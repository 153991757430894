import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { memberAttachmentEndpoints } from "./memberAttachmentEndpoints";
import { baseURL } from "../baseUrl/baseURL";
import { headers } from "../baseUrl/setHeaders";
import { profileAPI } from "../profileAPI/profileAPI";

export const memberAttachmentsAPI = createApi({
    reducerPath: "memberAttachmentsAPI",
    baseQuery: fetchBaseQuery({ baseUrl: baseURL, prepareHeaders: headers }),
    endpoints: (builder) => ({
        uploadAttachment: builder.mutation({
            query: (payload) => ({
                url:
                    payload.get("attachmentType") === "CIN"
                        ? `${memberAttachmentEndpoints.COI_CERT}`
                        : payload.get("attachmentType") === "license"
                        ? `${memberAttachmentEndpoints.BL_CERT}`
                        : payload.get("attachmentType") === "logo"
                        ? `${memberAttachmentEndpoints.LOGO_CERT}`
                        : payload.get("attachmentType") === "profile"
                        ? `${memberAttachmentEndpoints.CP_CERT}`
                        : payload.get("attachmentType") === "tin"
                        ? `${memberAttachmentEndpoints.TIN_CERT}`
                        : ``,
                method: `POST`,
                body: payload,
            }),
            async onQueryStarted(_, { queryFulfilled, dispatch }) {
                try {
                    await queryFulfilled;
                    dispatch(profileAPI.util.invalidateTags(["Profile"]));
                } catch (err) {}
            },
        }),
    }),
});

export const { useUploadAttachmentMutation } = memberAttachmentsAPI;
