import React from "react";
import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ErrorMessage, useField } from "formik";

const CustomInput = styled(TextField)({});

const CustomTextField = ({
    label,
    type,
    value,
    prepend,
    bBottom,
    optional,
    ...props
}) => {
    const [field, meta] = useField(props);
    return (
        <>
            <Box
                sx={{
                    marginTop: 2,
                    borderRadius: 1.5,
                    overflow: "hidden",
                    position: "relative",
                }}
            >
                <CustomInput
                    error={meta.touched && meta.error ? true : false}
                    label={label}
                    variant="filled"
                    fullWidth
                    type={type}
                    size="small"
                    placeholder={field.label}
                    {...field}
                    {...props}
                    focused={type === "date" || type === "time" ? true : false}
                    InputProps={{
                        startAdornment: prepend && (
                            <InputAdornment position="start">
                                {prepend}
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        borderBottom: `1px solid ${bBottom}`,
                    }}
                />
                <ErrorMessage
                    component="span"
                    name={field.name}
                    className="error-text"
                />
                {optional && (
                    <Typography
                        sx={{
                            fontSize: 9,
                            fontWeight: "bold",
                            color: "red",
                            position: "absolute",
                            top: 5,
                            right: 5,
                        }}
                    >
                        ** optional **
                    </Typography>
                )}
            </Box>
        </>
    );
};

export default CustomTextField;
