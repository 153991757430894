import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { Button, Grid, IconButton, styled, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { colors } from "../assets/utils/colors";
import NotificationWidget from "./widgets/NotificationWidget";
import { notificationsSelector } from "../state/features/selectors";
import { useSelector } from "react-redux";
import NoContent from "./NoContent";
import { isEmpty } from "lodash";

const DrawerHeader = styled(Box)(({ theme }) => ({
    ...theme.mixins.toolbar,
}));

const NotificationBar = ({ openNotBar, closeNotDrawer }) => {
    // ############### Redux States ###############
    const { notifications } = useSelector(notificationsSelector);
    // const notifications = [
    //     {
    //         id: 1,
    //         title: "Not title",
    //         body: "Not body",
    //         created_at: "2024-03-01",
    //     },
    //     {
    //         id: 2,
    //         title: "Not title",
    //         body: "Not body",
    //         created_at: "2024-03-01",
    //     },
    //     {
    //         id: 3,
    //         title: "Not title",
    //         body: "Not body",
    //         created_at: "2024-03-01",
    //     },
    // ];
    return (
        <React.Fragment key={1}>
            <Drawer
                anchor={"right"}
                open={openNotBar}
                sx={{ position: "relative" }}
            >
                <Box
                    sx={{
                        width: { md: 400, sm: 400, xs: "100vw" },
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                        height: "100vh",
                        position: "relative",
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-end",
                            p: 2,
                            height: "20vh",
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                        }}
                    >
                        {/* <DrawerHeader /> */}
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <IconButton
                                onClick={() => closeNotDrawer()}
                                color="error"
                                sx={{
                                    position: "absolute",
                                    left: 10,
                                    boxShadow: `1px 1px 10px 4px ${colors.bgColor2}`,
                                }}
                            >
                                <Close />
                            </IconButton>
                            <Typography
                                sx={{
                                    fontWeight: "bold",
                                    color: colors.primary,
                                }}
                            >
                                Latest Notifications
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            height: "70vh",
                            overflow: "auto",
                            position: "relative",
                        }}
                    >
                        <Grid container spacing={2} padding={2}>
                            {!isEmpty(notifications) ? (
                                <>
                                    {notifications.map((notification) => (
                                        <Grid
                                            item
                                            sm={12}
                                            xs={12}
                                            key={notification.id}
                                        >
                                            <NotificationWidget
                                                notification={notification}
                                            />
                                        </Grid>
                                    ))}
                                </>
                            ) : (
                                <>
                                    <Grid item sm={12} xs={12}>
                                        <NoContent
                                            height={300}
                                            message={
                                                "No Notification available"
                                            }
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Box>
                    {!isEmpty(notifications) && (
                        <Box
                            sx={{
                                height: "10vh",
                                display: "flex",
                                alignItems: "flex-end",
                                p: 1,
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor4})`,
                            }}
                        >
                            <Button
                                fullWidth
                                variant="outlined"
                                color="info"
                                size="large"
                                sx={{ mx: 1, fontWeight: "bold", fontSIze: 14 }}
                            >
                                Mark all read
                            </Button>
                            <Button
                                fullWidth
                                variant="outlined"
                                color="error"
                                size="large"
                                sx={{ mx: 1, fontWeight: "bold", fontSIze: 14 }}
                            >
                                Delete all
                            </Button>
                        </Box>
                    )}
                </Box>
            </Drawer>
        </React.Fragment>
    );
};
export default NotificationBar;
