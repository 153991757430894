import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { LocalPolice, PriceCheck } from "@mui/icons-material";
import { colors } from "../../assets/utils/colors";
import useCurrency from "../../hooks/useCurrency";
import GetCertificate from "../../pages/subscriptions/CRUD/GetCertificate";
import moment from "moment";
import { useSelector } from "react-redux";
import { licenseSelector } from "../../state/features/selectors";
import { filter } from "lodash";

const ActiveSubscriptionWidgetTwo = () => {
    const currency = useCurrency();
    // ############## Comp state ##################
    const [activeLicense, setActiveLicense] = useState(null);

    // ############# Redux state ################
    const { licenses } = useSelector(licenseSelector);

    // ############# FUNC #################
    // => Get active license
    const getActiveLicense = () => {
        let active = filter(licenses, { licenseStatus: "active" });
        if (active) {
            setActiveLicense(active[0]);
        }
    };

    // ############# useEffect #################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            getActiveLicense();
        }

        return () => {
            isSubscribed = false;
        };
    }, [licenses]);
    return (
        <>
            <Box
                sx={{
                    height: "100%",
                    width: "100%",
                    borderRadius: 5,
                    border: `2px solid ${colors.bgColor2}`,
                    overflow: "hidden",
                    boxShadow: `0 0 10px ${colors.bgColor2}`,
                    position: "relative",
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                    ":before": {
                        content: "''",
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        borderRadius: 5,
                        background: `linear-gradient(180deg, ${colors.primary}, ${colors.secondary})`,
                        transition: ".5s",
                        opacity: 0.1,
                        zIndex: 5,
                        borderTopRightRadius: 0,
                        borderBottomLeftRadius: 0,
                    },
                }}
            >
                <Grid
                    container
                    justifyContent={"space-between"}
                    sx={{
                        zIndex: 15,
                    }}
                >
                    <Grid item sm={12} xs={12}>
                        <Box
                            sx={{
                                p: 1,
                                // background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor4})`,
                            }}
                        >
                            <Typography
                                sx={{
                                    textAlign: "center",
                                    fontSize: 12,
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                    color: colors.secondary,
                                }}
                            >
                                Active license
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid
                    container
                    padding={2}
                    spacing={2}
                    justifyContent={"space-between"}
                >
                    <Grid item sm={12} xs={12}>
                        <Box
                            sx={{
                                height: 60,
                                width: 60,
                                bgcolor: "red",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: 2,
                                background: `linear-gradient(180deg, ${colors.bgColor4}, ${colors.bgColor2})`,
                            }}
                        >
                            <LocalPolice
                                sx={{
                                    fontSize: 40,
                                    color: colors.orange,
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Typography
                            sx={{
                                fontSize: 16,
                                fontWeight: "bold",
                                color: colors.primary,
                            }}
                        >
                            Platnum
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 15,
                                fontWeight: "bold",
                                px: 2,
                                py: 0.5,
                                color: colors.primary,
                            }}
                        >
                            <PriceCheck sx={{ mr: 1, fontSize: 16 }} />
                            {currency.formatCurrency("TZS", 50000000)}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Box
                            sx={{
                                py: 0.5,
                                borderRadius: 5,
                                borderTopRightRadius: 5,
                                borderBottomLeftRadius: 5,
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 11,
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                    textAlign: "center",
                                    color: "green",
                                }}
                            >
                                Subscribed
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 10,
                                    fontWeight: "bold",
                                    textAlign: "center",
                                }}
                            >
                                {moment(activeLicense?.startDate).format("LL")}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Box
                            sx={{
                                py: 0.5,
                                borderRadius: 5,
                                borderTopRightRadius: 5,
                                borderBottomLeftRadius: 5,
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 11,
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                    textAlign: "center",
                                    color: colors.red,
                                }}
                            >
                                Expiration
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 10,
                                    fontWeight: "bold",
                                    textAlign: "center",
                                }}
                            >
                                {moment(activeLicense?.endDate).format("LL")}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <GetCertificate license={activeLicense} />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default ActiveSubscriptionWidgetTwo;
