import React from "react";
import { Grid, Typography } from "@mui/material";
import { Formik } from "formik";
import {
    ArrowRightAltOutlined,
    PersonAddAlt1Outlined,
} from "@mui/icons-material";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { registerSelector } from "../../../state/features/selectors";
import { colors } from "../../../assets/utils/colors";
import CustomPasswordField from "../../../components/forms/CustomPasswordField";
import NextAndBackBtn from "../../../components/steppers/NextAndBackBtn";
import { setPassword } from "../../../state/features/register/registerSlice";
import CustomSubmitButton1 from "../../../components/forms/buttons/CustomSubmitButton1";

// ############# Validation Schema ##############
const validationSchema = Yup.object({
    password: Yup.string().required().min(8),
    confirm_password: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("confirm password is required field")
        .min(8, "Password must be at least 8 characters"),
});

const PasswordConfimation = ({ steps, activeStep, handleNext, handleBack }) => {
    // ############## CONST #################
    const dispatch = useDispatch();

    // ############## Redux state ################
    const { password } = useSelector(registerSelector);

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    password: password,
                }}
                validationSchema={validationSchema}
                onSubmit={(payload) => {
                    dispatch(setPassword(payload));
                    handleNext();
                }}
            >
                {(formik) => (
                    <Grid
                        container
                        justifyContent={"center"}
                        padding={2}
                        py={{ md: 1.5 }}
                        columnSpacing={4}
                    >
                        <Grid item sm={11} xs={12}>
                            <Typography sx={{ fontSize: 14 }}>
                                Please provide the password that you will be
                                used to login to your account
                            </Typography>
                        </Grid>
                        <Grid item sm={11} xs={12}>
                            <CustomPasswordField
                                id="password"
                                className="form-input"
                                label={"Password"}
                                type="password"
                                name="password"
                                bBottom={colors.secondary}
                            />
                            <Typography
                                sx={{
                                    marign: 0,
                                    padding: 0,
                                    fontSize: 12,
                                    color: colors.warning,
                                }}
                            >
                                Note :- password should contain atleast 8
                                characters
                            </Typography>
                        </Grid>
                        <Grid item sm={11} xs={12}>
                            <CustomPasswordField
                                id="confirm_password"
                                className="form-input"
                                label={"Confirm password"}
                                type="password"
                                name="confirm_password"
                                bBottom={colors.secondary}
                            />
                        </Grid>

                        {/* ######### sign up btn ######### */}
                        <Grid
                            item
                            sm={11}
                            xs={12}
                            sx={{
                                marginTop: 2.5,
                                textAlign: "center",
                            }}
                        >
                            <CustomSubmitButton1
                                startIcon={<PersonAddAlt1Outlined />}
                                endIcon={<ArrowRightAltOutlined />}
                                variant={"contained"}
                                onClick={() => {
                                    formik.handleSubmit();
                                }}
                            >
                                Confirm login password
                            </CustomSubmitButton1>
                        </Grid>

                        {/* ################ ACTION BUTONS ###################### */}
                        <Grid
                            item
                            sm={11}
                            xs={12}
                            sx={{
                                marginTop: 2.5,
                                textAlign: "center",
                            }}
                        >
                            <NextAndBackBtn
                                steps={steps}
                                activeStep={activeStep}
                                handleBack={handleBack}
                                action={handleNext}
                                disabled={password ? false : true}
                            />
                        </Grid>
                    </Grid>
                )}
            </Formik>
        </>
    );
};

export default PasswordConfimation;
