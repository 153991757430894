import React, { useState } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import { ArrowRight, Receipt, SecurityOutlined } from "@mui/icons-material";
import CustomButton2 from "../../../components/forms/buttons/CustomButton2";
import ReportDialog from "../../../components/dialogs/ReportDialog";
import { PDFDownloadLink } from "@react-pdf/renderer";
import CertificatePDF from "../../reports/CertificatePDF";
import { colors } from "../../../assets/utils/colors";

const GetCertificate = ({ fullWidth, license }) => {
    // ############## Comp State ##############
    const [openDialog, setOpenDialog] = useState(false);

    // ############# Func ###############
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <>
            <Box>
                {/* ################ Dialogy ################# */}
                <ReportDialog
                    head={"Certificate generator"}
                    customWidth={"md"}
                    openDialog={openDialog}
                    handleDialogClose={handleCloseDialog}
                >
                    <CertificatePDF license={license} />
                </ReportDialog>
                {/* <PDFDownloadLink
                    document={
                        <InvoicePDF
                            bankAcc={bankAcc}
                            invoice={invoice}
                            profile={profile}
                        />
                    }
                    fileName="invoice.pdf"
                >
                    {({ blob, url, loading, error }) => (
                        <CustomButton2
                            loading={loading}
                            size={"large"}
                            btColor="warning"
                            fullWidth={fullWidth && fullWidth}
                            startIcon={<Receipt />}
                            endIcon={<ArrowRight />}
                            title={"Invoice PDF"}
                        />
                    )}
                </PDFDownloadLink> */}
                {/* <CustomButton2
                    size={"large"}
                    btColor="warning"
                    fullWidth={fullWidth && fullWidth}
                    action={handleOpenDialog}
                    startIcon={<Receipt />}
                    endIcon={<ArrowRight />}
                    title={"Invoice PDF"}
                /> */}
                <Tooltip
                    title="Get certificate"
                    arrow
                    sx={{
                        zIndex: 999,
                    }}
                >
                    <IconButton
                        color="info"
                        sx={{
                            fontSize: 10,
                            zIndex: 999,
                            width: "100%",
                            fontWeight: "bold",
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor5})`,
                        }}
                        onClick={handleOpenDialog}
                    >
                        <SecurityOutlined sx={{ mr: 1 }} />
                        Subscription cerificate
                    </IconButton>
                </Tooltip>
            </Box>
        </>
    );
};

export default GetCertificate;
