import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../baseUrl/baseURL";
import { headers } from "../baseUrl/setHeaders";
import { invoiceEndpoints } from "./invoiceEndpoints";

export const invoiceAPI = createApi({
    reducerPath: "invoiceAPI",
    baseQuery: fetchBaseQuery({ baseUrl: baseURL, prepareHeaders: headers }),
    tagTypes: ["Invoices", "Invoice"],
    endpoints: (builder) => ({
        // GET Invoices
        getInvoices: builder.query({
            query: (payload) => ({
                url: `${invoiceEndpoints.GET_INVOICES}/${payload}`,
            }),
            providesTags: ["Invoices"],
        }),

        // GET single invoice
        getSingleInvoice: builder.query({
            query: (payload) => ({
                url: `${invoiceEndpoints.GET_SINGLE_INVOICE}/${payload}`,
            }),
            providesTags: ["Invoice"],
        }),

        // => PAY Invoice
        payInvoice: builder.mutation({
            query: (payload) => ({
                url: `${invoiceEndpoints.PAY_INVOICE}/${payload.get(
                    "invoice_id"
                )}`,
                method: `POST`,
                body: payload,
            }),
            invalidatesTags: ["Invoices", "Invoice"],
        }),
    }),
});

export const {
    useGetInvoicesQuery,
    usePayInvoiceMutation,
    useGetSingleInvoiceQuery,

    // => Lazy
    useLazyGetInvoicesQuery,
} = invoiceAPI;
