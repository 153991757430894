import { createSlice } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const initialState = {
    user: {},
    authToken: "",
    isAuthenticated: false,
    authorizationType: "",
};

// !########## SLICE #############
const authSlice = createSlice({
    name: "authSlice",
    initialState,
    reducers: {
        setAuthUser: (state, action) => {
            let user = action.payload.user;
            let token = action.payload.token;
            state.user = user;
            state.authToken = token;
            state.isAuthenticated = true;
            state.authorizationType = user?.organization_type?.name;
        },
        setUpdatedAuthUser: (state, action) => {
            let user = action.payload;
            state.user = user;
            cookies.set("user", user);
        },
        removeAuthUser: (state) => {
            state.user = {};
            state.authToken = "";
            state.isAuthenticated = false;
            state.authorizationType = "";
        },
    },
});

export const { setAuthUser, removeAuthUser, setUpdatedAuthUser } =
    authSlice.actions;
export default authSlice.reducer;
