import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Infobox1 from "./infoboxes/Infobox1";
import { colors } from "../assets/utils/colors";
import { useSelector } from "react-redux";
import { certificateSelector } from "../state/features/selectors";
import { VerifiedOutlined } from "@mui/icons-material";
import moment from "moment";

const CertificateVerify = () => {
    // ############## Redux state ###################
    const { certificate } = useSelector(certificateSelector);

    const companyData = [
        {
            id: 1,
            title: "Company name",
            value: certificate?.member.companyName,
            width: 6,
        },
        {
            id: 2,
            title: "Membership categorys",
            value: certificate?.member.memberShipCategory,
            width: 6,
        },
        {
            id: 3,
            title: "Establishement year",
            value: certificate?.member.establishmentYear,
            width: 6,
        },
        {
            id: 4,
            title: "Verification status",
            value: certificate?.member.verificationStatus,
            width: 6,
        },
    ];

    const certificateData = [
        {
            id: 1,
            title: "Certificate number",
            value: certificate?.licenseNumber,
            width: 6,
        },
        {
            id: 2,
            title: "Certificate status",
            value: certificate?.licenseStatus,
            width: 6,
        },
        {
            id: 3,
            title: "Ensured on",
            value: moment(certificate?.startDate).format("LL"),
            width: 6,
        },
        {
            id: 4,
            title: "Expired on",
            value: moment(certificate?.endDate).format("LL"),
            width: 6,
        },
    ];

    return (
        <>
            <Grid container justifyContent={"center"}>
                <Grid item sm={12} xs={12}>
                    <Box
                        sx={{
                            borderRadius: 2,
                            overflow: "hidden",
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                        }}
                    >
                        <Box
                            sx={{
                                p: 2,
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                            }}
                        >
                            <Typography
                                sx={{
                                    textTransform: "uppercase",
                                    textAlign: "center",
                                    fontSIze: 20,
                                    fontWeight: "bold",
                                    color: colors.primary,
                                }}
                            >
                                Certificate verification
                            </Typography>
                        </Box>
                        <Box>
                            <Grid container padding={2} spacing={2}>
                                <Grid item sm={12}>
                                    <Typography
                                        sx={{
                                            background: `linear-gradient(rgba(23, 148, 202, .8), rgb(49, 180, 76, .8))`,
                                            backgroundClip: "text",
                                            textFillColor: "transparent",
                                        }}
                                    >
                                        Member details
                                    </Typography>

                                    <Grid container>
                                        {companyData.map((data, index) => (
                                            <Grid
                                                item
                                                sm={data.width}
                                                xs={6}
                                                key={index}
                                            >
                                                <Infobox1 data={data} />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                                <Grid item sm={12}>
                                    <Typography
                                        sx={{
                                            background: `linear-gradient(rgba(23, 148, 202, .8), rgb(49, 180, 76, .8))`,
                                            backgroundClip: "text",
                                            textFillColor: "transparent",
                                        }}
                                    >
                                        Certificate details
                                    </Typography>

                                    <Box
                                        sx={{
                                            p: 2,
                                            position: "relative",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 22,
                                                textAlign: "center",
                                                fontWeight: "bold",
                                                color: colors.primary,
                                            }}
                                        >
                                            {certificate?.licenseNumber}
                                        </Typography>
                                        <VerifiedOutlined
                                            sx={{
                                                position: "absolute",
                                                right: 10,
                                                color: colors.secondary,
                                            }}
                                        />
                                    </Box>
                                    <Grid container>
                                        {certificateData.map((data, index) => (
                                            <Grid
                                                item
                                                sm={data.width}
                                                xs={6}
                                                key={index}
                                            >
                                                <Infobox1 data={data} />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default CertificateVerify;
