import React from "react";
import CustomCard from "../../../components/cards/CustomCard";
import { Grid } from "@mui/material";
import Infobox1 from "../../../components/infoboxes/Infobox1";
import AddContactPerson from "../CRUD/AddContactPerson";
import NoContent from "../../../components/NoContent";
import { isEmpty } from "lodash";

const ContactPerson = ({ profile }) => {
    const contactPerson = profile.contactPerson;

    const ContactPerson = [
        {
            id: 1,
            title: "Name of contact person",
            value:`${contactPerson?.firstName}  ${contactPerson?.middleName || ""}  ${contactPerson?.lastName}`,
            width: 6,
        },
        {
            id: 2,
            title: "Position",
            value: contactPerson?.position,
            width: 6,
        },
        {
            id: 3,
            title: "Telephone",
            value: contactPerson?.phoneNumber,
            width: 6,
        },
        {
            id: 4,
            title: "Email address",
            value: contactPerson?.email,
            width: 6,
        },
    ];
    return (
        <div>
            <CustomCard
                title={"Contact person"}
                height={"100%"}
                action={<AddContactPerson contactPerson={contactPerson} />}
            >
                {isEmpty(contactPerson) ? (
                    <NoContent
                        height={"30vh"}
                        message={"Company contact person not updated"}
                    />
                ) : (
                    <Grid container padding={1} sx={{ height: "100%" }}>
                        {ContactPerson?.map((contact, index) => (
                            <Grid item sm={contact.width} xs={6} key={index}>
                                <Infobox1 data={contact} />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </CustomCard>
        </div>
    );
};

export default ContactPerson;
