import { Box } from "@mui/material";
import { QRCodeCanvas } from "qrcode.react";
import React from "react";

function CertificateQrcode({ license }) {
    return (
        <Box sx={{ display: "none" }}>
            <QRCodeCanvas
                id="certQr"
                value={`https://tcciamembership.or.tz/certificates/verify/${license?.licenseNumber}`}
            />
        </Box>
    );
}

export default CertificateQrcode;
