import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import MiniMemberShipLevelWidget from "../widgets/MiniMemberShipLevelWidget";
import Infobox1 from "../infoboxes/Infobox1";
import NoContent from "../NoContent";
import CustomPagination from "../paginations/CustomPagination";
import { isEmpty, size, take } from "lodash";
import { paginator } from "../../helpers/paginationHelper";
import { useSelector } from "react-redux";
import {
    adminSelector,
    membershipLevelSelector,
} from "../../state/features/selectors";

const InvoiceSidebar = () => {
    // ############## Comp state ##################
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(1);
    // ############## FUNC ####################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };

    // ############# Redux state ##################
    const { membershipLevels } = useSelector(membershipLevelSelector);
    const { bankDetails: bankAccounts } = useSelector(adminSelector);

    // => Get single bank acc
    let bankAcc = take(bankAccounts, 1);
    bankAcc = bankAcc && bankAcc[0];

    const bankDetails = [
        {
            id: 1,
            title: "Name of Bank",
            value: bankAcc?.bankName,
            width: 12,
        },
        {
            id: 2,
            title: "Account name",
            value: bankAcc?.accountName,
            width: 12,
        },
        {
            id: 3,
            title: "Bank address",
            value: bankAcc?.bankAddress,
            width: 12,
        },
        {
            id: 4,
            title: "Swift code",
            value: bankAcc?.swiftCode,
            width: 12,
        },
        {
            id: 5,
            title: "Account number",
            value: bankAcc?.accountNumber,
            width: 12,
        },
    ];

    // => Pagination data
    const paginatedMemberShip = paginator(membershipLevels, page, perPage);

    return (
        <div>
            <Grid container rowGap={2}>
                <Grid item sm={12} xs={12}>
                    <Typography
                        sx={{
                            fontSize: {
                                md: 13,
                                xs: 14,
                            },
                            mb: 2,
                            fontWeight: "bold",
                            background: `linear-gradient(rgba(23, 148, 202, .8), rgb(49, 180, 76, .8))`,
                            backgroundClip: "text",
                            textFillColor: "transparent",
                        }}
                    >
                        Membership levels ({size(membershipLevels)})
                    </Typography>
                    <Box>
                        <CustomPagination
                            height={"100%"}
                            borderBottom
                            handlePageChange={handlePageChange}
                            handlePerPageChage={handlePerPageChage}
                            data={paginatedMemberShip}
                        >
                            {isEmpty(membershipLevels) ? (
                                <>
                                    <NoContent
                                        height={200}
                                        message={"No membership levels"}
                                    />
                                </>
                            ) : (
                                <Grid container spacing={1}>
                                    {paginatedMemberShip.data.map((level) => (
                                        <Grid
                                            item
                                            sm={12}
                                            xs={12}
                                            key={level.id}
                                        >
                                            <MiniMemberShipLevelWidget
                                                level={level}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </CustomPagination>
                    </Box>
                </Grid>
                <Grid item sm={12} xs={12}>
                    <Typography
                        sx={{
                            fontSize: {
                                md: 13,
                                xs: 14,
                            },
                            mb: 1,
                            fontWeight: "bold",
                            background: `linear-gradient(rgba(23, 148, 202, .8), rgb(49, 180, 76, .8))`,
                            backgroundClip: "text",
                            textFillColor: "transparent",
                        }}
                    >
                        Bank acc for payment
                    </Typography>
                    <Box>
                        {isEmpty(bankAccounts) ? (
                            <>
                                <NoContent
                                    height={250}
                                    message={"No bank details available"}
                                />
                            </>
                        ) : (
                            <Grid container>
                                {bankDetails.map((contact, index) => (
                                    <Grid
                                        item
                                        sm={contact.width}
                                        xs={12}
                                        key={index}
                                    >
                                        <Infobox1 data={contact} />
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default InvoiceSidebar;
