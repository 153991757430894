import { Box, Typography } from "@mui/material";
import React from "react";
import { colors } from "../../assets/utils/colors";

const Infobox3 = ({ title, value }) => {
    return (
        <div>
            <Box sx={{ mb: 1 }}>
                <Typography
                    sx={{
                        fontSize: 12,
                        fontWeight: "bold",
                        color: colors.primary,
                    }}
                >
                    {title}
                </Typography>
                <Typography
                    sx={{
                        fontSize: {
                            md: 16,
                            sm: 16,
                            xs: 14,
                        },
                        fontWeight: "bold",
                        opacity: 0.8,
                    }}
                >
                    {value}
                </Typography>
            </Box>
        </div>
    );
};

export default Infobox3;
