import React, { useEffect, useState } from "react";
import CustomCard from "../../../components/cards/CustomCard";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { colors } from "../../../assets/utils/colors";
import RequredDetails from "./RequredDetails";
import RequestVerification from "../CRUD/RequestVerification";
import ProfileStatus from "./ProfileStatus";
import ProfileStatus2 from "./ProfileStatus2";
import { profileSelector } from "../../../state/features/selectors";
import { useSelector } from "react-redux";

function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress
                variant="determinate"
                {...props}
                size={160}
                color="secondary"
                sx={{ position: "", height: 200, width: 200 }}
            />
            <CircularProgress
                variant="determinate"
                {...props}
                size={160}
                value={100}
                sx={{
                    position: "absolute",
                    height: 200,
                    width: 200,
                    opacity: 0.2,
                }}
                color="primary"
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    sx={{
                        fontSize: 40,
                        fontWeight: "bold",
                        color: colors.secondary,
                    }}
                >
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
};

const SubmitProfileDetails = ({ profile }) => {
    const progressComplete = profile?.progressComplete;
    const [progress, setProgress] = useState(0);
    const { profile: authProfile } = useSelector(profileSelector);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            setProgress(progressComplete);
        }
        return () => {
            isSubscribed = false;
        };
    }, [progressComplete]);

    return (
        <>
            <CustomCard>
                <Grid container padding={2}>
                    <Grid item sm={3} xs={12}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                p: 1,
                                borderRadius: 5,
                                background: `linear-gradient(360deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                            }}
                        >
                            <Box>
                                <CircularProgressWithLabel value={progress} />
                            </Box>
                            <Box>
                                <ProfileStatus2 />
                            </Box>
                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        fontWeight: "bold",
                                        color: colors.primary,
                                        my: 1.5,
                                    }}
                                >
                                    Required details completion
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item sm={9}>
                        <Box>
                            <ProfileStatus />
                        </Box>
                        <Box>
                            <RequredDetails profile={profile} />
                        </Box>
                        {authProfile.isEligible === true && (
                            <Box>
                                <RequestVerification />
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </CustomCard>
        </>
    );
};

export default SubmitProfileDetails;
