import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import { Box, List } from "@mui/material";
import { Window } from "@mui/icons-material";
// import CustomListItem from "./MUIcustoms/CustomListItem";
import { colors } from "../hooks/useColor";

// ############### CONSTANTS #################
const drawerWidth = 250;
const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});
const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

// ############### STYLED DESKTOP DRAWER #################
const DrawerDesktop = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    "& .MuiPaper-root": {
        backgroundColor: colors.primary,
    },
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

// ################# DrawerContent ################
const DrawerContent = styled(Box)(({ theme }) => ({
    bgcolor: colors.bgColor3,
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 15,
    paddingRight: 15,
    transition: ".3s all ease-in-out",
    "& .list-title": {
        fontWeight: "bold",
        fontSize: 12,
        padding: 5,
        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
    },
    "& .list-icon": {
        fontSize: 18,
        color: colors.secondary,
    },
}));

//! #################### MAIN FUNC ######################
const HomeSideBar = ({ handleDrawerToggle, mobileOpen }) => {
    // ############ CONST ###################
    const [open] = React.useState(false);

    // => For content to be below app bar
    const AppBarHeight = styled("div")(({ theme }) => ({
        ...theme.mixins.toolbar,
    }));
    const { window } = "props";
    const container =
        window !== undefined ? () => window().document.body : undefined;

    // ################# DRAWER CONTENT ################
    const navLinks = [
        {
            id: 1,
            text: "Home",
            icon: <Window className="list-icon" />,
            url: "/",
        },
        {
            id: 2,
            text: "Link 1",
            icon: <Window className="list-icon" />,
            url: "/",
        },
        {
            id: 3,
            text: "Link 2",
            icon: <Window className="list-icon" />,
            url: "/",
        },
    ];

    // ##################### Drawer #################
    const drawer = (
        <React.Fragment>
            <DrawerContent>
                <Box sx={{ mb: 5 }}>
                    <Box>
                        {/* <List sx={{ p: 0 }}>
                            {navLinks?.map((item) => (
                                <CustomListItem
                                    key={item.id}
                                    text={item.text}
                                    icon={item.icon}
                                    url={item.url}
                                />
                            ))}
                        </List> */}
                    </Box>
                </Box>
            </DrawerContent>
        </React.Fragment>
    );
    return (
        <>
            {/* ##################### mobile ################# */}
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: "block", sm: "none" },
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: "80%", // Change the width of drawer in mobile
                        backgroundColor: colors.primary,
                    },
                }}
            >
                <Toolbar />
                {drawer}
            </Drawer>

            {/* ##################### desktop ################ */}
            <DrawerDesktop
                variant="temporary"
                open={open}
                sx={{
                    display: { xs: "none", sm: "block" },
                }}
            >
                <AppBarHeight />
                <Divider />
                {drawer}
            </DrawerDesktop>
        </>
    );
};

export default HomeSideBar;
