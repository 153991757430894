import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { ArrowRight, Payment } from "@mui/icons-material";
import CustomDialog from "../../../components/dialogs/CustomDialog";
import CustomButton2 from "../../../components/forms/buttons/CustomButton2";
import PayInvoiceForm from "./PayInvoiceForm";

const PayInvoice = ({ fullWidth, invoice }) => {
    // ############## Comp State ##############
    const [openDialog, setOpenDialog] = useState(false);

    // ############# Func ###############
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <>
            <Box>
                {/* ################ Dialogy ################# */}
                <CustomDialog
                    openDialog={openDialog}
                    closeDialog={handleCloseDialog}
                    head={"Invoice payment (upload payment slip)"}
                    // customWidth={"md"}
                >
                    <Box className="warning-message">
                        <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                            ⚠️ Attention: Payment Slip Submission ⚠️
                        </Typography>
                    </Box>
                    <Box sx={{ py: 5 }}>
                        <Typography sx={{ fontSize: 15, textAlign: "center" }}>
                            Please ensure that you already pay for membership
                            level from invoice and the payment slip you about to
                            upload is matching the invoice payment. After upload
                            payment slip you cant change invoice membership
                            level🌟
                        </Typography>
                    </Box>
                    <PayInvoiceForm
                        closeDialog={handleCloseDialog}
                        invoice={invoice}
                    />
                </CustomDialog>
                <CustomButton2
                    size={"large"}
                    btColor="secondary"
                    fullWidth={fullWidth && fullWidth}
                    startIcon={<Payment />}
                    endIcon={<ArrowRight />}
                    action={handleOpenDialog}
                    title={"Direct bank payment"}
                />
            </Box>
        </>
    );
};

export default PayInvoice;
