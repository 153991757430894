import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { ArrowRight, Payment, VerifiedOutlined } from "@mui/icons-material";
import CustomDialog from "../../components/dialogs/CustomDialog";
import CustomButton2 from "../../components/forms/buttons/CustomButton2";
import CreateIncoice from "./CRUD/CreateIncoice";
import { Link, useNavigate } from "react-router-dom";
import { memberProfileSelector } from "../../state/features/selectors";
import { useSelector } from "react-redux";

const ViewInvoice = ({ fullWidth, level }) => {
    // ############## CONST ####################
    const navigate = useNavigate();

    // ############## Comp State ##############
    const [openDialog, setOpenDialog] = useState(false);

    // ########### Redux state #################
    const { memberProfile: profile } = useSelector(memberProfileSelector);

    // ############# Func ###############
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <>
            <Box>
                {/* ################ Dialogy ################# */}
                <CustomDialog
                    openDialog={openDialog}
                    closeDialog={handleCloseDialog}
                    head={"Invoice creation"}
                    // customWidth={"md"}
                >
                    <CreateIncoice
                        level={level}
                        closeDialog={handleCloseDialog}
                    />
                </CustomDialog>
                <Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                        className="success-message"
                    >
                        <VerifiedOutlined />
                        <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
                            “Your current subscribed to this membership level”
                        </Typography>
                    </Box>
                    <Link to={`/invoices/${profile.currInvoice.id}`}>
                        <CustomButton2
                            size={"large"}
                            btColor="secondary"
                            fullWidth={fullWidth && fullWidth}
                            startIcon={<Payment />}
                            endIcon={<ArrowRight />}
                            title={"View invoice"}
                        />
                    </Link>
                </Box>
            </Box>
        </>
    );
};

export default ViewInvoice;
