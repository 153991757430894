import React from "react";
import { Grid } from "@mui/material";
import PageLayout from "../layouts/PageLayout";
import AnnouncementWidget from "../../components/widgets/AnnouncementWidget";
import { adminSelector } from "../../state/features/selectors";
import { useSelector } from "react-redux";
import NoContent from "../../components/NoContent";
import { isEmpty } from "lodash";

const AnnouncementPage = () => {
    // ############# Redux state ##################
    const { announcements } = useSelector(adminSelector);

    return (
        <>
            <PageLayout head={"Announcements"} noSidebar>
                {isEmpty(announcements) ? (
                    <>
                        <NoContent
                            height={300}
                            message={"No announcement at the moment"}
                        />
                    </>
                ) : (
                    <Grid container spacing={4}>
                        {announcements.map((announcement, index) => (
                            <Grid item sm={6} xs={12} key={index}>
                                <AnnouncementWidget
                                    announcement={announcement}
                                />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </PageLayout>
        </>
    );
};

export default AnnouncementPage;
