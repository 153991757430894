import React, { useState } from "react";
import {
    ArrowRightAltOutlined,
    ContactEmergencyOutlined,
    Edit,
} from "@mui/icons-material";
import { Button, Grid, MenuItem } from "@mui/material";
import CustomCrudDialog from "../../../components/dialogs/CustomCrudDialog";
import { useDispatch, useSelector } from "react-redux";
import useRTK from "../../../hooks/useRTK";
import { setMemberProfile } from "../../../state/features/profile/memberProfileSlice";
import CustomSubmitButton1 from "../../../components/forms/buttons/CustomSubmitButton1";
import CustomTextField from "../../../components/forms/CustomTextField";
import { useUpdateMemberDetailsMutation } from "../../../api/memberAPI/memberAPI";
import * as Yup from "yup";
import { isEmpty } from "lodash";
import { Form, Formik } from "formik";
import CustomSelect from "../../../components/forms/CustomSelect";
import { colors } from "../../../assets/utils/colors";
import CustomMultlineTextField from "../../../components/forms/CustomMultlineTextField";
import { adminSelector } from "../../../state/features/selectors";

// ################## VALIDATION ####################
const memberValidationSchema = Yup.object().shape({
    companyName: Yup.string().required("Company name is required field"),
    companyShortName: Yup.string().nullable(),
    establishmentYear: Yup.string().required("establishment year is required"),
    description: Yup.string().required(),
    companySector: Yup.string().required("company industry is required field"),
});

const UpdateGeneralDetails = ({ memberCompany }) => {
    // ################# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############## Comp State ##############
    const [openDialog, setOpenDialog] = useState(false);

    // ############## Redux state ################
    const { sectors } = useSelector(adminSelector);

    // ############# Func ###############
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const getYears = () => {
        const start = 1950;
        const end = new Date().getFullYear();
        let yearCount = [];
        for (let i = start; i <= end; i++) {
            yearCount.push(i);
        }
        return yearCount;
    };

    // ############## RTK ##################
    const [
        updateMemberDetails,
        {
            isLoading: updateLoading,
            isSuccess: updateIsSuccess,
            isError: updateIsError,
            error: updateError,
            data: updatedData,
        },
    ] = useUpdateMemberDetailsMutation();

    // ############### useEffect ################
    // => RTK action
    const rtkActions = (data) => {
        handleCloseDialog();
        dispatch(setMemberProfile(data));
    };
    RTK.useRTKResponse(
        updateIsSuccess,
        updateIsError,
        updateError,
        updatedData,
        rtkActions
    );

    return (
        <>
            <CustomCrudDialog
                openDialog={openDialog}
                closeDialog={handleCloseDialog}
                head={"Company details update"}
            >
                <Formik
                    enableReinitialize
                    initialValues={{
                        companyName: !isEmpty(memberCompany.companyName)
                            ? memberCompany.companyName
                            : "",
                        companyShortName: !isEmpty(
                            memberCompany.companyShortName
                        )
                            ? memberCompany.companyShortName
                            : "",
                        establishmentYear: memberCompany.establishmentYear
                            ? memberCompany.establishmentYear
                            : "",
                        description: !isEmpty(memberCompany.description)
                            ? memberCompany.description
                            : "",
                        companySector: !isEmpty(memberCompany.companySector)
                            ? memberCompany.companySector.id
                            : "",
                    }}
                    validationSchema={memberValidationSchema}
                    onSubmit={(payload) => {
                        updateMemberDetails(payload);
                    }}
                >
                    {(formik) => (
                        <Form>
                            <Grid
                                container
                                justifyContent={"center"}
                                columnSpacing={2}
                            >
                                <Grid item sm={6} xs={12}>
                                    <CustomTextField
                                        type="text"
                                        name="companyName"
                                        label={"Company name"}
                                        bBottom={colors.secondary}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <CustomTextField
                                        type="text"
                                        name="companyShortName"
                                        label={"Short name"}
                                        optional
                                        bBottom={colors.secondary}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={6}>
                                    <CustomSelect
                                        label={"Establishment year"}
                                        formik={formik}
                                        bBottom={colors.secondary}
                                        name="establishmentYear"
                                    >
                                        {getYears()?.map((year) => (
                                            <MenuItem value={year} key={year}>
                                                {year}
                                            </MenuItem>
                                        ))}
                                    </CustomSelect>
                                </Grid>
                                <Grid item sm={6} xs={6}>
                                    <CustomSelect
                                        label={"Sector | Industry type"}
                                        formik={formik}
                                        bBottom={colors.secondary}
                                        name="companySector"
                                    >
                                        {sectors?.map((sector) => (
                                            <MenuItem
                                                value={sector.id}
                                                key={sector.id}
                                            >
                                                {sector.sectorName}
                                            </MenuItem>
                                        ))}
                                    </CustomSelect>
                                </Grid>
                                <Grid item sm={12} xs={12}>
                                    <CustomMultlineTextField
                                        type="text"
                                        name="description"
                                        rows={3}
                                        label={"Company description"}
                                        bBottom={colors.secondary}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <CustomSubmitButton1
                                    loading={updateLoading}
                                    btnColor={"primary"}
                                    startIcon={<ContactEmergencyOutlined />}
                                    endIcon={<ArrowRightAltOutlined />}
                                    onClick={formik.handleSubmit}
                                >
                                    Update company details
                                </CustomSubmitButton1>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </CustomCrudDialog>
            <Button
                size="small"
                startIcon={<Edit />}
                variant="outlined"
                sx={{
                    fontSize: 12,
                    fontWeight: "bold",
                    color: "#fff",
                    backgroundImage: `linear-gradient(rgba(23, 148, 202, .5), rgba(23, 148, 202, .5)), radial-gradient(circle at left top, rgb(1, 110, 218), rgb(49, 180, 76, .5))`,
                }}
                onClick={() => {
                    handleOpenDialog();
                }}
            >
                Update
            </Button>
        </>
    );
};

export default UpdateGeneralDetails;
