// => Add contact person
const ADD_DIRECTOR = "/directors";

// => Update contact person
const UPDATE_DIRECTOR = "/directors";

export const directorEndpoints = {
    ADD_DIRECTOR,
    UPDATE_DIRECTOR,
};
