import React from "react";
import { Box, styled, Typography } from "@mui/material";
import { colors } from "../../assets/utils/colors";

const Card = styled(Box)({
    borderRadius: 15,
    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
    border: `5px solid ${colors.bgColor3}`,
    overflow: "hidden",
});

const CardHeader = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 15,
    paddingRight: 15,
    position: "relative !important",
    ".title": {
        fontSize: 14,
        color: colors.primary,
        fontWeight: "bold",
        "::before": {
            content: "''",
            width: 90,
            height: 1,
            background: colors.primary,
            position: "absolute",
            bottom: 5,
        },
    },
});

const CardBody = styled(Box)({});

const CustomCardTwo = ({ action, title, height, children }) => {
    return (
        <React.Fragment>
            <Card sx={{ borderRadius: 5, height: "100%" }}>
                {title && (
                    <CardHeader>
                        <Typography className="title" noWrap>
                            {title}
                        </Typography>
                        <Box>{action}</Box>
                    </CardHeader>
                )}
                <CardBody sx={{ height: height ? height : "100%", p: 2 }}>
                    {children}
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default CustomCardTwo;
