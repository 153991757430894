import { Box, LinearProgress } from '@mui/material';
import React from 'react'

const DialogLinerProgress = ({ isLoading }) => {
    return (
        <>
            <Box
                sx={{
                    position: "absolute",
                    top: 0,
                    left: 20,
                    right: 20,
                }}
            >
                {isLoading && (
                    <LinearProgress
                        sx={{
                            height: 10,
                            borderRadius: 15,
                        }}
                    />
                )}
            </Box>
        </>
    );
};

export default DialogLinerProgress