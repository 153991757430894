import React, { useState } from "react";
import { Box } from "@mui/material";
import { ArrowRight, Payment } from "@mui/icons-material";
import CustomDialog from "../../components/dialogs/CustomDialog";
import CustomButton2 from "../../components/forms/buttons/CustomButton2";
import CreateIncoice from "./CRUD/CreateIncoice";

const GenerateInvoice = ({ fullWidth, level }) => {
    // ############## Comp State ##############
    const [openDialog, setOpenDialog] = useState(false);

    // ############# Func ###############
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <>
            <Box>
                {/* ################ Dialogy ################# */}
                <CustomDialog
                    openDialog={openDialog}
                    closeDialog={handleCloseDialog}
                    head={"Generate invoice"}
                    // customWidth={"md"}
                >
                    <CreateIncoice
                        level={level}
                        closeDialog={handleCloseDialog}
                    />
                </CustomDialog>
                <CustomButton2
                    size={"large"}
                    btColor="secondary"
                    fullWidth={fullWidth && fullWidth}
                    startIcon={<Payment />}
                    endIcon={<ArrowRight />}
                    action={handleOpenDialog}
                    title={"Generate invoice"}
                />
            </Box>
        </>
    );
};

export default GenerateInvoice;
