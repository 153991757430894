import React from "react";
import { Box, styled } from "@mui/material";
import DataPagnation from "./DataPagnation";
import { colors } from "../../assets/utils/colors";

// ############# CARD ###############
const Card = styled(Box)({
    borderRadius: 15,
    background: colors.bgColor2,
    border: `1px solid ${colors.bgColor4}`,
});

// ############# CARD BODY ###############
const CardBody = styled(Box)({
    position: "relative",
    background: colors.bgColor1,
});

// ############# CARD FOOTER ###############
const CardFooter = styled(Box)(({ theme }) => ({}));

const CustomPagination = ({
    data,
    height,
    handlePageChange,
    handlePerPageChage,
    children,
}) => {
    const currentPage = data?.page;
    const handleNext = (nextPage) => {
        handlePageChange(nextPage);
    };
    const handlePrev = (prevPage) => {
        handlePageChange(prevPage);
    };
    const handlePageSelect = (page) => {
        handlePageChange(page);
    };
    return (
        <>
            <Card
                sx={{
                    borderRadius: 2,
                }}
            >
                {/* ==== Body ===== */}
                <CardBody
                    sx={{
                        height: height,
                        position: "relative",
                    }}
                >
                    {children}
                </CardBody>
                <CardFooter>
                    <DataPagnation
                        data={data}
                        currentPage={currentPage}
                        perPage={handlePerPageChage}
                        handlePageSelect={handlePageSelect}
                        handleNext={handleNext}
                        handlePrev={handlePrev}
                    />
                </CardFooter>
            </Card>
        </>
    );
};

export default CustomPagination;
