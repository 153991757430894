import React, { useState } from "react";
import CustomSubmitButton1 from "../../../components/forms/buttons/CustomSubmitButton1";
import {
    ArrowRightAlt,
    ArrowRightAltOutlined,
    DoneAllOutlined,
    ManageAccountsOutlined,
    SecurityOutlined,
} from "@mui/icons-material";
import CustomDialog from "../../../components/dialogs/CustomDialog";
import { Box, Typography } from "@mui/material";
import { setMemberProfile } from "../../../state/features/profile/memberProfileSlice";
import { useRequestMemberVerificationMutation } from "../../../api/memberAPI/memberAPI";
import { useDispatch } from "react-redux";
import useRTK from "../../../hooks/useRTK";
import { Link } from "react-router-dom";

const RequestVerification = () => {
    // ################# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# Comp State ################
    const [openDialog, setOpenDialog] = useState(false);

    // ############## FUNC #####################
    // => Apply dialog
    const handleDialogOpen = () => {
        setOpenDialog(true);
    };
    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    // ############### RTK #####################
    const [
        requestMemberVerification,
        { isLoading, isSuccess, isError, error, data: updatedData },
    ] = useRequestMemberVerificationMutation();

    // ############### useEffect ################
    // => RTK action
    const rtkActions = (data) => {
        handleDialogClose();
        dispatch(setMemberProfile(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, updatedData, rtkActions);

    return (
        <>
            {/* ################ Dialogy ################# */}
            <CustomDialog
                openDialog={openDialog}
                customWidth={"sm"}
                head={"Verification request"}
                closeDialog={handleDialogClose}
            >
                <Box sx={{ width: "100%" }}>
                    <Box className="warning-message">
                        <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                            ⚠️ Attention: Verification request Submission ⚠️
                        </Typography>
                    </Box>
                    <Box sx={{ py: 5 }}>
                        <Typography sx={{ fontSize: 15, textAlign: "center" }}>
                            Please ensure that all the information you provide
                            is accurate and up-to-date. Submitting false
                            information may result in account to not be
                            verified. We take verification seriously and
                            appreciate your cooperation.🌟
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            py: 0.5,
                            width: "100%",
                        }}
                    >
                        <CustomSubmitButton1
                            startIcon={<DoneAllOutlined color="success" />}
                            endIcon={<ArrowRightAltOutlined />}
                            btnColor={`secondary`}
                            variant={"contained"}
                            onClick={() => {
                                requestMemberVerification();
                            }}
                            loading={isLoading}
                            sx={{ py: 1.5, fontSize: 16 }}
                        >
                            Request verification
                        </CustomSubmitButton1>
                    </Box>
                </Box>
            </CustomDialog>
            <Link to="/membership-levels">
                <CustomSubmitButton1
                    startIcon={<SecurityOutlined />}
                    endIcon={<ArrowRightAlt />}
                    // onClick={handleDialogOpen}
                >
                    You can now create invoice
                </CustomSubmitButton1>
            </Link>
        </>
    );
};

export default RequestVerification;
