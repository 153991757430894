import React, { useState } from "react";
import { Add, Update } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import CustomCrudDialog from "../../../components/dialogs/CustomCrudDialog";
import UpdateAttachments from "./UpdateAttachments";

const AddAttachment = ({ attachment }) => {
    // ############## Comp State ##############
    const [openDialog, setOpenDialog] = useState(false);

    // ############# Func ###############
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <>
            <CustomCrudDialog
                openDialog={openDialog}
                closeDialog={handleCloseDialog}
                head={`${attachment.status ? "Update" : "Add"} attachment docs`}
            >
                <Box sx={{ pt: 2 }}>
                    <Typography
                        sx={{
                            fontSize: 22,
                            fontWeight: "bold",
                            textAlign: "center",
                            opacity: 0.7,
                        }}
                    >
                        Add company{" "}
                        {attachment.attachmentType === "CIN"
                            ? "Inc certificate"
                            : attachment.attachmentType}
                    </Typography>
                </Box>
                <UpdateAttachments
                    closeDialogy={handleCloseDialog}
                    attachmentType={attachment.attachmentType}
                    attachmentNumber={attachment.attachmentNumber}
                />
            </CustomCrudDialog>
            <Button
                size="small"
                startIcon={attachment.status ? <Update /> : <Add />}
                variant="outlined"
                sx={{
                    fontSize: 12,
                    fontWeight: "bold",
                    color: "#fff",
                    backgroundImage: `linear-gradient(rgba(23, 148, 202, .5), rgba(23, 148, 202, .5)), radial-gradient(circle at left top, rgb(1, 110, 218), rgb(49, 180, 76, .5))`,
                }}
                onClick={() => {
                    handleOpenDialog();
                }}
            >
                {attachment.status ? "Update" : "Add"}
            </Button>
        </>
    );
};

export default AddAttachment;
