// => GET membership level
const GET_MEMBERSHIP_LEVELS = "/membership-levels/active";

// => GET active membership level
const GET_ACTIVE_MEMBERSHIP_LEVELS = "/membership-levels/active";

// => CREATE membership level
const ADD_MEMBERSHIP_LEVEL = "/members/add-membership-level";

// => UPDATE membership level
const UPDATE_MEMBERSHIP_LEVEL = "/members/update-membership-level";

export const membershipLevelEndpoints = {
    ADD_MEMBERSHIP_LEVEL,
    UPDATE_MEMBERSHIP_LEVEL,
    GET_MEMBERSHIP_LEVELS,
    GET_ACTIVE_MEMBERSHIP_LEVELS,
};
