// => LOGIN
const LOGIN = "/auth/login";

// => LOGOUT
const LOGOUT = "/auth/logout";

export const authEndpoints = {
    LOGIN,
    LOGOUT,
};
