import { systemConfigSelector } from "../state/features/selectors";
import { useSelector } from "react-redux";

const lightColor = {
    primary: "#1794CA",
    secondary: "#31B44C",
    bgColor1: "#8bc9e4",
    bgColor2: "#a2d4e9",
    bgColor3: "#b9deef",
    bgColor4: "#d0e9f4",
    bgColor5: "#e7f4f9",
    yellow: "#FFD900",
    green: "#00EE76",
    red: "#F43249",
    gray: "#D9D9D9",
    blue: "#027BBD",
};

const darkColor = {
    primary: "#1794CA",
    secondary: "#31B44C",
    bgColor1: "#8bc9e4",
    bgColor2: "#a2d4e9",
    bgColor3: "#b9deef",
    bgColor4: "#d0e9f4",
    bgColor5: "#e7f4f9",
    yellow: "#FFD900",
    green: "#00EE76",
    red: "#F43249",
    gray: "#D9D9D9",
    blue: "#027BBD",
};

const useColor = () => {
    // ######### Inner states #############
    let color = null;

    // ######### Redux states #############
    const { darkMode } = useSelector(systemConfigSelector);

    if (darkMode) {
        color = darkColor;
    } else {
        color = lightColor;
    }
    return color;
};

export const colors = lightColor;
export default useColor;
