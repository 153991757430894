import { Box, Button } from "@mui/material";
import React from "react";
import Loader from "react-js-loader";
import { colors } from "../../../assets/utils/colors";

const CustomButton2 = ({
    title,
    startIcon,
    endIcon,
    btColor,
    loading,
    variant,
    action,
}) => {
    return (
        <>
            <Button
                size={"large"}
                onClick={action}
                disabled={loading ? true : false}
                startIcon={startIcon}
                endIcon={endIcon}
                variant={variant ? variant : "outlined"}
                fullWidth
                color={btColor ? btColor : "primary"}
                sx={{
                    py: { sm: 1.5, xs: 1 },
                    fontSize: { sm: 14, xs: 12 },
                    fontWeight: "bold",
                }}
            >
                {title}
                {loading && (
                    <Box
                        sx={{
                            position: "absolute",
                            left: 0,
                            right: 0,
                        }}
                    >
                        <Loader
                            type="bubble-loop"
                            bgColor={colors.info}
                            color={colors.info}
                            size={50}
                        />
                    </Box>
                )}
            </Button>
        </>
    );
};

export default CustomButton2;
