import { adminAPI } from "../../api/adminAPI/adminAPI";
import { authAPI } from "../../api/authAPI/authAPI";
import { certificateAPI } from "../../api/certificateAPI/certificateAPI";
import { contactPersonAPI } from "../../api/contactPersonAPI/contactPersonAPI";
import { contactsAPI } from "../../api/contactsAPI/contactsAPI";
import { directorsAPI } from "../../api/directorsAPI/directorsAPI";
import { invoiceAPI } from "../../api/invoiceAPI/invoiceAPI";
import { licenseAPI } from "../../api/licenseAPI/licenseAPI";
import { memberAPI } from "../../api/memberAPI/memberAPI";
import { memberAttachmentsAPI } from "../../api/memberAttachmentsAPI/memberAttachmentsAPI";
import { membershipLevelAPI } from "../../api/membershipLevelAPI/membershipLevelAPI";
import { notificationsAPI } from "../../api/notificationsAPI/notificationsAPI";
import { profileAPI } from "../../api/profileAPI/profileAPI";
import { registerAPI } from "../../api/registerAPI/registerAPI";

// => RTK middleware
export const RTKmiddleware = [
    registerAPI.middleware,
    authAPI.middleware,
    profileAPI.middleware,
    memberAttachmentsAPI.middleware,
    contactsAPI.middleware,
    contactPersonAPI.middleware,
    directorsAPI.middleware,
    memberAPI.middleware,
    adminAPI.middleware,
    invoiceAPI.middleware,
    membershipLevelAPI.middleware,
    licenseAPI.middleware,
    notificationsAPI.middleware,
    certificateAPI.middleware,
];
