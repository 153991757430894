import React from "react";
import CustomCard from "../../../components/cards/CustomCard";
import { Grid } from "@mui/material";
import Infobox1 from "../../../components/infoboxes/Infobox1";
import AddCompanyContacts from "../CRUD/AddCompanyContacts";
import { isEmpty } from "lodash";
import NoContent from "../../../components/NoContent";

const CompanyContacts = ({ profile }) => {
    const contacts = profile.contacts;
    const companyContacts = [
        {
            id: 3,
            title: "Location",
            value: contacts?.location,
            width: 4,
        },
        {
            id: 1,
            title: "Post address",
            value: contacts?.postalAddress,
            width: 4,
        },
        {
            id: 4,
            title: "Plot Number",
            value: contacts?.plotNumber,
            width: 4,
        },
        {
            id: 5,
            title: "Region",
            value: contacts?.region?.regionName,
            width: 4,
        },
        {
            id: 6,
            title: "District",
            value: contacts?.district,
            width: 4,
        },
        {
            id: 6,
            title: "Ward",
            value: contacts?.ward,
            width: 4,
        },
        {
            id: 8,
            title: "Fax number",
            value: contacts?.fax,
            width: 4,
        },
        {
            id: 7,
            title: "Telephone",
            value: contacts?.phoneNumber,
            width: 4,
        },
        {
            id: 2,
            title: "Stree",
            value: contacts?.street,
            width: 4,
        },
        {
            id: 9,
            title: "Email address",
            value: contacts?.email,
            width: 6,
        },
        {
            id: 10,
            title: "Website address",
            value: contacts?.website,
            width: 6,
        },
    ];
    return (
        <div>
            <CustomCard
                title={"Company contacts"}
                height={"100%"}
                action={<AddCompanyContacts contacts={contacts} />}
            >
                {isEmpty(contacts) ? (
                    <NoContent
                        height={"25vh"}
                        message={"Company contact not updated"}
                    />
                ) : (
                    <Grid container padding={2}>
                        {companyContacts.map((contact, index) => (
                            <Grid item sm={contact.width} xs={6} key={index}>
                                <Infobox1 data={contact} />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </CustomCard>
        </div>
    );
};

export default CompanyContacts;
