// REGISTER user
const REGISTER = "/auth/register";

// Reg ID verification
const VERIFY_ICN = "/auth/verify-tin-number";

// Verify company name
const VERIFY_COMPANY_NAME = "/auth/verify-company-name";

// REQUEST otp
const REQUEST_OTP = "/auth/request-otp";

// VERIFY otp
const VERIFY_OTP = "/auth/verify-otp";

// FORGOT password
const FORGOT_PASSWORD = "/auth/password/forgot";

export const registerEndpoints = {
    VERIFY_ICN,
    VERIFY_COMPANY_NAME,
    REQUEST_OTP,
    VERIFY_OTP,
    REGISTER,
    FORGOT_PASSWORD,
};
