import React from 'react';
import { Grid } from '@mui/material';

const SpacingContent = ({ children }) => {
    return (
        <>
            <Grid container justifyContent={'center'}>
                <Grid item sm={11} xs={11.9}>
                    {children}
                </Grid>
            </Grid>
        </>
    );
};

export default SpacingContent;
