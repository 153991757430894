import getEnv from "../../helpers/getEnv";

// => Local base url
const localBaseUrl = getEnv().REACT_APP_LOCAL_URL;

// => Dev base url
const devBaseUrl = getEnv().REACT_APP_DEV_URL;

// => Pro base url
const proBaseUrl = getEnv().REACT_APP_PRODUCTION_URL;

const getBaseURL = () => {
    let env = process.env.REACT_APP_ENV;
    if (env === "pro") {
        return proBaseUrl;
    }
    if (env === "dev") {
        return devBaseUrl;
    }
    return localBaseUrl;
};

export const baseURL = getBaseURL();
