import { Box, Grid } from "@mui/material";
import React from "react";
import PageHeader from "../../components/PageHeader";
import { colors } from "../../assets/utils/colors";

const PageLayout = ({ sidebar, children, head, action, noSidebar }) => {
    return (
        <>
            <Grid
                container
                spacing={2}
                sx={{
                    flex: `1 1 auto`,
                }}
                padding={{ md: 0, sm: 0, xs: 1 }}
            >
                {head && (
                    <Grid
                        item
                        sm={12}
                        xs={12}
                        sx={{
                            position: "sticky",
                            top: { md: 48, sm: 48, xs: 40 },
                            zIndex: 999,
                        }}
                    >
                        <PageHeader head={head} action={action} />
                    </Grid>
                )}
                <Grid item sm={12} xs={12}>
                    <Grid
                        container
                        spacing={2}
                        sx={{ pb: 1, px: { md: 2, sm: 2, xs: 0 } }}
                    >
                        <Grid item sm={noSidebar ? 12 : 9} xs={12}>
                            <Box
                            // sx={{
                            //     py: { md: 5, sm: 5, xs: 2 },
                            //     px: { md: 2, sm: 2, xs: 1 },
                            //     m: 2,
                            //     borderRadius: 5,
                            //     background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                            // }}
                            >
                                {children}
                            </Box>
                        </Grid>
                        {noSidebar ? (
                            <></>
                        ) : (
                            <Grid item sm={3}>
                                <Box
                                    sx={{
                                        height: head ? `76vh` : `88vh`,
                                        position: "sticky",
                                        top: 145,
                                        py: 1.5,
                                        p: 1.5,
                                        overflow: "auto",
                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor4}) !important`,
                                        borderRadius: 4,
                                        boxShadow: `0 2px 10px ${colors.bgColor2}`,
                                    }}
                                >
                                    {sidebar}
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default PageLayout;
