import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { AddPhotoAlternate, ArrowRight } from "@mui/icons-material";
import useRTK from "../../../hooks/useRTK";
import { colors } from "../../../assets/utils/colors";
import { useUploadAttachmentMutation } from "../../../api/memberAttachmentsAPI/memberAttachmentsAPI";
import { setMemberProfile } from "../../../state/features/profile/memberProfileSlice";
import CustomFileInput from "../../../components/forms/CustomFileInput";
import CustomSubmitButton1 from "../../../components/forms/buttons/CustomSubmitButton1";
import CustomTextField from "../../../components/forms/CustomTextField";

// ################## VALIDATION ####################
// * -> logo
const attachmentValidationSchema = Yup.object().shape({
    attachment: Yup.mixed().required("File field is required"),
    attachmentType: Yup.string().required("Attachment type is required"),
    attachmentNumber: Yup.string().required("Attachment number is required"),
});
const attachmentValidationSchema2 = Yup.object().shape({
    attachment: Yup.mixed().required("File field is required"),
    attachmentType: Yup.string().required("Attachment type is required"),
});

// !############## MAIN FUNC ################
const UpdateAttachments = ({
    closeDialogy,
    attachmentType,
    attachmentNumber,
}) => {
    // ################# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############## Comp State ##########
    // -> handle file preview
    const [filePreview, setFilePreview] = useState("");
    const [selectedSchema, setSelectedSchema] = useState("schemaOne");
    // const attachmentType = [
    //     {
    //         id: 1,
    //         name: "Certificate Of Incorporation",
    //         value: "COI",
    //     },
    //     {
    //         id: 2,
    //         name: "Business License",
    //         value: "BL",
    //     },
    //     {
    //         id: 3,
    //         name: "Tax identification numbers",
    //         value: "TIN",
    //     },
    //     {
    //         id: 4,
    //         name: "Company Logo",
    //         value: "logo",
    //     },
    //     {
    //         id: 5,
    //         name: "Company Profile",
    //         value: "CP",
    //     },
    // ];

    // ############## FUNC #################
    const getSelectedValue = (value) => {
        value === "logo" || value === "profile"
            ? setSelectedSchema("schemaTwo")
            : setSelectedSchema("schemaOne");
    };

    // ############## RTK ##################
    const [
        uploadMemberAttachment,
        { isLoading, isSuccess, isError, error, data: updatedData },
    ] = useUploadAttachmentMutation();

    // ############### useEffect ################
    // => RTK action
    const rtkActions = (data) => {
        closeDialogy();
        dispatch(setMemberProfile(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, updatedData, rtkActions);

    useEffect(() => {
        let isSubscribed = true;
        getSelectedValue(attachmentType);
        return () => {
            isSubscribed = false;
        };
    }, [attachmentType]);

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    attachment: "",
                    attachmentType: attachmentType,
                    attachmentNumber: attachmentNumber,
                }}
                validationSchema={
                    selectedSchema === "schemaOne"
                        ? attachmentValidationSchema
                        : attachmentValidationSchema2
                }
                onSubmit={(payload) => {
                    const data = new FormData();
                    data.append("attachmentType", payload.attachmentType);
                    data.append("attachmentNumber", payload.attachmentNumber);
                    payload.attachmentType === "CIN"
                        ? data.append(
                              "incorporationCertficate",
                              payload.attachment
                          )
                        : payload.attachmentType === "license"
                        ? data.append("businessLicense", payload.attachment)
                        : payload.attachmentType === "logo"
                        ? data.append("companyLogo", payload.attachment)
                        : payload.attachmentType === "profile"
                        ? data.append("companyProfile", payload.attachment)
                        : payload.attachmentType === "tin"
                        ? data.append("tinCertificate", payload.attachment)
                        : data.append("attachment", "");
                    uploadMemberAttachment(data);
                }}
            >
                {(formik) => (
                    <Form>
                        <Grid container justifyContent={"center"} spacing={2}>
                            {/* <Grid
                                item
                                sm={selectedSchema === "schemaOne" ? 6 : 12}
                                xs={12}
                            >
                                <CustomSelect
                                    label={"Attachment type"}
                                    formik={formik}
                                    bBottom={colors.secondary}
                                    name="attachmentType"
                                    handler={getSelectedValue}
                                >
                                    {attachmentType.map((attachment) => (
                                        <MenuItem
                                            key={attachment.value}
                                            value={attachment.value}
                                        >
                                            {attachment.name}
                                        </MenuItem>
                                    ))}
                                </CustomSelect>
                            </Grid> */}
                            {selectedSchema === "schemaOne" && (
                                <Grid item sm={12} xs={12}>
                                    <CustomTextField
                                        type="text"
                                        name="attachmentNumber"
                                        label={"Attachment number"}
                                        bBottom={colors.secondary}
                                    />
                                </Grid>
                            )}
                            <Grid item sm={12}>
                                <Box
                                    sx={{
                                        borderRadius: 1,
                                    }}
                                >
                                    <CustomFileInput
                                        name="attachment"
                                        type="file"
                                        formik={formik}
                                        accept="image/*"
                                        inputName={"attachment"}
                                        setFilePreview={setFilePreview}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item sm={12} xs={12} sx={{ mt: 5 }}>
                            <CustomSubmitButton1
                                loading={isLoading}
                                btnColor={"primary"}
                                startIcon={<AddPhotoAlternate />}
                                endIcon={<ArrowRight />}
                                onClick={formik.handleSubmit}
                            >
                                Upload attachment
                            </CustomSubmitButton1>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default UpdateAttachments;
