import { createSlice } from "@reduxjs/toolkit";
import { filter } from "lodash";

const initialState = {
    memberProfile: {},
};

// !########## SLICE #############
const memberProfileSlice = createSlice({
    name: "memberProfileSlice",
    initialState,
    reducers: {
        setMemberProfile: (state, action) => {
            let profile = action.payload;
            let user = profile?.member.users;
            let member = profile?.member;
            let CIN = filter(profile.attachments, { attachmentType: "CIP" });
            CIN = CIN[0];
            let BL = filter(profile.attachments, { attachmentType: "license" });
            BL = BL[0];
            let CP = filter(profile.attachments, { attachmentType: "profile" });
            CP = CP[0];
            let TIN = filter(profile.attachments, { attachmentType: "tin" });
            TIN = TIN[0];
            let logo = filter(profile.attachments, { attachmentType: "logo" });
            logo = logo[0];

            let restructuredProfile = {
                // => User
                user_id: user.id,
                registrationId: user.registrationId,
                phoneNumber: user.phoneNumber,
                lastLogin: user.lastLogin,
                created_at: user.created_at,
                updated_at: user.updated_at,
                // => Member
                companyName: member.companyName,
                companySector: member?.sector,
                memberShipCategory: member.memberShipCategory,
                verificationStatus: member.verificationStatus,
                establishmentYear: member.establishmentYear,
                companyShortName: member.companyShortName,
                description: member.description,
                verificationMessage: member.verificationMessage,
                companyStatus: member.companyStatus,
                progressComplete: member.progressComplete,
                membershipLevel: member.membershipLevel,
                currInvoice: profile.invoice,
                // => Adition
                contacts: profile.contacts,
                contactPerson: profile.contactPerson,
                directors: profile.directors,
                attachments: profile.attachments,
                // => Attachments
                certOfIncoporation: CIN,
                businessLicense: BL,
                companyProfile: CP,
                logo: logo,
                TIN: TIN,
            };

            state.memberProfile = restructuredProfile;
        },
    },
});

export const { setMemberProfile } = memberProfileSlice.actions;
export default memberProfileSlice.reducer;
