import { createSlice } from "@reduxjs/toolkit";

// => initialState
const initialState = {
    darkMode: false,
    isMainFetch: true,
};
export const systemConfigSlice = createSlice({
    name: "systemConfigSlice",
    initialState,
    reducers: {
        setDarkMode: (state, action) => {
            state.darkMode = action.payload;
        },
        // => Deactivate main fetch
        disableMainFetch: (state, actions) => {
            state.isMainFetch = false;
        },
    },
});

export const { setDarkMode, disableMainFetch } = systemConfigSlice.actions;
export default systemConfigSlice.reducer;
