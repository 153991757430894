import React from "react";

import { Box, Typography, IconButton, Tooltip } from "@mui/material";
import { Delete, DeleteForeverOutlined } from "@mui/icons-material";
import { colors } from "../../assets/utils/colors";
import moment from "moment";

const NotificationWidget = ({ notification }) => {
    return (
        <React.Fragment>
            <Box
                sx={{
                    p: 1.5,
                    borderRadius: 2,
                    background: `linear-gradient(180deg, ${colors.bgColor4}, ${colors.bgColor4})`,
                    border: `1px dotted ${colors.primary}`,
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            fontWeight: "bold",
                            fontSize: 14,
                            color: colors.primary,
                        }}
                    >
                        {notification?.title}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        py: 1,
                    }}
                >
                    <Typography sx={{ fontSize: 13, opacity: 0.8 }}>
                        {notification?.description}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: "bold",
                            fontSize: 11,
                            color: colors.orange,
                        }}
                    >
                        {moment(notification.created_at).format("LL")}
                    </Typography>
                    <Tooltip title="Delete" arrow>
                        <IconButton
                            size="small"
                            color="error"
                            sx={{
                                background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor4})`,
                            }}
                        >
                            <DeleteForeverOutlined
                                sx={{
                                    fontSize: 20,
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
        </React.Fragment>
    );
};

export default NotificationWidget;
