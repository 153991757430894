import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
    Box,
    FilledInput,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
} from '@mui/material';
import { ErrorMessage, useField } from 'formik';
import React, { useState } from 'react';
import { colors } from '../../assets/utils/colors';

const CustomPasswordField = ({
    label,
    type,
    labelColor,
    bBottom,
    ...props
}) => {
    const [field, meta] = useField(props);
    const [showPassword, setShowPassword] = useState(false);
    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    return (
        <>
            <Box sx={{ marginTop: 2, borderRadius: 1.5, overflow: 'hidden' }}>
                <FormControl
                    fullWidth
                    variant="filled"
                    sx={{
                        borderBottom: `1px solid ${bBottom}`,
                    }}
                >
                    <InputLabel
                        sx={{
                            color: `${
                                labelColor ? labelColor : colors.secondary
                            } !important`,
                        }}
                    >
                        {label}
                    </InputLabel>
                    <FilledInput
                        id={field.label}
                        error={meta.touched && meta.error ? true : false}
                        label={label}
                        type={showPassword ? 'text' : type}
                        placeholder={field.label}
                        {...field}
                        {...props}
                        fullWidth
                        size="small"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleShowPassword}
                                    edge="end"
                                >
                                    {showPassword ? (
                                        <VisibilityOff
                                            className="icon"
                                            color={
                                                labelColor
                                                    ? labelColor
                                                    : 'red !important'
                                            }
                                        />
                                    ) : (
                                        <Visibility
                                            className="icon"
                                            color={
                                                labelColor
                                                    ? labelColor
                                                    : 'red !important'
                                            }
                                        />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <ErrorMessage
                    component="span"
                    name={field.name}
                    className="error-text"
                />
            </Box>
        </>
    );
};
export default CustomPasswordField;
