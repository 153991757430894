import React from "react";
import { Box, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ErrorMessage, useField } from "formik";

const CustomInput = styled(TextField)({
    padding: 0,
});

const CustomFileInput = ({
    formik,
    setFilePreview,
    setFile,
    label,
    type,
    accept,
    bBottom,
    inputName,
    ...props
}) => {
    const handleFileInputChange = (e) => {
        setFilePreview &&
            setFilePreview(URL.createObjectURL(e.target.files[0]));
        formik.setFieldValue(inputName, e.currentTarget.files[0]);
    };
    const [field, meta] = useField(props);
    return (
        <>
            <Box sx={{ marginTop: 2 }}>
                <CustomInput
                    error={meta.touched && meta.error ? true : false}
                    label={label}
                    variant="filled"
                    fullWidth
                    type={type}
                    placeholder={field.label}
                    onChange={handleFileInputChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{ accept: accept }}
                    size="small"
                    sx={{
                        borderBottom: `2px solid ${bBottom}`,
                    }}
                />
                <ErrorMessage
                    component="span"
                    name={field.name}
                    className="error-text"
                />
            </Box>
        </>
    );
};

export default CustomFileInput;
