import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import React from "react";

const NextAndBackBtn = ({
    steps,
    activeStep,
    action,
    disabled,
    handleBack,
}) => {
    return (
        <>
            <Box
                sx={{
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: "flex",
                    borderRadius: 5,
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    variant="text"
                    className="app-btn"
                    sx={{ px: 2 }}
                    color="warning"
                    startIcon={<ArrowLeft />}
                >
                    Back
                </Button>
                {activeStep !== steps.length ? (
                    <Button
                        onClick={action}
                        disabled={disabled && disabled}
                        variant="text"
                        className="app-btn"
                        color="secondary"
                        sx={{ px: 2 }}
                        endIcon={<ArrowRight />}
                    >
                        {activeStep === steps.length ? "Finish" : "Next"}
                    </Button>
                ) : (
                    ""
                )}
            </Box>
        </>
    );
};

export default NextAndBackBtn;
