import * as React from "react";
import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";
import HotToastfy from "../../components/HotToastfy";
import HomeSideBar from "../../components/HomeSideBar";
import HomeTopBar from "../../components/HomeTopBar";
import Footer from "../../components/Footer";
import useRTK from "../../hooks/useRTK";
import { setActiveMembershipLevels } from "../../state/features/membershipLevel/membershipLevelSlice";
import FullAppLoader from "../../components/FullAppLoader";
import { useLazyGetActiveMemberShipLevelsQuery } from "../../api/membershipLevelAPI/membershipLevelAPI";
import { useDispatch } from "react-redux";

// const DrawerHeader = styled(Box)(({ theme }) => ({
//     ...theme.mixins.toolbar,
// }));

const HomeLayout = ({ children }) => {
    // ############# Const ##############
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const { pathname } = useLocation();
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# FUNC ##############
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // ############### RTK ####################
    // => Active membership level
    const [
        getMemberShipLevels,
        {
            isLoading: membershipLevelsLoading,
            isSuccess: membershipLevelsSuccess,
            data: membershipLevelsData,
        },
    ] = useLazyGetActiveMemberShipLevelsQuery();
    const rtkMembershipLevelsActions = (data) => {
        dispatch(setActiveMembershipLevels(data));
    };
    RTK.useRTKFetch(
        membershipLevelsSuccess,
        membershipLevelsData,
        rtkMembershipLevelsActions
    );

    React.useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            getMemberShipLevels();
        }

        return () => {
            isSubscribed = false;
        };
    }, []);

    return (
        <Box
            sx={{
                display: "flex",
                // position: "absolute",
                top: 0,
                bottom: "100%",
                right: 0,
                left: 0,
            }}
        >
            {membershipLevelsLoading ? (
                <FullAppLoader />
            ) : (
                <>
                    <HotToastfy />
                    {/* ############### topbar ############### */}
                    <HomeTopBar
                        handleDrawerToggle={handleDrawerToggle}
                        mobileOpen={mobileOpen}
                    />

                    {/* ############### sidebar ###############*/}
                    <HomeSideBar mobileOpen={mobileOpen} />

                    {/* ############### content ###############*/}
                    <Box component="main" sx={{ flexGrow: 1 }}>
                        {/* <DrawerHeader /> */}
                        {children}

                        {/* ############### Footer ############### */}
                        <Footer />
                    </Box>
                </>
            )}
        </Box>
    );
};

export default HomeLayout;
