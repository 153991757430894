import * as React from "react";
import Box from "@mui/material/Box";
import TopBar from "../../components/TopBar";
import SideBar from "../../components/SideBar";
import { useLocation } from "react-router-dom";
import useRTK from "../../hooks/useRTK";
import FullAppLoader from "../../components/FullAppLoader";
import HotToastfy from "../../components/HotToastfy";

const AppLayout2 = ({ children }) => {
    const { pathname } = useLocation();
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    // ################# Comp state ###############
    const RTK = useRTK();

    return (
        <Box
            sx={{
                display: "flex",
                position: "absolute",
                top: 0,
                right: 0,
                left: 0,
            }}
        >
            {RTK.useRTKMainData() ? (
                <FullAppLoader />
            ) : (
                <>
                    {/* ############## Hot Toastfy ########## */}
                    <HotToastfy />

                    {/* ############### topbar ############### */}
                    <TopBar
                        handleDrawerToggle={handleDrawerToggle}
                        mobileOpen={mobileOpen}
                    />
                    {/* ############### sidebar ###############*/}
                    <SideBar mobileOpen={mobileOpen} />

                    {/* ############### content ###############*/}

                    <Box component="main" sx={{ flexGrow: 1 }}>
                        {/* <DrawerHeader /> */}
                        {children}
                    </Box>
                </>
            )}
        </Box>
    );
};

export default AppLayout2;
