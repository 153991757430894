// => Auth selector
export const authSelector = (state) => state.authReducer;

// => Auth selector
export const registerSelector = (state) => state.registerReducer;

// => Profile selector
export const profileSelector = (state) => state.profileReducer;

// => Member profile selector
export const memberProfileSelector = (state) => state.memberProfileReducer;

// => Admin selector
export const adminSelector = (state) => state.adminReducer;

// => Invoice selector
export const invoiceSelector = (state) => state.invoiceReducer;

// => License selector
export const licenseSelector = (state) => state.licenseReducer;

// => Membership Level selector
export const membershipLevelSelector = (state) => state.membershipLevelReducer;

// => systemConfig selector
export const systemConfigSelector = (state) => state.systemConfigReducer;

// => Notification selector
export const notificationsSelector = (state) => state.notificationsReducer;

// => Certificate selector
export const certificateSelector = (state) => state.certificateReducer;
