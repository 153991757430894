import { Box, styled, Typography } from "@mui/material";
import React from "react";
import strHelper from "../../helpers/strHelper";
import { colors } from "../../assets/utils/colors";
import DataPagnation2 from "./DataPagnation2";

// ############# CARD ###############
const Card = styled(Box)({
    borderRadius: 15,
    border: `1px solid ${colors.bgColor4}`,
    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor4}) !important`,
});

// ############# CARD HEADER ###############
const CardHeader = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 15,
    paddingRight: 15,
    position: "relative !important",
    ".head": {
        fontSize: 14,
        // color: colors.primary,
        fontWeight: "bold",
        width: "100%",
        opacity: 0.6,
    },
});

// ############# CARD BODY ###############
const CardBody = styled(Box)({
    padding: 15,
    overflow: "auto",
    position: "relative",
    background: colors.bgColor1,
});

// ############# CARD FOOTER ###############
const CardFooter = styled(Box)(({ theme }) => ({}));

const CustomPagination2 = ({
    data,
    height,
    action,
    head,
    borderBottom,
    handlePageChange,
    handlePerPageChage,
    children,
}) => {
    const STR = strHelper();

    const currentPage = data?.page;
    const handleNext = (nextPage) => {
        handlePageChange(nextPage);
    };
    const handlePrev = (prevPage) => {
        handlePageChange(prevPage);
    };
    const handlePageSelect = (page) => {
        handlePageChange(page);
    };
    return (
        <>
            <Card
                sx={{
                    borderRadius: 2,
                    borderBottom: borderBottom
                        ? `15px solid ${colors.bgColor4}`
                        : `1px solid ${colors.bgColor4}`,
                }}
            >
                {/* ==== header ===== */}
                <CardHeader sx={{ display: head ? "flex" : "none" }}>
                    <Box>
                        <Typography className="head" noWrap>
                            {STR.strCapitalizeFirstChar(head)}
                        </Typography>
                    </Box>
                    <Box>{action}</Box>
                </CardHeader>

                {/* ==== Body ===== */}
                <CardBody
                    sx={{
                        height: height,
                        overflow: "auto",
                        position: "relative",
                    }}
                >
                    {children}
                </CardBody>
                <CardFooter>
                    <DataPagnation2
                        data={data}
                        currentPage={currentPage}
                        perPage={handlePerPageChage}
                        handlePageSelect={handlePageSelect}
                        handleNext={handleNext}
                        handlePrev={handlePrev}
                    />
                </CardFooter>
            </Card>
        </>
    );
};

export default CustomPagination2;
