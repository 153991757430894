import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../baseUrl/baseURL";
import { headers } from "../baseUrl/setHeaders";
import { licenseEndpoints } from "./licenseEndpoints";

export const licenseAPI = createApi({
    reducerPath: "licenseAPI",
    baseQuery: fetchBaseQuery({ baseUrl: baseURL, prepareHeaders: headers }),
    endpoints: (builder) => ({
        // GET Invoices
        getLicenses: builder.query({
            query: (payload) => ({
                url: `${licenseEndpoints.GET_LICENSES}`,
            }),
        }),
    }),
});

export const {
    useGetLicensesQuery,

    // => Lazy
    useLazyGetLicensesQuery,
} = licenseAPI;
