import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { adminEndpoints } from "./adminEndpoints";
import { baseURL } from "../baseUrl/baseURL";
import { headers } from "../baseUrl/setHeaders";

export const adminAPI = createApi({
    reducerPath: "adminAPI",
    baseQuery: fetchBaseQuery({ baseUrl: baseURL, prepareHeaders: headers }),
    endpoints: (builder) => ({
        // => Get sectors
        getActiveSectors: builder.query({
            query: (payload) => `${adminEndpoints.GET_ACTIVE_SECTORS}`,
        }),

        // => Get all announcements
        getAllAnnouncements: builder.query({
            query: () => `${adminEndpoints.GET_ANNOUNCEMENTS}`,
        }),

        // => Get single announcement
        getSingleAnnouncement: builder.query({
            query: (payload) =>
                `${adminEndpoints.GET_SINGLE_ANNOUNCEMENT}/${payload}`,
        }),

        // => Get bank accs
        getBantAccounts: builder.query({
            query: () => `${adminEndpoints.GET_BANK_ACCOUNTS}`,
        }),

        // => Get bank accs
        getActiveRegions: builder.query({
            query: () => `${adminEndpoints.GET_ACTIVE_REGIONS}`,
        }),
    }),
});

export const {
    useGetAllAnnouncementsQuery,
    useGetSingleAnnouncementQuery,
    useGetBantAccountsQuery,
    useGetActiveRegionsQuery,

    // => Lazy
    useLazyGetAllAnnouncementsQuery,
    useLazyGetBantAccountsQuery,
    useLazyGetActiveSectorsQuery,
    useLazyGetActiveRegionsQuery,
} = adminAPI;
