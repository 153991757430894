import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { ArrowRight, Payment } from "@mui/icons-material";
import CustomDialog from "../../../components/dialogs/CustomDialog";
import CustomButton2 from "../../../components/forms/buttons/CustomButton2";
import PayInvoiceForm from "./PayInvoiceForm";

const ControllNumber = ({ fullWidth, invoice }) => {
    // ############## Comp State ##############
    const [openDialog, setOpenDialog] = useState(false);

    // ############# Func ###############
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <>
            <Box>
                {/* ################ Dialogy ################# */}
                <CustomDialog
                    openDialog={openDialog}
                    closeDialog={handleCloseDialog}
                    head={"Invoice control number payment"}
                    customWidth={"md"}
                >
                    <Box className="warning-message">
                        <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                            NAMNA YA KULIPIA INVOICE KWA KUTUMIA CONTROL NUMBER
                        </Typography>
                    </Box>
                    <Box sx={{ py: 5 }}>
                        <Typography sx={{ fontSize: 15, textAlign: "center" }}>
                            Tunapenda kuwataarifu kuwa Bank ya CRDB imeanzisha
                            mfumo waulipaji wa Ada kupitia Control Number hivyo
                            utapokea ujumbe mfupi (SMS) wenye Jina la Mtoto na
                            kiasi cha kulipa na Control Number utakayotumia
                            kulipia. Unaweza kulipia kwa njia zifuatazo:
                        </Typography>
                    </Box>
                </CustomDialog>
                <CustomButton2
                    size={"large"}
                    btColor="secondary"
                    fullWidth={fullWidth && fullWidth}
                    startIcon={<Payment />}
                    endIcon={<ArrowRight />}
                    action={handleOpenDialog}
                    title={"Control # payment"}
                />
            </Box>
        </>
    );
};

export default ControllNumber;
