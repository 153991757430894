import React from "react";
import { Grid } from "@mui/material";
import CompanyDetails from "./components/CompanyDetails";
import CompanyContacts from "./components/CompanyContacts";
import CompanyDirectors from "./components/CompanyDirectors";
import CompanyAttachment from "./components/CompanyAttachment";
import ContactPerson from "./components/ContactPerson";
import SubmitProfileDetails from "./components/SubmitProfileDetails";
import { memberProfileSelector } from "../../state/features/selectors";
import { useSelector } from "react-redux";
import PageLayout from "../layouts/PageLayout";

const Profile = () => {
    // ############ Redux state ##############
    const { memberProfile: profile } = useSelector(memberProfileSelector);

    return (
        <>
            <PageLayout head={"Member profile"} noSidebar>
                <Grid container spacing={4}>
                    <Grid item sm={12} xs={12}>
                        <SubmitProfileDetails profile={profile} />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <CompanyDetails profile={profile} />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <CompanyContacts profile={profile} />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <ContactPerson profile={profile} />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <CompanyDirectors profile={profile} />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <CompanyAttachment profile={profile} />
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default Profile;
