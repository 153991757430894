import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import MiniAnnouncementWidget from "../widgets/MiniAnnouncementWidget";
import { adminSelector } from "../../state/features/selectors";
import { useSelector } from "react-redux";
import NoContent from "../NoContent";
import { isEmpty } from "lodash";

const DashSidebar = () => {
    // ############# Redux state ##################
    const { announcements } = useSelector(adminSelector);

    return (
        <div>
            <Grid container padding={2} rowGap={2}>
                <Grid item sm={12} xs={12}>
                    <Typography
                        sx={{
                            fontSize: {
                                md: 13,
                                xs: 14,
                            },
                            mb: 3,
                            fontWeight: "bold",
                            background: `linear-gradient(rgba(23, 148, 202, .8), rgb(49, 180, 76, .8))`,
                            backgroundClip: "text",
                            textFillColor: "transparent",
                        }}
                    >
                        Announcements
                    </Typography>
                    <Box>
                        {isEmpty(announcements) ? (
                            <>
                                <NoContent
                                    height={300}
                                    message={"No announcement at the moment"}
                                />
                            </>
                        ) : (
                            <Grid container spacing={2}>
                                {announcements.map((announcement, index) => (
                                    <Grid item sm={12} xs={12} key={index}>
                                        <MiniAnnouncementWidget
                                            announcement={announcement}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default DashSidebar;
