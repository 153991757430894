import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    membershipLevels: [],
    activeMembershipLevels: [],
};

// !########## SLICE #############
const membershipLevelSlice = createSlice({
    name: "membershipLevelSlice",
    initialState,
    reducers: {
        setMembershipLevels: (state, action) => {
            state.membershipLevels = action.payload;
        },
        setActiveMembershipLevels: (state, action) => {
            state.activeMembershipLevels = action.payload;
        },
    },
});

export const { setMembershipLevels, setActiveMembershipLevels } =
    membershipLevelSlice.actions;
export default membershipLevelSlice.reducer;
