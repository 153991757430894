import { Chip, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { colors } from "../../../assets/utils/colors";
import { DocumentScannerOutlined } from "@mui/icons-material";
import DocumentPreviewDialog from "../../../components/dialogs/DocumentPreviewDialog";
import DocumentPreview from "../../../components/DocumentPreview";

const PreviewAttachment = ({ attachment }) => {
    const [openDocsDialog, setOpenDocsDialogy] = useState(false);
    // -> Dialogy closer
    const handleDialogClose = () => {
        setOpenDocsDialogy(false);
    };

    // => Handle docs preview
    const handleDocsPreview = () => {
        setOpenDocsDialogy(true);
    };
    return (
        <>
            {/* ##################### DIALOG ################# */}
            <DocumentPreviewDialog
                head={"Attachment preview"}
                openDialog={openDocsDialog}
                handleDialogClose={handleDialogClose}
            >
                <DocumentPreview document={attachment} />
            </DocumentPreviewDialog>

            {attachment ? (
                <Tooltip title="Attachment" arrow>
                    <IconButton
                        size="small"
                        sx={{
                            px: 2,
                            background: `linear-gradient(180deg, ${colors.bgColor5}, ${colors.bgColor4})`,
                        }}
                        onClick={() => {
                            handleDocsPreview();
                        }}
                    >
                        <DocumentScannerOutlined
                            sx={{ fontSize: 14, mr: 2 }}
                            color="info"
                        />
                        <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
                            View
                        </Typography>
                    </IconButton>
                </Tooltip>
            ) : (
                <Chip
                    label="Not uploaded"
                    size="small"
                    color="warning"
                    sx={{
                        fontSize: 10,
                        px: 1,
                        fontWeight: "bold",
                        opacity: 0.8,
                    }}
                />
            )}
        </>
    );
};

export default PreviewAttachment;
