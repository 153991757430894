import React from "react";
import {
    Avatar,
    Box,
    Grid,
    Typography,
    IconButton,
    Tooltip,
} from "@mui/material";
import { OutboundOutlined, Verified } from "@mui/icons-material";
import { colors } from "../../assets/utils/colors";
import { Link } from "react-router-dom";
import { filter } from "lodash";

const MiniMemberDetailsWidget = ({profile}) => {
    const getAttachment = (type) => {
        let attachment = filter(
            profile?.attachments,
            ({ attachmentType }) => attachmentType === type
        );
        if (attachment) {
            return attachment[0];
        } else {
            return false;
        }
    };

    const logo = getAttachment("logo");

    return (
        <>
            <Box
                sx={{
                    height: "100%",
                    width: "100%",
                    borderRadius: 5,
                    py: 1,
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                    boxShadow: `0 0 10px ${colors.bgColor2}`,
                    position: "relative",
                }}
            >
                <Box sx={{ position: "absolute", bottom: 5, right: 5 }}>
                    <Tooltip title="Profile" arrow>
                        <Link to={"/profile"}>
                            <IconButton color="info">
                                <OutboundOutlined />
                            </IconButton>
                        </Link>
                    </Tooltip>
                </Box>
                <Grid container padding={1} columnSpacing={1}>
                    <Grid item sm={3} xs={3}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Box>
                                <Avatar
                                    src={logo?.attachmentDocument}
                                    variant="square"
                                    sx={{
                                        height: {
                                            sm: 60,
                                            md: 60,
                                            xs: 45,
                                        },
                                        width: {
                                            sm: 60,
                                            md: 60,
                                            xs: 45,
                                        },
                                        p: 1,
                                        borderRadius: 5,
                                        color: colors.secondary,
                                        border: `2px dotted ${colors.primary}`,
                                        background: `linear-gradient(360deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                                    }}
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    // flexDirection: "column",
                                    mt: 1,
                                    p: 0.5,
                                    py: 0.2,
                                    borderRadius: 2,
                                    border: `1px dotted ${colors.secondary}`,
                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor5})`,
                                }}
                            >
                                <Verified
                                    sx={{
                                        fontSize: 14,
                                        color: colors.secondary,
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: 9,
                                        fontWeight: "bold",
                                        ml: 0.5,
                                        color: colors.secondary,
                                    }}
                                >
                                    Verified
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item sm={9} xs={9}>
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: {
                                        md: 16,
                                        xs: 14,
                                    },
                                    fontWeight: "bold",
                                    background: `linear-gradient(rgba(23, 148, 202, .8), rgb(49, 180, 76, .8))`,
                                    backgroundClip: "text",
                                    textFillColor: "transparent",
                                }}
                            >
                                {profile?.companyName}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: {
                                        md: 12,
                                        sm: 15,
                                        xs: 12,
                                    },
                                    fontWeight: "bold",
                                    opacity: 0.8,
                                }}
                            >
                                {profile?.companySector?.sectorName}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: {
                                        md: 12,
                                        sm: 15,
                                        xs: 12,
                                    },
                                    fontWeight: "bold",
                                    opacity: 0.8,
                                }}
                            >
                                {profile.companyShortName}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: {
                                        md: 12,
                                        sm: 15,
                                        xs: 12,
                                    },
                                    fontWeight: "bold",
                                    opacity: 0.8,
                                }}
                            >
                                {profile.establishmentYear}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Box sx={{ py: 2 }}>
                            <Typography
                                sx={{
                                    fontSize: 12,
                                    fontWeight: "bold",
                                    color: colors.primary,
                                    textAlign: "center",
                                }}
                            >
                                Tax Payer Identification Number
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: {
                                        md: 18,
                                        sm: 18,
                                        xs: 12,
                                    },
                                    fontWeight: "bold",
                                    opacity: 0.8,
                                    textAlign: "center",
                                    background: `linear-gradient(rgba(23, 148, 202, .8), rgb(49, 180, 76, .8))`,
                                    backgroundClip: "text",
                                    textFillColor: "transparent",
                                }}
                            >
                                {profile?.TIN?.attachmentNumber}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default MiniMemberDetailsWidget;
