import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authEndpoints } from "./authEndpoints";
import { baseURL } from "../baseUrl/baseURL";
import { headers } from "../baseUrl/setHeaders";

export const authAPI = createApi({
    reducerPath: "authAPI",
    baseQuery: fetchBaseQuery({ baseUrl: baseURL, prepareHeaders: headers }),
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (payload) => ({
                url: `${authEndpoints.LOGIN}`,
                method: `POST`,
                body: payload,
            }),
        }),
        logout: builder.mutation({
            query: (payload) => ({
                url: `${authEndpoints.LOGOUT}`,
                method: `POST`,
                body: payload,
            }),
        }),
    }),
});

export const { useLoginMutation, useLogoutMutation } = authAPI;
