import React from "react";
import { Grid, Typography } from "@mui/material";
import { Formik } from "formik";
import CustomTextField from "../../../components/forms/CustomTextField";
import {
    ArrowRightAltOutlined,
    VerifiedUserOutlined,
} from "@mui/icons-material";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import useRTK from "../../../hooks/useRTK";
import { useVerifyICNMutation } from "../../../api/registerAPI/registerAPI";
import { setMemberICN } from "../../../state/features/register/registerSlice";
import { registerSelector } from "../../../state/features/selectors";
import { colors } from "../../../assets/utils/colors";
import NextAndBackBtn from "../../../components/steppers/NextAndBackBtn";
import CustomSubmitButton1 from "../../../components/forms/buttons/CustomSubmitButton1";
import DialogLinerProgress from "../../../components/DialogLinerProgress";

// ############# Validation Schema ##############
const validationSchema = Yup.object({
    tinNumber: Yup.string().required("Please provide your TIN").max(9).min(9),
});

const VerifyRegID = ({ steps, activeStep, handleNext, handleBack }) => {
    // ############ CONST ####################
    const dispatch = useDispatch();
    const RTK = useRTK();

    // ############ Redux State ##############
    const { memberICN } = useSelector(registerSelector);

    // ############# RTK ##################
    const [verifyICN, { isLoading, isSuccess, isError, error, data: icnData }] =
        useVerifyICNMutation();

    // ############# useEffect ##################
    const rtkActions = (data) => {
        console.log(data);
        dispatch(setMemberICN(data));
        handleNext();
    };
    RTK.useRTKResponse(isSuccess, isError, error, icnData, rtkActions);

    return (
        <>
            <DialogLinerProgress isLoading={isLoading} />
            <Formik
                enableReinitialize
                initialValues={{
                    tinNumber: memberICN,
                }}
                validationSchema={validationSchema}
                onSubmit={(payload) => {
                    verifyICN(payload);
                }}
            >
                {(formik) => (
                    <Grid
                        container
                        justifyContent={"center"}
                        padding={2}
                        py={{ md: 2 }}
                    >
                        <Grid item sm={11} xs={12}>
                            <Typography sx={{ fontSize: 14 }}>
                                Please provide company Taxpayer Identification
                                Number (TIN) in order to proceed
                            </Typography>
                        </Grid>
                        <Grid item sm={11} xs={12} sx={{ mb: 4 }}>
                            <CustomTextField
                                className="form-input"
                                label={`Taxpayer Identification Number (TIN)`}
                                type="text"
                                name="tinNumber"
                                bBottom={colors.secondary}
                            />
                        </Grid>

                        {/* ################ ACTION BUTONS ###################### */}
                        <Grid
                            item
                            sm={11}
                            xs={12}
                            sx={{
                                textAlign: "center",
                            }}
                        >
                            <CustomSubmitButton1
                                startIcon={<VerifiedUserOutlined />}
                                endIcon={<ArrowRightAltOutlined />}
                                variant={"contained"}
                                loading={isLoading}
                                onClick={() => {
                                    formik.handleSubmit();
                                }}
                            >
                                Confirm TIN
                            </CustomSubmitButton1>
                        </Grid>
                        <Grid
                            item
                            sm={11}
                            xs={12}
                            sx={{
                                marginTop: 2.5,
                                textAlign: "center",
                            }}
                        >
                            <NextAndBackBtn
                                steps={steps}
                                activeStep={activeStep}
                                handleBack={handleBack}
                                action={handleNext}
                                disabled={memberICN ? false : true}
                            />
                        </Grid>
                    </Grid>
                )}
            </Formik>
        </>
    );
};

export default VerifyRegID;
