import {
    LocalPolice,
    PriceCheck,
    Unpublished,
    Verified,
} from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { colors } from "../../assets/utils/colors";
import useCurrency from "../../hooks/useCurrency";
import Register from "../../pages/auth/Register";

const MemberShipLevelWidget = ({ level }) => {
    const currency = useCurrency();
 
    return (
        <>
            <Box
                sx={{
                    width: "100%",
                    borderRadius: 5,
                    p: 4,
                    position: "relative",
                    border: `1px solid ${colors.primary}`,
                    background: `linear-gradient(360deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                }}
            >
                <Box
                    sx={{
                        height: 70,
                        width: 70,
                        bgcolor: "red",
                        position: "absolute",
                        top: -40,
                        left: -10,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 5,
                        background: `linear-gradient(360deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                    }}
                >
                    <LocalPolice
                        sx={{
                            fontSize: 55,
                            color:
                                level.levelName === "Platinum"
                                    ? colors.secondary
                                    : level.levelName === "Silver"
                                    ? "gray"
                                    : colors.orange,
                        }}
                    />
                </Box>
                <Grid container spacing={2}>
                    <Grid item sm={12} xs={12}>
                        <Typography
                            sx={{
                                fontSize: 20,
                                fontWeight: "bold",
                                background: `linear-gradient(rgba(23, 148, 202, .8), rgb(49, 180, 76, .8))`,
                                backgroundClip: "text",
                                textFillColor: "transparent",
                            }}
                        >
                            {level.levelName}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Typography
                            sx={{
                                fontSize: 18,
                                fontWeight: "bold",
                                border: `1px solid ${colors.primary}`,
                                px: 2,
                                py: 0.6,
                                borderRadius: 2,
                                color: colors.primary,
                            }}
                        >
                            <PriceCheck sx={{ mr: 1.5 }} />
                            {currency.formatCurrency("TZS", level.levelFee)}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Typography
                            sx={{
                                fontSize: 14,
                                textAlign: "center",
                                p: 1,
                                fontWeight: "bold",
                                opacity: 0.8,
                            }}
                        >
                            Service / Benefit
                        </Typography>
                        <Box sx={{ height: "30vh", overflow: "auto" }}>
                            {level?.benefits.map((benefit, index) => (
                                <Box
                                    sx={{
                                        fontSize: 14,
                                        display: "flex",
                                        alignItems: "center",
                                        mb: 1,
                                        justifyContent: "space-between",
                                    }}
                                    key={index}
                                >
                                    <Box>
                                        <Typography
                                            component={"span"}
                                            noWrap
                                            sx={{
                                                fontSize: 12,
                                                mr: 1,
                                            }}
                                        >
                                            <strong>{`${++index} )`}</strong>
                                        </Typography>
                                        <Typography
                                            component={"span"}
                                            sx={{
                                                fontSize: 12,
                                            }}
                                        >
                                            {benefit.service}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ pr: 1 }}>
                                        {benefit.status ? (
                                            <>
                                                <Verified
                                                    sx={{
                                                        color: colors.secondary,
                                                        fontSize: 18,
                                                    }}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <Unpublished
                                                    sx={{
                                                        color: colors.red,
                                                        fontSize: 18,
                                                    }}
                                                />
                                            </>
                                        )}
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                        <Box sx={{ mt: 5 }}>
                            <Register fullWidth />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default MemberShipLevelWidget;
